import { createSlice } from '@reduxjs/toolkit'
import { finalizedScheduleMetadataApi } from 'services/api'

const initialState = {
  finalizedScheduleManagementList: {},
  isListLoading: false,
  error: null
}

const finalizedScheduleManagementSlice = createSlice({
  name: 'finalizedschedulemanagement',
  initialState,
  reducers: {
    getFinalizedScheduleManagementListStart (state) {
      state.error = null
      state.isListLoading = true
    },
    getFinalizedScheduleManagementListSuccess (state, { payload }) {
      state.finalizedScheduleManagementList = payload
      state.error = null
      state.isListLoading = false
    },
    getFinalizedScheduleManagementListFailed (state, { payload }) {
      state.finalizedScheduleManagementList = {}
      state.error = payload.error
      state.isListLoading = false
    },
    downloadFinalizedScheduleManagementXMLSuccess (state) {
      state.error = null
    },
    downloadFinalizedScheduleManagementXMLFailed (state, { payload }) {
      state.error = payload
    },
    clear (state) {
      state.finalizedScheduleManagementList = {}
      state.error = null
    }
  }
})

const {
  getFinalizedScheduleManagementListStart,
  getFinalizedScheduleManagementListSuccess,
  getFinalizedScheduleManagementListFailed,
  downloadFinalizedScheduleManagementXMLSuccess,
  downloadFinalizedScheduleManagementXMLFailed,
  clear
} = finalizedScheduleManagementSlice.actions

const getFinalizedScheduleManagementList = (params) => async dispatch => {
  try {
    dispatch(getFinalizedScheduleManagementListStart())
    const finalizedScheduleManagementList = await finalizedScheduleMetadataApi.getPaginatedFinalizedScheduleMeta(params)
    dispatch(getFinalizedScheduleManagementListSuccess(finalizedScheduleManagementList))
  } catch (error) {
    dispatch(getFinalizedScheduleManagementListFailed({ error }))
  }
}

const downloadFinalizedScheduleManagementXML = (id) => async dispatch => {
  try {
    const response = await finalizedScheduleMetadataApi.finalizedScheduleMetadataXml({ id }, { responseType: 'blob' })
    dispatch(downloadFinalizedScheduleManagementXMLSuccess())
    return response
  } catch (error) {
    dispatch(downloadFinalizedScheduleManagementXMLFailed({ error }))
    return null
  }
}

export {
  getFinalizedScheduleManagementList,
  downloadFinalizedScheduleManagementXML,
  clear
}

export default finalizedScheduleManagementSlice.reducer
