import { PrognosisStatus } from 'models/enum'

export const getComparedStatuses = (oldState, newState, propName = 'powerPlantId') => {
  if (!Array.isArray(oldState) || !Array.isArray(newState)) {
    return []
  }
  return newState.map(newItem => {
    // Check ready status
    const foundReady = oldState.find(oldItem => {
      return oldItem[propName] === newItem[propName] &&
        [PrognosisStatus.Run, PrognosisStatus.Ready].includes(oldItem.prognosisStatus) &&
        newItem.prognosisStatus === PrognosisStatus.NoNewData
    })
    if (foundReady) {
      const { messageShowed } = foundReady
      return {
        ...newItem,
        prognosisStatus: PrognosisStatus.Ready,
        ...(messageShowed && { messageShowed })
      }
    }

    // Check running status
    const foundRun = oldState.find(oldItem => {
      return oldItem[propName] === newItem[propName] &&
        oldItem.prognosisStatus === PrognosisStatus.Run &&
        newItem.prognosisStatus === PrognosisStatus.NewData
    })
    if (foundRun) {
      return {
        ...newItem,
        prognosisStatus: PrognosisStatus.Run
      }
    }

    // No change
    return newItem
  })
}
