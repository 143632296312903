import { createSlice } from '@reduxjs/toolkit'
import { companyProfileApi, userApi } from 'services/api'
import { ModalStatus } from 'models/enum'
import { history } from 'router'
import { updateSessionCompanyProfileId } from 'store/auth/auth.slice'
import { getCompanyProfileList as getSelectableProfileList } from 'store/general/entity.slice'

const initialState = {
  companyProfileList: {},
  companyProfile: {},
  companyProfileModalStatus: ModalStatus.Hidden,
  isListLoading: false,
  isEntityLoading: false,
  sessionProfile: null,
  error: null
}

const companyProfileSlice = createSlice({
  name: 'companyprofile',
  initialState,
  reducers: {
    getCompanyProfileListStart (state) {
      state.isListLoading = true
      state.error = null
    },
    getCompanyProfileListSuccess (state, { payload }) {
      state.isListLoading = false
      state.error = null
      state.companyProfileList = payload
    },
    getCompanyProfileListFailed (state, { payload }) {
      state.isListLoading = false
      state.error = payload.error
      state.companyProfileList = {}
    },
    getCompanyProfileSuccess (state, { payload }) {
      state.companyProfile = payload
      state.error = null
      state.isEntityLoading = false
    },
    getCompanyProfileFailed (state, { payload }) {
      state.companyProfile = {}
      state.error = payload.error
      state.isEntityLoading = false
    },
    createCompanyProfileSuccess (state) {
      state.companyProfile = {}
      state.error = null
      state.companyProfileModalStatus = ModalStatus.Hidden
      state.isEntityLoading = false
    },
    createCompanyProfileFailed (state, { payload }) {
      state.error = payload.error
      state.isEntityLoading = false
    },
    editCompanyProfileSuccess (state) {
      state.companyProfile = {}
      state.error = null
      state.companyProfileModalStatus = ModalStatus.Hidden
      state.isEntityLoading = false
    },
    editCompanyProfileFailed (state, { payload }) {
      state.error = payload.error
      state.isEntityLoading = false
    },
    inactivateCompanyProfileSuccess (state) {
      state.error = null
    },
    inactivateCompanyProfileFailed (state, { payload }) {
      state.error = payload.error
    },
    activateCompanyProfileSuccess (state) {
      state.error = null
    },
    activateCompanyProfileFailed (state, { payload }) {
      state.error = payload.error
    },
    setCompanyProfileModalStatus (state, { payload }) {
      state.companyProfileModalStatus = payload
      if (payload === ModalStatus.Hidden) {
        state.companyProfile = {}
      }
    },
    setCompanyProfileLoading (state) {
      state.isEntityLoading = true
    },
    updateSessionProfileSuccess (state, { payload }) {
      state.sessionProfile = payload
    },
    updateSessionProfileFailed (state, { payload }) {
      state.error = payload.error
    },
    clear (state) {
      state.companyProfileList = {}
      state.companyProfile = {}
      state.companyProfileModalStatus = ModalStatus.Hidden
      state.error = null
    }
  }
})

const {
  getCompanyProfileListStart,
  getCompanyProfileListSuccess,
  getCompanyProfileListFailed,
  getCompanyProfileSuccess,
  getCompanyProfileFailed,
  createCompanyProfileSuccess,
  createCompanyProfileFailed,
  editCompanyProfileSuccess,
  editCompanyProfileFailed,
  inactivateCompanyProfileSuccess,
  inactivateCompanyProfileFailed,
  activateCompanyProfileSuccess,
  activateCompanyProfileFailed,
  setCompanyProfileModalStatus,
  setCompanyProfileLoading,
  updateSessionProfileSuccess,
  updateSessionProfileFailed,
  clear
} = companyProfileSlice.actions

const getCompanyProfileList = (filters) => async dispatch => {
  try {
    dispatch(getCompanyProfileListStart())
    const companyProfileList = await companyProfileApi.getPaginatedCompanyProfile(filters)
    dispatch(getCompanyProfileListSuccess(companyProfileList))
  } catch (error) {
    dispatch(getCompanyProfileListFailed({ error }))
  }
}

const getCompanyProfile = (id) => async dispatch => {
  try {
    dispatch(setCompanyProfileLoading())
    const companyProfile = await companyProfileApi.getCompanyProfile({ id })
    dispatch(getCompanyProfileSuccess(companyProfile))
  } catch (error) {
    dispatch(getCompanyProfileFailed({ error }))
  }
}

const createCompanyProfile = (createCompanyProfileDto) => async dispatch => {
  try {
    dispatch(setCompanyProfileLoading())
    await companyProfileApi.createCompanyProfile({ createCompanyProfileDto })
    dispatch(createCompanyProfileSuccess())
    dispatch(getCompanyProfileList())
    dispatch(getSelectableProfileList())
  } catch (error) {
    dispatch(createCompanyProfileFailed({ error }))
  }
}

const editCompanyProfile = (id, updateCompanyProfileDto) => async dispatch => {
  try {
    dispatch(setCompanyProfileLoading())
    await companyProfileApi.editCompanyProfile({ id, updateCompanyProfileDto })
    dispatch(editCompanyProfileSuccess())
    dispatch(getCompanyProfileList())
  } catch (error) {
    dispatch(editCompanyProfileFailed({ error }))
  }
}

const inactivateCompanyProfile = (id) => async dispatch => {
  try {
    await companyProfileApi.inactivateCompanyProfile({ id })
    dispatch(inactivateCompanyProfileSuccess())
    dispatch(getCompanyProfileList())
  } catch (error) {
    dispatch(inactivateCompanyProfileFailed({ error }))
  }
}

const activateCompanyProfile = (id) => async dispatch => {
  try {
    await companyProfileApi.activateCompanyProfile({ id })
    dispatch(activateCompanyProfileSuccess())
    dispatch(getCompanyProfileList())
  } catch (error) {
    dispatch(activateCompanyProfileFailed({ error }))
  }
}

const updateSessionProfile = (id, profileId) => async (dispatch, getState) => {
  try {
    if (!profileId) {
      await userApi.removeSessionProfile({ id })
    } else {
      await userApi.updateSessionProfile({ id, profileId })
    }
    await dispatch(updateSessionProfileSuccess(profileId))
    await dispatch(updateSessionCompanyProfileId(profileId))

    if (!profileId) {
      const loggedInProfileId = getState().auth.profileId
      history.push(`/profile/edit/${loggedInProfileId}`)
    } else {
      history.push('/temp')
      history.replace('/data/masterdata/balance-responsible')
    }
  } catch (error) {
    dispatch(updateSessionProfileFailed({ error }))
  }
}

export {
  getCompanyProfileList,
  getCompanyProfile,
  createCompanyProfile,
  editCompanyProfile,
  inactivateCompanyProfile,
  activateCompanyProfile,
  updateSessionProfile,
  setCompanyProfileModalStatus,
  clear
}

export default companyProfileSlice.reducer
