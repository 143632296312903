"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyProfileApi = exports.CompanyProfileApiFactory = exports.CompanyProfileApiFp = exports.CompanyProfileApiAxiosParamCreator = void 0;
var globalImportUrl = require("url");
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var base_1 = require("../base");
/**
 * CompanyProfileApi - axios parameter creator
 * @export
 */
exports.CompanyProfileApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateCompanyProfile: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    if (id === null || id === undefined) {
                        throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling activateCompanyProfile.');
                    }
                    localVarPath = "/api/CompanyProfile/{id}/Activate"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {CreateCompanyProfileDto} [createCompanyProfileDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompanyProfile: function (createCompanyProfileDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions, needsSerialization;
                return __generator(this, function (_a) {
                    localVarPath = "/api/CompanyProfile";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    needsSerialization = (typeof createCompanyProfileDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
                    localVarRequestOptions.data = needsSerialization ? JSON.stringify(createCompanyProfileDto !== undefined ? createCompanyProfileDto : {}) : (createCompanyProfileDto || "");
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {UpdateCompanyProfileDto} [updateCompanyProfileDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editCompanyProfile: function (id, updateCompanyProfileDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions, needsSerialization;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    if (id === null || id === undefined) {
                        throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling editCompanyProfile.');
                    }
                    localVarPath = "/api/CompanyProfile/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    needsSerialization = (typeof updateCompanyProfileDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
                    localVarRequestOptions.data = needsSerialization ? JSON.stringify(updateCompanyProfileDto !== undefined ? updateCompanyProfileDto : {}) : (updateCompanyProfileDto || "");
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCustomerProfile: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/api/CompanyProfile/customerProfile";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyProfile: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    if (id === null || id === undefined) {
                        throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling getCompanyProfile.');
                    }
                    localVarPath = "/api/CompanyProfile/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} [name]
         * @param {string} [address]
         * @param {string} [mailAddress]
         * @param {string} [phoneNumber]
         * @param {string} [emailAddress]
         * @param {string} [taxNumber]
         * @param {string} [socialTaxNumber]
         * @param {string} [bankName]
         * @param {string} [bankAccountNumber]
         * @param {boolean} [isSysAdmin]
         * @param {ListSortDirection} [orderByDirection]
         * @param {string} [orderByFieldName]
         * @param {number} [paginationPageNumber]
         * @param {number} [paginationPageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedCompanyProfile: function (name, address, mailAddress, phoneNumber, emailAddress, taxNumber, socialTaxNumber, bankName, bankAccountNumber, isSysAdmin, orderByDirection, orderByFieldName, paginationPageNumber, paginationPageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/api/CompanyProfile/paginated";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    if (name !== undefined) {
                        localVarQueryParameter['Name'] = name;
                    }
                    if (address !== undefined) {
                        localVarQueryParameter['Address'] = address;
                    }
                    if (mailAddress !== undefined) {
                        localVarQueryParameter['MailAddress'] = mailAddress;
                    }
                    if (phoneNumber !== undefined) {
                        localVarQueryParameter['PhoneNumber'] = phoneNumber;
                    }
                    if (emailAddress !== undefined) {
                        localVarQueryParameter['EmailAddress'] = emailAddress;
                    }
                    if (taxNumber !== undefined) {
                        localVarQueryParameter['TaxNumber'] = taxNumber;
                    }
                    if (socialTaxNumber !== undefined) {
                        localVarQueryParameter['SocialTaxNumber'] = socialTaxNumber;
                    }
                    if (bankName !== undefined) {
                        localVarQueryParameter['BankName'] = bankName;
                    }
                    if (bankAccountNumber !== undefined) {
                        localVarQueryParameter['BankAccountNumber'] = bankAccountNumber;
                    }
                    if (isSysAdmin !== undefined) {
                        localVarQueryParameter['IsSysAdmin'] = isSysAdmin;
                    }
                    if (orderByDirection !== undefined) {
                        localVarQueryParameter['OrderBy.Direction'] = orderByDirection;
                    }
                    if (orderByFieldName !== undefined) {
                        localVarQueryParameter['OrderBy.FieldName'] = orderByFieldName;
                    }
                    if (paginationPageNumber !== undefined) {
                        localVarQueryParameter['Pagination.PageNumber'] = paginationPageNumber;
                    }
                    if (paginationPageSize !== undefined) {
                        localVarQueryParameter['Pagination.PageSize'] = paginationPageSize;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inactivateCompanyProfile: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    if (id === null || id === undefined) {
                        throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling inactivateCompanyProfile.');
                    }
                    localVarPath = "/api/CompanyProfile/{id}/Inactivate"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} [id]
         * @param {string} [name]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isProfileNameValid: function (id, name, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/api/CompanyProfile/isProfileNameValid";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    if (id !== undefined) {
                        localVarQueryParameter['id'] = id;
                    }
                    if (name !== undefined) {
                        localVarQueryParameter['name'] = name;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * CompanyProfileApi - functional programming interface
 * @export
 */
exports.CompanyProfileApiFp = function (configuration) {
    return {
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateCompanyProfile: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.CompanyProfileApiAxiosParamCreator(configuration).activateCompanyProfile(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CreateCompanyProfileDto} [createCompanyProfileDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompanyProfile: function (createCompanyProfileDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.CompanyProfileApiAxiosParamCreator(configuration).createCompanyProfile(createCompanyProfileDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {UpdateCompanyProfileDto} [updateCompanyProfileDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editCompanyProfile: function (id, updateCompanyProfileDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.CompanyProfileApiAxiosParamCreator(configuration).editCompanyProfile(id, updateCompanyProfileDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCustomerProfile: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.CompanyProfileApiAxiosParamCreator(configuration).getAllCustomerProfile(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyProfile: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.CompanyProfileApiAxiosParamCreator(configuration).getCompanyProfile(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [name]
         * @param {string} [address]
         * @param {string} [mailAddress]
         * @param {string} [phoneNumber]
         * @param {string} [emailAddress]
         * @param {string} [taxNumber]
         * @param {string} [socialTaxNumber]
         * @param {string} [bankName]
         * @param {string} [bankAccountNumber]
         * @param {boolean} [isSysAdmin]
         * @param {ListSortDirection} [orderByDirection]
         * @param {string} [orderByFieldName]
         * @param {number} [paginationPageNumber]
         * @param {number} [paginationPageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedCompanyProfile: function (name, address, mailAddress, phoneNumber, emailAddress, taxNumber, socialTaxNumber, bankName, bankAccountNumber, isSysAdmin, orderByDirection, orderByFieldName, paginationPageNumber, paginationPageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.CompanyProfileApiAxiosParamCreator(configuration).getPaginatedCompanyProfile(name, address, mailAddress, phoneNumber, emailAddress, taxNumber, socialTaxNumber, bankName, bankAccountNumber, isSysAdmin, orderByDirection, orderByFieldName, paginationPageNumber, paginationPageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inactivateCompanyProfile: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.CompanyProfileApiAxiosParamCreator(configuration).inactivateCompanyProfile(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [id]
         * @param {string} [name]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isProfileNameValid: function (id, name, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.CompanyProfileApiAxiosParamCreator(configuration).isProfileNameValid(id, name, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
/**
 * CompanyProfileApi - factory interface
 * @export
 */
exports.CompanyProfileApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateCompanyProfile: function (id, options) {
            return exports.CompanyProfileApiFp(configuration).activateCompanyProfile(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CreateCompanyProfileDto} [createCompanyProfileDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompanyProfile: function (createCompanyProfileDto, options) {
            return exports.CompanyProfileApiFp(configuration).createCompanyProfile(createCompanyProfileDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} id
         * @param {UpdateCompanyProfileDto} [updateCompanyProfileDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editCompanyProfile: function (id, updateCompanyProfileDto, options) {
            return exports.CompanyProfileApiFp(configuration).editCompanyProfile(id, updateCompanyProfileDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCustomerProfile: function (options) {
            return exports.CompanyProfileApiFp(configuration).getAllCustomerProfile(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyProfile: function (id, options) {
            return exports.CompanyProfileApiFp(configuration).getCompanyProfile(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [name]
         * @param {string} [address]
         * @param {string} [mailAddress]
         * @param {string} [phoneNumber]
         * @param {string} [emailAddress]
         * @param {string} [taxNumber]
         * @param {string} [socialTaxNumber]
         * @param {string} [bankName]
         * @param {string} [bankAccountNumber]
         * @param {boolean} [isSysAdmin]
         * @param {ListSortDirection} [orderByDirection]
         * @param {string} [orderByFieldName]
         * @param {number} [paginationPageNumber]
         * @param {number} [paginationPageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedCompanyProfile: function (name, address, mailAddress, phoneNumber, emailAddress, taxNumber, socialTaxNumber, bankName, bankAccountNumber, isSysAdmin, orderByDirection, orderByFieldName, paginationPageNumber, paginationPageSize, options) {
            return exports.CompanyProfileApiFp(configuration).getPaginatedCompanyProfile(name, address, mailAddress, phoneNumber, emailAddress, taxNumber, socialTaxNumber, bankName, bankAccountNumber, isSysAdmin, orderByDirection, orderByFieldName, paginationPageNumber, paginationPageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inactivateCompanyProfile: function (id, options) {
            return exports.CompanyProfileApiFp(configuration).inactivateCompanyProfile(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} [id]
         * @param {string} [name]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isProfileNameValid: function (id, name, options) {
            return exports.CompanyProfileApiFp(configuration).isProfileNameValid(id, name, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * CompanyProfileApi - object-oriented interface
 * @export
 * @class CompanyProfileApi
 * @extends {BaseAPI}
 */
var CompanyProfileApi = /** @class */ (function (_super) {
    __extends(CompanyProfileApi, _super);
    function CompanyProfileApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {CompanyProfileApiActivateCompanyProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyProfileApi
     */
    CompanyProfileApi.prototype.activateCompanyProfile = function (requestParameters, options) {
        var _this = this;
        return exports.CompanyProfileApiFp(this.configuration).activateCompanyProfile(requestParameters.id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CompanyProfileApiCreateCompanyProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyProfileApi
     */
    CompanyProfileApi.prototype.createCompanyProfile = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return exports.CompanyProfileApiFp(this.configuration).createCompanyProfile(requestParameters.createCompanyProfileDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CompanyProfileApiEditCompanyProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyProfileApi
     */
    CompanyProfileApi.prototype.editCompanyProfile = function (requestParameters, options) {
        var _this = this;
        return exports.CompanyProfileApiFp(this.configuration).editCompanyProfile(requestParameters.id, requestParameters.updateCompanyProfileDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyProfileApi
     */
    CompanyProfileApi.prototype.getAllCustomerProfile = function (options) {
        var _this = this;
        return exports.CompanyProfileApiFp(this.configuration).getAllCustomerProfile(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CompanyProfileApiGetCompanyProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyProfileApi
     */
    CompanyProfileApi.prototype.getCompanyProfile = function (requestParameters, options) {
        var _this = this;
        return exports.CompanyProfileApiFp(this.configuration).getCompanyProfile(requestParameters.id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CompanyProfileApiGetPaginatedCompanyProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyProfileApi
     */
    CompanyProfileApi.prototype.getPaginatedCompanyProfile = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return exports.CompanyProfileApiFp(this.configuration).getPaginatedCompanyProfile(requestParameters.name, requestParameters.address, requestParameters.mailAddress, requestParameters.phoneNumber, requestParameters.emailAddress, requestParameters.taxNumber, requestParameters.socialTaxNumber, requestParameters.bankName, requestParameters.bankAccountNumber, requestParameters.isSysAdmin, requestParameters.orderByDirection, requestParameters.orderByFieldName, requestParameters.paginationPageNumber, requestParameters.paginationPageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CompanyProfileApiInactivateCompanyProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyProfileApi
     */
    CompanyProfileApi.prototype.inactivateCompanyProfile = function (requestParameters, options) {
        var _this = this;
        return exports.CompanyProfileApiFp(this.configuration).inactivateCompanyProfile(requestParameters.id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CompanyProfileApiIsProfileNameValidRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyProfileApi
     */
    CompanyProfileApi.prototype.isProfileNameValid = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return exports.CompanyProfileApiFp(this.configuration).isProfileNameValid(requestParameters.id, requestParameters.name, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return CompanyProfileApi;
}(base_1.BaseAPI));
exports.CompanyProfileApi = CompanyProfileApi;
