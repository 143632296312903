"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataImportApi = exports.DataImportApiFactory = exports.DataImportApiFp = exports.DataImportApiAxiosParamCreator = void 0;
var globalImportUrl = require("url");
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var base_1 = require("../base");
/**
 * DataImportApi - axios parameter creator
 * @export
 */
exports.DataImportApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadForecastSample: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/api/DataImport/Download/ForecastSample";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadProductionSample: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/api/DataImport/Download/ProductionSample";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadWeatherSample: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/api/DataImport/Download/WeatherSample";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} [powerPlantId]
         * @param {string} [measurementDay]
         * @param {any} [excelFile]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forecast: function (powerPlantId, measurementDay, excelFile, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/api/DataImport/Forecast";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarFormParams = new FormData();
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    if (powerPlantId !== undefined) {
                        localVarQueryParameter['powerPlantId'] = powerPlantId;
                    }
                    if (measurementDay !== undefined) {
                        localVarQueryParameter['measurementDay'] = (measurementDay instanceof Date) ?
                            measurementDay.toISOString() :
                            measurementDay;
                    }
                    if (excelFile !== undefined) {
                        localVarFormParams.append('excelFile', excelFile);
                    }
                    localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = localVarFormParams;
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} [powerPlantId]
         * @param {string} [measurementDay]
         * @param {any} [excelFile]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        production: function (powerPlantId, measurementDay, excelFile, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/api/DataImport/Production";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarFormParams = new FormData();
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    if (powerPlantId !== undefined) {
                        localVarQueryParameter['powerPlantId'] = powerPlantId;
                    }
                    if (measurementDay !== undefined) {
                        localVarQueryParameter['measurementDay'] = (measurementDay instanceof Date) ?
                            measurementDay.toISOString() :
                            measurementDay;
                    }
                    if (excelFile !== undefined) {
                        localVarFormParams.append('excelFile', excelFile);
                    }
                    localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = localVarFormParams;
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {any} [xmlFile]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productionMkp: function (xmlFile, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/api/DataImport/ProductionMkp";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarFormParams = new FormData();
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    if (xmlFile !== undefined) {
                        localVarFormParams.append('xmlFile', xmlFile);
                    }
                    localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = localVarFormParams;
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {any} [csvFile]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productionMkpCsv: function (csvFile, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/api/DataImport/ProductionMkpCsv";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarFormParams = new FormData();
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    if (csvFile !== undefined) {
                        localVarFormParams.append('csvFile', csvFile);
                    }
                    localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = localVarFormParams;
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {ScheduleUploadMekState} [uploadState]
         * @param {any} [xmlFile]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        schedule: function (uploadState, xmlFile, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/api/DataImport/Schedule";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarFormParams = new FormData();
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    if (uploadState !== undefined) {
                        localVarQueryParameter['uploadState'] = uploadState;
                    }
                    if (xmlFile !== undefined) {
                        localVarFormParams.append('xmlFile', xmlFile);
                    }
                    localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = localVarFormParams;
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} [powerPlantId]
         * @param {string} [measurementDay]
         * @param {any} [excelFile]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weather: function (powerPlantId, measurementDay, excelFile, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/api/DataImport/Weather";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarFormParams = new FormData();
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    if (powerPlantId !== undefined) {
                        localVarQueryParameter['powerPlantId'] = powerPlantId;
                    }
                    if (measurementDay !== undefined) {
                        localVarQueryParameter['measurementDay'] = (measurementDay instanceof Date) ?
                            measurementDay.toISOString() :
                            measurementDay;
                    }
                    if (excelFile !== undefined) {
                        localVarFormParams.append('excelFile', excelFile);
                    }
                    localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = localVarFormParams;
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * DataImportApi - functional programming interface
 * @export
 */
exports.DataImportApiFp = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadForecastSample: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.DataImportApiAxiosParamCreator(configuration).downloadForecastSample(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadProductionSample: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.DataImportApiAxiosParamCreator(configuration).downloadProductionSample(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadWeatherSample: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.DataImportApiAxiosParamCreator(configuration).downloadWeatherSample(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [powerPlantId]
         * @param {string} [measurementDay]
         * @param {any} [excelFile]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forecast: function (powerPlantId, measurementDay, excelFile, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.DataImportApiAxiosParamCreator(configuration).forecast(powerPlantId, measurementDay, excelFile, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [powerPlantId]
         * @param {string} [measurementDay]
         * @param {any} [excelFile]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        production: function (powerPlantId, measurementDay, excelFile, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.DataImportApiAxiosParamCreator(configuration).production(powerPlantId, measurementDay, excelFile, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {any} [xmlFile]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productionMkp: function (xmlFile, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.DataImportApiAxiosParamCreator(configuration).productionMkp(xmlFile, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {any} [csvFile]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productionMkpCsv: function (csvFile, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.DataImportApiAxiosParamCreator(configuration).productionMkpCsv(csvFile, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {ScheduleUploadMekState} [uploadState]
         * @param {any} [xmlFile]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        schedule: function (uploadState, xmlFile, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.DataImportApiAxiosParamCreator(configuration).schedule(uploadState, xmlFile, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [powerPlantId]
         * @param {string} [measurementDay]
         * @param {any} [excelFile]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weather: function (powerPlantId, measurementDay, excelFile, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.DataImportApiAxiosParamCreator(configuration).weather(powerPlantId, measurementDay, excelFile, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
/**
 * DataImportApi - factory interface
 * @export
 */
exports.DataImportApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadForecastSample: function (options) {
            return exports.DataImportApiFp(configuration).downloadForecastSample(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadProductionSample: function (options) {
            return exports.DataImportApiFp(configuration).downloadProductionSample(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadWeatherSample: function (options) {
            return exports.DataImportApiFp(configuration).downloadWeatherSample(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} [powerPlantId]
         * @param {string} [measurementDay]
         * @param {any} [excelFile]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forecast: function (powerPlantId, measurementDay, excelFile, options) {
            return exports.DataImportApiFp(configuration).forecast(powerPlantId, measurementDay, excelFile, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} [powerPlantId]
         * @param {string} [measurementDay]
         * @param {any} [excelFile]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        production: function (powerPlantId, measurementDay, excelFile, options) {
            return exports.DataImportApiFp(configuration).production(powerPlantId, measurementDay, excelFile, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {any} [xmlFile]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productionMkp: function (xmlFile, options) {
            return exports.DataImportApiFp(configuration).productionMkp(xmlFile, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {any} [csvFile]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productionMkpCsv: function (csvFile, options) {
            return exports.DataImportApiFp(configuration).productionMkpCsv(csvFile, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {ScheduleUploadMekState} [uploadState]
         * @param {any} [xmlFile]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        schedule: function (uploadState, xmlFile, options) {
            return exports.DataImportApiFp(configuration).schedule(uploadState, xmlFile, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} [powerPlantId]
         * @param {string} [measurementDay]
         * @param {any} [excelFile]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weather: function (powerPlantId, measurementDay, excelFile, options) {
            return exports.DataImportApiFp(configuration).weather(powerPlantId, measurementDay, excelFile, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * DataImportApi - object-oriented interface
 * @export
 * @class DataImportApi
 * @extends {BaseAPI}
 */
var DataImportApi = /** @class */ (function (_super) {
    __extends(DataImportApi, _super);
    function DataImportApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportApi
     */
    DataImportApi.prototype.downloadForecastSample = function (options) {
        var _this = this;
        return exports.DataImportApiFp(this.configuration).downloadForecastSample(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportApi
     */
    DataImportApi.prototype.downloadProductionSample = function (options) {
        var _this = this;
        return exports.DataImportApiFp(this.configuration).downloadProductionSample(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportApi
     */
    DataImportApi.prototype.downloadWeatherSample = function (options) {
        var _this = this;
        return exports.DataImportApiFp(this.configuration).downloadWeatherSample(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {DataImportApiForecastRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportApi
     */
    DataImportApi.prototype.forecast = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return exports.DataImportApiFp(this.configuration).forecast(requestParameters.powerPlantId, requestParameters.measurementDay, requestParameters.excelFile, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {DataImportApiProductionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportApi
     */
    DataImportApi.prototype.production = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return exports.DataImportApiFp(this.configuration).production(requestParameters.powerPlantId, requestParameters.measurementDay, requestParameters.excelFile, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {DataImportApiProductionMkpRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportApi
     */
    DataImportApi.prototype.productionMkp = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return exports.DataImportApiFp(this.configuration).productionMkp(requestParameters.xmlFile, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {DataImportApiProductionMkpCsvRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportApi
     */
    DataImportApi.prototype.productionMkpCsv = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return exports.DataImportApiFp(this.configuration).productionMkpCsv(requestParameters.csvFile, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {DataImportApiScheduleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportApi
     */
    DataImportApi.prototype.schedule = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return exports.DataImportApiFp(this.configuration).schedule(requestParameters.uploadState, requestParameters.xmlFile, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {DataImportApiWeatherRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportApi
     */
    DataImportApi.prototype.weather = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return exports.DataImportApiFp(this.configuration).weather(requestParameters.powerPlantId, requestParameters.measurementDay, requestParameters.excelFile, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return DataImportApi;
}(base_1.BaseAPI));
exports.DataImportApi = DataImportApi;
