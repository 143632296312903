import React, { createContext, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'antd'
import SchemaContext from 'contexts/SchemaContext'
import { useTranslation } from 'react-i18next'
import styles from './FormItemWidget.module.scss'
import classnames from 'classnames'

export const InputContext = createContext()

const FormItemWidget = (props) => {
  const { name, className, labelPostfix, children, rules, hasTooltip, ...inputProps } = props
  const { t, i18n } = useTranslation()
  const { schema, nestedSchema, formNamespace } = useContext(SchemaContext)

  const mergedRules = useMemo(() => {
    if (!schema) {
      return rules
    }
    let constraintRules

    if (Array.isArray(name) && nestedSchema) {
      constraintRules = nestedSchema.getConstraints(name[1])
    } else {
      constraintRules = schema.getConstraints(name)
    }

    if (rules) {
      return [...constraintRules, ...rules]
    }
    return constraintRules
  }, [name, rules, schema, nestedSchema])

  const inputLabel = useMemo(() => {
    const joinedName = Array.isArray(name) ? name.join('.') : name
    return [t(`${formNamespace}.entity.${joinedName}`), labelPostfix].join(' ')
  }, [formNamespace, labelPostfix, t, name])

  const classes = useMemo(() => {
    return classnames(className, styles.formItemWidget)
  }, [className])

  const tooltipText = useMemo(() => {
    const tooltipKey = `${formNamespace}.entity.${name}-long`
    return i18n.exists(tooltipKey) && t(tooltipKey)
  }, [t, name, i18n, formNamespace])

  return (
    <InputContext.Provider value={{ hasTooltip, tooltipText }}>
      <Form.Item
        className={classes}
        name={name}
        label={inputLabel}
        rules={mergedRules}
        validateTrigger="onBlur"
        {...inputProps}
      >
        {children}
      </Form.Item>
    </InputContext.Provider>
  )
}

export default FormItemWidget

FormItemWidget.propTypes = {
  className: PropTypes.string,
  labelPostfix: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  children: PropTypes.node.isRequired,
  rules: PropTypes.array,
  hasTooltip: PropTypes.bool
}
