import { createSlice } from '@reduxjs/toolkit'
import { systemLogApi } from 'services/api'

const initialState = {
  systemLogList: {},
  isListLoading: false,
  error: null
}

const systemLogSlice = createSlice({
  name: 'systemlog',
  initialState,
  reducers: {
    getSystemLogListStart (state) {
      state.error = null
      state.isListLoading = true
    },
    getSystemLogListSuccess (state, { payload }) {
      state.systemLogList = payload
      state.error = null
      state.isListLoading = false
    },
    getSystemLogListFailed (state, { payload }) {
      state.systemLogList = {}
      state.error = payload.error
      state.isListLoading = false
    },
    clear (state) {
      state.systemLogList = {}
      state.error = null
    }
  }
})

const {
  getSystemLogListStart,
  getSystemLogListSuccess,
  getSystemLogListFailed,
  clear
} = systemLogSlice.actions

const getSystemLogList = (filters) => async dispatch => {
  try {
    dispatch(getSystemLogListStart())
    const systemLogList = await systemLogApi.getPaginatedSystemLogs(filters)
    dispatch(getSystemLogListSuccess(systemLogList))
  } catch (error) {
    dispatch(getSystemLogListFailed({ error }))
  }
}

export {
  getSystemLogList,
  clear
}

export default systemLogSlice.reducer
