import { createSlice } from '@reduxjs/toolkit'
import { powerPlantApi } from 'services/api'
import { ModalStatus } from 'models/enum'
import { setActiveSunCollectorList } from './suncollector.slice'
import { setActiveIntervalSunCollectorList } from './intervalconfig.slice'
import { omit } from 'lodash'

const initialState = {
  powerPlantList: [],
  powerPlant: {},
  powerPlantModalStatus: ModalStatus.Hidden,
  isListLoading: false,
  isEntityLoading: false,
  error: null
}

const powerPlantSlice = createSlice({
  name: 'powerplant',
  initialState,
  reducers: {
    getPowerPlantListStart (state) {
      state.error = null
      state.isListLoading = true
    },
    getPowerPlantListSuccess (state, { payload }) {
      state.powerPlantList = payload
      state.error = null
      state.isListLoading = false
    },
    getPowerPlantListFailed (state, { payload }) {
      state.isListLoading = false
      state.error = payload.error
      state.powerPlantList = []
    },
    getPowerPlantSuccess (state, { payload }) {
      state.powerPlant = payload
      state.error = null
      state.isEntityLoading = false
    },
    getPowerPlantFailed (state, { payload }) {
      state.powerPlant = {}
      state.error = payload.error
      state.isEntityLoading = false
    },
    resfreshPowerPlant (state, { payload }) {
      state.powerPlantList = state.powerPlantList.map(powerPlant => {
        return powerPlant.id === payload.id ? payload : powerPlant
      })
    },
    createPowerPlantSuccess (state) {
      state.powerPlant = {}
      state.error = null
      state.powerPlantModalStatus = ModalStatus.Hidden
      state.isEntityLoading = false
    },
    createPowerPlantFailed (state, { payload }) {
      state.error = payload.error
      state.isEntityLoading = false
    },
    editPowerPlantSuccess (state) {
      state.powerPlant = {}
      state.error = null
      state.powerPlantModalStatus = ModalStatus.Hidden
      state.isEntityLoading = false
    },
    editPowerPlantFailed (state, { payload }) {
      state.error = payload.error
      state.isEntityLoading = false
    },
    getPowerPlantDuplicateSuccess (state, { payload }) {
      state.powerPlant = omit(payload, ['name', 'eic'])
      state.error = null
      state.isEntityLoading = false
    },
    getPowerPlantDuplicateFailed (state, { payload }) {
      state.powerPlant = {}
      state.error = payload.error
      state.isEntityLoading = false
    },
    inactivatePowerPlantSuccess (state, { payload }) {
      state.error = null
      state.powerPlantList = state.powerPlantList.map(powerPlant => ({
        ...powerPlant,
        isActive: powerPlant.id === payload ? false : powerPlant.isActive
      }))
    },
    inactivatePowerPlantFailed (state, { payload }) {
      state.error = payload.error
    },
    activatePowerPlantSuccess (state, { payload }) {
      state.error = null
      state.powerPlantList = state.powerPlantList.map(powerPlant => ({
        ...powerPlant,
        isActive: powerPlant.id === payload ? true : powerPlant.isActive
      }))
    },
    activatePowerPlantFailed (state, { payload }) {
      state.error = payload.customError
    },
    setPowerPlantModalStatus (state, { payload }) {
      state.powerPlantModalStatus = payload
      if (payload === ModalStatus.Hidden) {
        state.powerPlant = {}
      }
    },
    setPowerPlantLoading (state) {
      state.isEntityLoading = true
    },
    clear (state) {
      state.powerPlantList = []
      state.powerPlant = {}
      state.powerPlantModalStatus = ModalStatus.Hidden
      state.error = null
    }
  }
})

const {
  getPowerPlantListStart,
  getPowerPlantListSuccess,
  getPowerPlantListFailed,
  getPowerPlantSuccess,
  getPowerPlantFailed,
  resfreshPowerPlant,
  createPowerPlantSuccess,
  createPowerPlantFailed,
  editPowerPlantSuccess,
  editPowerPlantFailed,
  getPowerPlantDuplicateSuccess,
  getPowerPlantDuplicateFailed,
  inactivatePowerPlantSuccess,
  inactivatePowerPlantFailed,
  activatePowerPlantSuccess,
  activatePowerPlantFailed,
  setPowerPlantModalStatus,
  setPowerPlantLoading,
  clear
} = powerPlantSlice.actions

const getPowerPlantList = () => async dispatch => {
  try {
    dispatch(getPowerPlantListStart())
    const powerPlantList = await powerPlantApi.getAllPowerPlant()
    dispatch(getPowerPlantListSuccess(powerPlantList))
  } catch (error) {
    dispatch(getPowerPlantListFailed({ error }))
  }
}

const getPowerPlant = (id) => async dispatch => {
  try {
    dispatch(setPowerPlantLoading())
    const powerPlant = await powerPlantApi.getPowerPlant({ id })
    dispatch(getPowerPlantSuccess(powerPlant))
  } catch (error) {
    dispatch(getPowerPlantFailed({ error }))
  }
}

const refreshPowerPlant = (id) => async dispatch => {
  const powerPlant = await powerPlantApi.getPowerPlant({ id })
  dispatch(resfreshPowerPlant(powerPlant))
}

const createPowerPlant = (createPowerPlantDto) => async dispatch => {
  try {
    dispatch(setPowerPlantLoading())
    await powerPlantApi.createPowerPlant({ createPowerPlantDto })
    dispatch(createPowerPlantSuccess())
    dispatch(getPowerPlantList())
  } catch (error) {
    dispatch(createPowerPlantFailed({ error }))
  }
}

const editPowerPlant = (id, updatePowerPlantDto) => async dispatch => {
  try {
    dispatch(setPowerPlantLoading())
    await powerPlantApi.editPowerPlant({ id, updatePowerPlantDto })
    dispatch(editPowerPlantSuccess())
    dispatch(getPowerPlantList())
  } catch (error) {
    dispatch(editPowerPlantFailed({ error }))
  }
}

const getPowerPlantDuplicate = (id) => async dispatch => {
  try {
    dispatch(setPowerPlantLoading())
    const powerPlant = await powerPlantApi.getPowerPlant({ id })
    dispatch(getPowerPlantDuplicateSuccess(powerPlant))
  } catch (error) {
    dispatch(getPowerPlantDuplicateFailed({ error }))
  }
}

const duplicatePowerPlant = (id, createPowerPlantDto) => async dispatch => {
  try {
    dispatch(setPowerPlantLoading())
    await powerPlantApi.duplicatePowerPlant({ id, createPowerPlantDto })
    dispatch(createPowerPlantSuccess())
    dispatch(getPowerPlantList())
  } catch (error) {
    dispatch(createPowerPlantFailed({ error }))
  }
}

const inactivatePowerPlant = (id) => async dispatch => {
  try {
    await powerPlantApi.inactivatePowerPlant({ id })
    await dispatch(inactivatePowerPlantSuccess(id))
    await dispatch(setActiveSunCollectorList(false))
    await dispatch(setActiveIntervalSunCollectorList(false))
  } catch (error) {
    dispatch(inactivatePowerPlantFailed({ error }))
  }
}

const activatePowerPlant = (id) => async dispatch => {
  try {
    await powerPlantApi.activatePowerPlant({ id })
    await dispatch(activatePowerPlantSuccess(id))
    await dispatch(setActiveSunCollectorList(true))
    await dispatch(setActiveIntervalSunCollectorList(true))
    return { isSuccess: true }
  } catch (customError) {
    dispatch(activatePowerPlantFailed({ customError }))
    return { isSuccess: false, customError }
  }
}

const checkPowerPlantNameUniqueness = (name) => async dispatch => {
  try {
    const powerPlantList = await powerPlantApi.getAllPowerPlant({ name })
    return powerPlantList.length
  } catch (error) {
    return 0
  }
}

const checkEicUniqueness = (eic) => async dispatch => {
  try {
    const powerPlantList = await powerPlantApi.getAllPowerPlant({ eic })
    return powerPlantList.length
  } catch (error) {
    return 0
  }
}

export {
  getPowerPlantList,
  getPowerPlant,
  refreshPowerPlant,
  createPowerPlant,
  editPowerPlant,
  getPowerPlantDuplicate,
  duplicatePowerPlant,
  inactivatePowerPlant,
  activatePowerPlant,
  setPowerPlantModalStatus,
  checkPowerPlantNameUniqueness,
  checkEicUniqueness,
  clear
}

export default powerPlantSlice.reducer
