import React from 'react'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressCard, faChevronDown, faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import LoginModal from 'components/views/Login/LoginModal'
import classnames from 'classnames'
import styles from './Header.module.scss'
import MenuComponent from '../Menu/MenuComponent'
import useHeader from './hooks/useHeader'
import { Dropdown, Menu, Select } from 'antd'
import SelectWidget from 'components/widgets/Select/SelectWidget'
import PermissionWidget from 'components/widgets/PermissionWidget'
import { Role } from 'models/enum'

const HeaderComponent = () => {
  const { t } = useTranslation()
  const {
    username,
    isMenuOpen,
    isLoggedIn,
    isSysAdminMode,
    logoSource,
    isUpdating,
    headerClasses,
    companyProfileList,
    sessionCompanyProfileId,
    isCompanyProfileListLoading,
    handleLogout,
    handleToggleMenu,
    handleOnLoginClick,
    handleClickChangePassword,
    handleChangeProfile
  } = useHeader()

  const authMenuItem = (
    <Menu>
      <Menu.Item key="password" onClick={handleClickChangePassword}>
        {t('navigation.change-password.title')}
      </Menu.Item>
    </Menu>
  )

  const profiles = companyProfileList?.map((profile) => (
    <Select.Option key={profile.id} value={profile.id}>
      {profile.name}
    </Select.Option>
  ))

  if (isUpdating) {
    return <nav />
  }

  return (
    <nav className={headerClasses}>
      <div className={styles.logoContainer}>
        <div
          className={classnames(styles.menuToggle, { [styles.open]: isMenuOpen })}
          onClick={handleToggleMenu}
        >
          <span className={styles.toggleLine} />
          <span className={styles.toggleLine} />
          <span className={styles.toggleLine} />
        </div>
        <NavLink to="/">
          <img className={styles.logo} src={logoSource} alt="MET" />
        </NavLink>
      </div>
      <div className={styles.navigation}>
        {isSysAdminMode ? (
          <span className={styles.sysAdminMenuTitle}>{t('navigation.sysadmin')}</span>
        ) : null}
        <MenuComponent />
        <ul className={styles.authMenu}>
          {isLoggedIn ? (
            <>
              <Dropdown
                align={{ offset: [0, 10] }}
                trigger={['click']}
                overlay={authMenuItem}
                placement="bottomCenter"
              >
                <li className={styles.authMenuItem}>
                  <span className={styles.loggedIn}>
                    <FontAwesomeIcon
                      icon={faUser}
                      className={styles.icon}
                    />
                    <span className={styles.menuItemText}>
                      {t('navigation.logged-in')}:
                    </span>
                    <strong> {username}</strong>
                  </span>
                  <FontAwesomeIcon icon={faChevronDown} color="white" />
                </li>
              </Dropdown>
              <PermissionWidget requiredRole={[Role.SysAdmin]}>
                <li className={styles.selectProfileItem}>
                  <FontAwesomeIcon icon={faAddressCard} color="white" />
                  <SelectWidget
                    allowClear={!isSysAdminMode}
                    disabled={isCompanyProfileListLoading}
                    suffixIcon={<FontAwesomeIcon icon={faChevronDown} color="white" />}
                    bordered={false}
                    className={styles.selectProfile}
                    placeholder={t('navigation.choose-profile')}
                    onChange={handleChangeProfile}
                    value={sessionCompanyProfileId}
                    showSearch={false}
                  >
                    {profiles}
                  </SelectWidget>
                </li>
              </PermissionWidget>
              <li className={styles.authMenuItem}>
                <NavLink
                  to="/logout"
                  className={styles.link}
                  role="menuitem"
                  onClick={handleLogout}
                >
                  <FontAwesomeIcon icon={faSignOutAlt} className={styles.icon} />
                  <span className={styles.menuItemText}>{t('navigation.logout')}</span>
                </NavLink>
              </li>
            </>) : (
              <li className={styles.authMenuItem}>
                <NavLink to="/" className={styles.link} onClick={handleOnLoginClick}>
                  {t('navigation.login')}
                </NavLink>
              </li>
            )}
        </ul>
      </div>
      <LoginModal />
    </nav>
  )
}

export default HeaderComponent
