import { createSlice } from '@reduxjs/toolkit'
import { dataImportApi } from 'services/api'
import { ExcelValidationErrors } from 'utils/response'

const initialState = {
  isProductionLoading: false,
  isWeatherLoading: false,
  isForecastLoading: false,
  isProductionMkpLoading: false,
  error: null
}

const dataimportSlice = createSlice({
  name: 'dataimport',
  initialState,
  reducers: {
    // Termelés tényadat
    uploadProductionStarted (state) {
      state.isProductionLoading = true
    },
    uploadProductionSuccess (state) {
      state.isProductionLoading = false
    },
    uploadProductionFailed (state, { payload }) {
      state.isProductionLoading = false
      state.error = payload.error
    },
    // Idójárás tényadat
    uploadWeatherStarted (state) {
      state.isWeatherLoading = true
    },
    uploadWeatherSuccess (state) {
      state.isWeatherLoading = false
    },
    uploadWeatherFailed (state, { payload }) {
      state.isWeatherLoading = false
      state.error = payload.error
    },
    // Időjárás előrejelzés
    uploadForecastStarted (state) {
      state.isForecastLoading = true
    },
    uploadForecastSuccess (state) {
      state.isForecastLoading = false
    },
    uploadForecastFailed (state, { payload }) {
      state.isForecastLoading = false
      state.error = payload.error
    },
    // MKP tényadat
    uploadProductionMkpStarted (state) {
      state.isProductionMkpLoading = true
    },
    uploadProductionMkpSuccess (state) {
      state.isProductionMkpLoading = false
    },
    uploadProductionMkpFailed (state, { payload }) {
      state.isProductionMkpLoading = false
      state.error = payload.error
    }
  }
})

const {
  uploadProductionStarted,
  uploadProductionSuccess,
  uploadProductionFailed,
  uploadWeatherStarted,
  uploadWeatherSuccess,
  uploadWeatherFailed,
  uploadForecastStarted,
  uploadForecastSuccess,
  uploadForecastFailed,
  uploadProductionMkpStarted,
  uploadProductionMkpSuccess,
  uploadProductionMkpFailed
} = dataimportSlice.actions

const uploadProduction = (data) => async dispatch => {
  try {
    dispatch(uploadProductionStarted())
    await dataImportApi.production(data)
    dispatch(uploadProductionSuccess())
    return { isSuccess: true }
  } catch (customError) {
    if (customError.errorCode === ExcelValidationErrors) {
      dispatch(uploadProductionFailed({ customError }))
      return { isSuccess: false, customError }
    } else {
      dispatch(uploadProductionFailed({ error: customError }))
      return { isSuccess: false }
    }
  }
}

const uploadWeather = (data) => async dispatch => {
  try {
    dispatch(uploadWeatherStarted())
    await dataImportApi.weather(data)
    dispatch(uploadWeatherSuccess())
    return { isSuccess: true }
  } catch (customError) {
    if (customError.errorCode === ExcelValidationErrors) {
      dispatch(uploadWeatherFailed({ customError }))
      return { isSuccess: false, customError }
    } else {
      dispatch(uploadWeatherFailed({ error: customError }))
      return { isSuccess: false }
    }
  }
}

const uploadForecast = (data) => async dispatch => {
  try {
    dispatch(uploadForecastStarted())
    await dataImportApi.forecast(data)
    dispatch(uploadForecastSuccess())
    return { isSuccess: true }
  } catch (customError) {
    if (customError.errorCode === ExcelValidationErrors) {
      dispatch(uploadForecastFailed({ customError }))
      return { isSuccess: false, customError }
    } else {
      dispatch(uploadForecastFailed({ error: customError }))
      return { isSuccess: false }
    }
  }
}

const uploadProductionMkp = (data) => async dispatch => {
  try {
    dispatch(uploadProductionMkpStarted())
    let response
    if (data.xmlFile) {
      response = await dataImportApi.productionMkp(data)
    } else {
      response = await dataImportApi.productionMkpCsv(data)
    }
    const { invalidEics } = response
    dispatch(uploadProductionMkpSuccess({ custom: true }))
    return { isSuccess: true, invalidEics }
  } catch (customError) {
    if (customError.errorCode === ExcelValidationErrors) {
      dispatch(uploadProductionMkpFailed({ customError }))
      return { isSuccess: false, customError }
    } else {
      dispatch(uploadProductionMkpFailed({ error: customError }))
      return { isSuccess: false }
    }
  }
}

const downloadProductionSample = () => async () => {
  try {
    return await dataImportApi.downloadProductionSample({ responseType: 'blob' })
  } catch {
    return null
  }
}

const downloadWeatherSample = () => async () => {
  try {
    return await dataImportApi.downloadWeatherSample({ responseType: 'blob' })
  } catch {
    return null
  }
}

const downloadForecastSample = () => async () => {
  try {
    return await dataImportApi.downloadForecastSample({ responseType: 'blob' })
  } catch {
    return null
  }
}

export { uploadProduction, uploadWeather, uploadForecast, uploadProductionMkp }
export { downloadProductionSample, downloadWeatherSample, downloadForecastSample }
export default dataimportSlice.reducer
