"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnalysisApi = exports.AnalysisApiFactory = exports.AnalysisApiFp = exports.AnalysisApiAxiosParamCreator = void 0;
var globalImportUrl = require("url");
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var base_1 = require("../base");
/**
 * AnalysisApi - axios parameter creator
 * @export
 */
exports.AnalysisApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {number} [estimateId]
         * @param {number} [factProductionId]
         * @param {number} [mkpProductionId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadProductionDiff: function (estimateId, factProductionId, mkpProductionId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/api/Analysis/DownloadProductionDiffReportage";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    if (estimateId !== undefined) {
                        localVarQueryParameter['EstimateId'] = estimateId;
                    }
                    if (factProductionId !== undefined) {
                        localVarQueryParameter['FactProductionId'] = factProductionId;
                    }
                    if (mkpProductionId !== undefined) {
                        localVarQueryParameter['MkpProductionId'] = mkpProductionId;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {string} targetDate
         * @param {ProductionDataProvider} provider
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportProductionDataCsv: function (powerPlantId, targetDate, provider, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'powerPlantId' is not null or undefined
                    if (powerPlantId === null || powerPlantId === undefined) {
                        throw new base_1.RequiredError('powerPlantId', 'Required parameter powerPlantId was null or undefined when calling exportProductionDataCsv.');
                    }
                    // verify required parameter 'targetDate' is not null or undefined
                    if (targetDate === null || targetDate === undefined) {
                        throw new base_1.RequiredError('targetDate', 'Required parameter targetDate was null or undefined when calling exportProductionDataCsv.');
                    }
                    // verify required parameter 'provider' is not null or undefined
                    if (provider === null || provider === undefined) {
                        throw new base_1.RequiredError('provider', 'Required parameter provider was null or undefined when calling exportProductionDataCsv.');
                    }
                    localVarPath = "/api/Analysis/ExportProductionDataCsv";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    if (powerPlantId !== undefined) {
                        localVarQueryParameter['PowerPlantId'] = powerPlantId;
                    }
                    if (targetDate !== undefined) {
                        localVarQueryParameter['TargetDate'] = (targetDate instanceof Date) ?
                            targetDate.toISOString() :
                            targetDate;
                    }
                    if (provider !== undefined) {
                        localVarQueryParameter['Provider'] = provider;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} [primaryEstimateParameterEstimateId]
         * @param {boolean} [primaryEstimateParameterHasProductionDifference]
         * @param {boolean} [primaryEstimateParameterEstimateWeatherFieldsHasTemperature]
         * @param {boolean} [primaryEstimateParameterEstimateWeatherFieldsHasRadiation]
         * @param {boolean} [primaryEstimateParameterEstimateWeatherFieldsHasWind]
         * @param {number} [secondaryEstimateParameterEstimateId]
         * @param {boolean} [secondaryEstimateParameterHasProductionDifference]
         * @param {boolean} [secondaryEstimateParameterEstimateWeatherFieldsHasTemperature]
         * @param {boolean} [secondaryEstimateParameterEstimateWeatherFieldsHasRadiation]
         * @param {boolean} [secondaryEstimateParameterEstimateWeatherFieldsHasWind]
         * @param {boolean} [isMKP]
         * @param {boolean} [factWeatherFieldsHasTemperature]
         * @param {boolean} [factWeatherFieldsHasRadiation]
         * @param {boolean} [factWeatherFieldsHasWind]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataAnalysis: function (primaryEstimateParameterEstimateId, primaryEstimateParameterHasProductionDifference, primaryEstimateParameterEstimateWeatherFieldsHasTemperature, primaryEstimateParameterEstimateWeatherFieldsHasRadiation, primaryEstimateParameterEstimateWeatherFieldsHasWind, secondaryEstimateParameterEstimateId, secondaryEstimateParameterHasProductionDifference, secondaryEstimateParameterEstimateWeatherFieldsHasTemperature, secondaryEstimateParameterEstimateWeatherFieldsHasRadiation, secondaryEstimateParameterEstimateWeatherFieldsHasWind, isMKP, factWeatherFieldsHasTemperature, factWeatherFieldsHasRadiation, factWeatherFieldsHasWind, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/api/Analysis/DataAnalysis";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    if (primaryEstimateParameterEstimateId !== undefined) {
                        localVarQueryParameter['PrimaryEstimateParameter.EstimateId'] = primaryEstimateParameterEstimateId;
                    }
                    if (primaryEstimateParameterHasProductionDifference !== undefined) {
                        localVarQueryParameter['PrimaryEstimateParameter.HasProductionDifference'] = primaryEstimateParameterHasProductionDifference;
                    }
                    if (primaryEstimateParameterEstimateWeatherFieldsHasTemperature !== undefined) {
                        localVarQueryParameter['PrimaryEstimateParameter.EstimateWeatherFields.HasTemperature'] = primaryEstimateParameterEstimateWeatherFieldsHasTemperature;
                    }
                    if (primaryEstimateParameterEstimateWeatherFieldsHasRadiation !== undefined) {
                        localVarQueryParameter['PrimaryEstimateParameter.EstimateWeatherFields.HasRadiation'] = primaryEstimateParameterEstimateWeatherFieldsHasRadiation;
                    }
                    if (primaryEstimateParameterEstimateWeatherFieldsHasWind !== undefined) {
                        localVarQueryParameter['PrimaryEstimateParameter.EstimateWeatherFields.HasWind'] = primaryEstimateParameterEstimateWeatherFieldsHasWind;
                    }
                    if (secondaryEstimateParameterEstimateId !== undefined) {
                        localVarQueryParameter['SecondaryEstimateParameter.EstimateId'] = secondaryEstimateParameterEstimateId;
                    }
                    if (secondaryEstimateParameterHasProductionDifference !== undefined) {
                        localVarQueryParameter['SecondaryEstimateParameter.HasProductionDifference'] = secondaryEstimateParameterHasProductionDifference;
                    }
                    if (secondaryEstimateParameterEstimateWeatherFieldsHasTemperature !== undefined) {
                        localVarQueryParameter['SecondaryEstimateParameter.EstimateWeatherFields.HasTemperature'] = secondaryEstimateParameterEstimateWeatherFieldsHasTemperature;
                    }
                    if (secondaryEstimateParameterEstimateWeatherFieldsHasRadiation !== undefined) {
                        localVarQueryParameter['SecondaryEstimateParameter.EstimateWeatherFields.HasRadiation'] = secondaryEstimateParameterEstimateWeatherFieldsHasRadiation;
                    }
                    if (secondaryEstimateParameterEstimateWeatherFieldsHasWind !== undefined) {
                        localVarQueryParameter['SecondaryEstimateParameter.EstimateWeatherFields.HasWind'] = secondaryEstimateParameterEstimateWeatherFieldsHasWind;
                    }
                    if (isMKP !== undefined) {
                        localVarQueryParameter['IsMKP'] = isMKP;
                    }
                    if (factWeatherFieldsHasTemperature !== undefined) {
                        localVarQueryParameter['FactWeatherFields.HasTemperature'] = factWeatherFieldsHasTemperature;
                    }
                    if (factWeatherFieldsHasRadiation !== undefined) {
                        localVarQueryParameter['FactWeatherFields.HasRadiation'] = factWeatherFieldsHasRadiation;
                    }
                    if (factWeatherFieldsHasWind !== undefined) {
                        localVarQueryParameter['FactWeatherFields.HasWind'] = factWeatherFieldsHasWind;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} powerPlantGroupId
         * @param {string} deliveryDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEstimateDataSum: function (powerPlantGroupId, deliveryDate, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'powerPlantGroupId' is not null or undefined
                    if (powerPlantGroupId === null || powerPlantGroupId === undefined) {
                        throw new base_1.RequiredError('powerPlantGroupId', 'Required parameter powerPlantGroupId was null or undefined when calling getEstimateDataSum.');
                    }
                    // verify required parameter 'deliveryDate' is not null or undefined
                    if (deliveryDate === null || deliveryDate === undefined) {
                        throw new base_1.RequiredError('deliveryDate', 'Required parameter deliveryDate was null or undefined when calling getEstimateDataSum.');
                    }
                    localVarPath = "/api/Analysis/EstimateDataSum";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    if (powerPlantGroupId !== undefined) {
                        localVarQueryParameter['powerPlantGroupId'] = powerPlantGroupId;
                    }
                    if (deliveryDate !== undefined) {
                        localVarQueryParameter['deliveryDate'] = (deliveryDate instanceof Date) ?
                            deliveryDate.toISOString() :
                            deliveryDate;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} [powerPlantId]
         * @param {string} [deliveryDate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEstimates: function (powerPlantId, deliveryDate, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/api/Analysis/Estimates";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    if (powerPlantId !== undefined) {
                        localVarQueryParameter['powerPlantId'] = powerPlantId;
                    }
                    if (deliveryDate !== undefined) {
                        localVarQueryParameter['deliveryDate'] = (deliveryDate instanceof Date) ?
                            deliveryDate.toISOString() :
                            deliveryDate;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} [powerPlantId]
         * @param {string} [dateFrom]
         * @param {string} [dateTo]
         * @param {boolean} [isEstimate]
         * @param {boolean} [isFact]
         * @param {boolean} [isMKP]
         * @param {boolean} [isApprovedDA]
         * @param {boolean} [isFinalApprovedID]
         * @param {ForecastProvider} [forecastProvider]
         * @param {boolean} [weatherFieldsHasTemperature]
         * @param {boolean} [weatherFieldsHasRadiation]
         * @param {boolean} [weatherFieldsHasWind]
         * @param {boolean} [factWeatherFieldsHasTemperature]
         * @param {boolean} [factWeatherFieldsHasRadiation]
         * @param {boolean} [factWeatherFieldsHasWind]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLongTermDataAnalysis: function (powerPlantId, dateFrom, dateTo, isEstimate, isFact, isMKP, isApprovedDA, isFinalApprovedID, forecastProvider, weatherFieldsHasTemperature, weatherFieldsHasRadiation, weatherFieldsHasWind, factWeatherFieldsHasTemperature, factWeatherFieldsHasRadiation, factWeatherFieldsHasWind, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/api/Analysis/LongTermDataAnalysis";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    if (powerPlantId !== undefined) {
                        localVarQueryParameter['PowerPlantId'] = powerPlantId;
                    }
                    if (dateFrom !== undefined) {
                        localVarQueryParameter['DateFrom'] = (dateFrom instanceof Date) ?
                            dateFrom.toISOString() :
                            dateFrom;
                    }
                    if (dateTo !== undefined) {
                        localVarQueryParameter['DateTo'] = (dateTo instanceof Date) ?
                            dateTo.toISOString() :
                            dateTo;
                    }
                    if (isEstimate !== undefined) {
                        localVarQueryParameter['IsEstimate'] = isEstimate;
                    }
                    if (isFact !== undefined) {
                        localVarQueryParameter['IsFact'] = isFact;
                    }
                    if (isMKP !== undefined) {
                        localVarQueryParameter['IsMKP'] = isMKP;
                    }
                    if (isApprovedDA !== undefined) {
                        localVarQueryParameter['IsApprovedDA'] = isApprovedDA;
                    }
                    if (isFinalApprovedID !== undefined) {
                        localVarQueryParameter['IsFinalApprovedID'] = isFinalApprovedID;
                    }
                    if (forecastProvider !== undefined) {
                        localVarQueryParameter['ForecastProvider'] = forecastProvider;
                    }
                    if (weatherFieldsHasTemperature !== undefined) {
                        localVarQueryParameter['WeatherFields.HasTemperature'] = weatherFieldsHasTemperature;
                    }
                    if (weatherFieldsHasRadiation !== undefined) {
                        localVarQueryParameter['WeatherFields.HasRadiation'] = weatherFieldsHasRadiation;
                    }
                    if (weatherFieldsHasWind !== undefined) {
                        localVarQueryParameter['WeatherFields.HasWind'] = weatherFieldsHasWind;
                    }
                    if (factWeatherFieldsHasTemperature !== undefined) {
                        localVarQueryParameter['FactWeatherFields.HasTemperature'] = factWeatherFieldsHasTemperature;
                    }
                    if (factWeatherFieldsHasRadiation !== undefined) {
                        localVarQueryParameter['FactWeatherFields.HasRadiation'] = factWeatherFieldsHasRadiation;
                    }
                    if (factWeatherFieldsHasWind !== undefined) {
                        localVarQueryParameter['FactWeatherFields.HasWind'] = factWeatherFieldsHasWind;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} [powerPlantId]
         * @param {string} [dateFrom]
         * @param {string} [dateTo]
         * @param {boolean} [isEstimate]
         * @param {boolean} [isFact]
         * @param {boolean} [isMKP]
         * @param {boolean} [isApprovedDA]
         * @param {boolean} [isFinalApprovedID]
         * @param {ForecastProvider} [forecastProvider]
         * @param {boolean} [weatherFieldsHasTemperature]
         * @param {boolean} [weatherFieldsHasRadiation]
         * @param {boolean} [weatherFieldsHasWind]
         * @param {boolean} [factWeatherFieldsHasTemperature]
         * @param {boolean} [factWeatherFieldsHasRadiation]
         * @param {boolean} [factWeatherFieldsHasWind]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLongTermDataAnalysisExcelResult: function (powerPlantId, dateFrom, dateTo, isEstimate, isFact, isMKP, isApprovedDA, isFinalApprovedID, forecastProvider, weatherFieldsHasTemperature, weatherFieldsHasRadiation, weatherFieldsHasWind, factWeatherFieldsHasTemperature, factWeatherFieldsHasRadiation, factWeatherFieldsHasWind, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/api/Analysis/LongTermDataAnalysis/Excel";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    if (powerPlantId !== undefined) {
                        localVarQueryParameter['PowerPlantId'] = powerPlantId;
                    }
                    if (dateFrom !== undefined) {
                        localVarQueryParameter['DateFrom'] = (dateFrom instanceof Date) ?
                            dateFrom.toISOString() :
                            dateFrom;
                    }
                    if (dateTo !== undefined) {
                        localVarQueryParameter['DateTo'] = (dateTo instanceof Date) ?
                            dateTo.toISOString() :
                            dateTo;
                    }
                    if (isEstimate !== undefined) {
                        localVarQueryParameter['IsEstimate'] = isEstimate;
                    }
                    if (isFact !== undefined) {
                        localVarQueryParameter['IsFact'] = isFact;
                    }
                    if (isMKP !== undefined) {
                        localVarQueryParameter['IsMKP'] = isMKP;
                    }
                    if (isApprovedDA !== undefined) {
                        localVarQueryParameter['IsApprovedDA'] = isApprovedDA;
                    }
                    if (isFinalApprovedID !== undefined) {
                        localVarQueryParameter['IsFinalApprovedID'] = isFinalApprovedID;
                    }
                    if (forecastProvider !== undefined) {
                        localVarQueryParameter['ForecastProvider'] = forecastProvider;
                    }
                    if (weatherFieldsHasTemperature !== undefined) {
                        localVarQueryParameter['WeatherFields.HasTemperature'] = weatherFieldsHasTemperature;
                    }
                    if (weatherFieldsHasRadiation !== undefined) {
                        localVarQueryParameter['WeatherFields.HasRadiation'] = weatherFieldsHasRadiation;
                    }
                    if (weatherFieldsHasWind !== undefined) {
                        localVarQueryParameter['WeatherFields.HasWind'] = weatherFieldsHasWind;
                    }
                    if (factWeatherFieldsHasTemperature !== undefined) {
                        localVarQueryParameter['FactWeatherFields.HasTemperature'] = factWeatherFieldsHasTemperature;
                    }
                    if (factWeatherFieldsHasRadiation !== undefined) {
                        localVarQueryParameter['FactWeatherFields.HasRadiation'] = factWeatherFieldsHasRadiation;
                    }
                    if (factWeatherFieldsHasWind !== undefined) {
                        localVarQueryParameter['FactWeatherFields.HasWind'] = factWeatherFieldsHasWind;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {string} deliveryDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductionAnalysis: function (powerPlantId, deliveryDate, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'powerPlantId' is not null or undefined
                    if (powerPlantId === null || powerPlantId === undefined) {
                        throw new base_1.RequiredError('powerPlantId', 'Required parameter powerPlantId was null or undefined when calling getProductionAnalysis.');
                    }
                    // verify required parameter 'deliveryDate' is not null or undefined
                    if (deliveryDate === null || deliveryDate === undefined) {
                        throw new base_1.RequiredError('deliveryDate', 'Required parameter deliveryDate was null or undefined when calling getProductionAnalysis.');
                    }
                    localVarPath = "/api/Analysis/ProductionAnalysis";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    if (powerPlantId !== undefined) {
                        localVarQueryParameter['powerPlantId'] = powerPlantId;
                    }
                    if (deliveryDate !== undefined) {
                        localVarQueryParameter['deliveryDate'] = (deliveryDate instanceof Date) ?
                            deliveryDate.toISOString() :
                            deliveryDate;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {string} targetDate
         * @param {ProductionDataProvider} provider
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductionData: function (powerPlantId, targetDate, provider, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'powerPlantId' is not null or undefined
                    if (powerPlantId === null || powerPlantId === undefined) {
                        throw new base_1.RequiredError('powerPlantId', 'Required parameter powerPlantId was null or undefined when calling getProductionData.');
                    }
                    // verify required parameter 'targetDate' is not null or undefined
                    if (targetDate === null || targetDate === undefined) {
                        throw new base_1.RequiredError('targetDate', 'Required parameter targetDate was null or undefined when calling getProductionData.');
                    }
                    // verify required parameter 'provider' is not null or undefined
                    if (provider === null || provider === undefined) {
                        throw new base_1.RequiredError('provider', 'Required parameter provider was null or undefined when calling getProductionData.');
                    }
                    localVarPath = "/api/Analysis/ProductionData";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    if (powerPlantId !== undefined) {
                        localVarQueryParameter['PowerPlantId'] = powerPlantId;
                    }
                    if (targetDate !== undefined) {
                        localVarQueryParameter['TargetDate'] = (targetDate instanceof Date) ?
                            targetDate.toISOString() :
                            targetDate;
                    }
                    if (provider !== undefined) {
                        localVarQueryParameter['Provider'] = provider;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} [estimateId]
         * @param {number} [factProductionId]
         * @param {number} [mkpProductionId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductionDataDiffAnalysis: function (estimateId, factProductionId, mkpProductionId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/api/Analysis/ProductionDataDiffAnalysis";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    if (estimateId !== undefined) {
                        localVarQueryParameter['EstimateId'] = estimateId;
                    }
                    if (factProductionId !== undefined) {
                        localVarQueryParameter['FactProductionId'] = factProductionId;
                    }
                    if (mkpProductionId !== undefined) {
                        localVarQueryParameter['MkpProductionId'] = mkpProductionId;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} [powerPlantId]
         * @param {string} [deliveryDate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSecondaryEstimates: function (powerPlantId, deliveryDate, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/api/Analysis/SecondaryEstimates";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    if (powerPlantId !== undefined) {
                        localVarQueryParameter['powerPlantId'] = powerPlantId;
                    }
                    if (deliveryDate !== undefined) {
                        localVarQueryParameter['deliveryDate'] = (deliveryDate instanceof Date) ?
                            deliveryDate.toISOString() :
                            deliveryDate;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * AnalysisApi - functional programming interface
 * @export
 */
exports.AnalysisApiFp = function (configuration) {
    return {
        /**
         *
         * @param {number} [estimateId]
         * @param {number} [factProductionId]
         * @param {number} [mkpProductionId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadProductionDiff: function (estimateId, factProductionId, mkpProductionId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.AnalysisApiAxiosParamCreator(configuration).downloadProductionDiff(estimateId, factProductionId, mkpProductionId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {string} targetDate
         * @param {ProductionDataProvider} provider
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportProductionDataCsv: function (powerPlantId, targetDate, provider, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.AnalysisApiAxiosParamCreator(configuration).exportProductionDataCsv(powerPlantId, targetDate, provider, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [primaryEstimateParameterEstimateId]
         * @param {boolean} [primaryEstimateParameterHasProductionDifference]
         * @param {boolean} [primaryEstimateParameterEstimateWeatherFieldsHasTemperature]
         * @param {boolean} [primaryEstimateParameterEstimateWeatherFieldsHasRadiation]
         * @param {boolean} [primaryEstimateParameterEstimateWeatherFieldsHasWind]
         * @param {number} [secondaryEstimateParameterEstimateId]
         * @param {boolean} [secondaryEstimateParameterHasProductionDifference]
         * @param {boolean} [secondaryEstimateParameterEstimateWeatherFieldsHasTemperature]
         * @param {boolean} [secondaryEstimateParameterEstimateWeatherFieldsHasRadiation]
         * @param {boolean} [secondaryEstimateParameterEstimateWeatherFieldsHasWind]
         * @param {boolean} [isMKP]
         * @param {boolean} [factWeatherFieldsHasTemperature]
         * @param {boolean} [factWeatherFieldsHasRadiation]
         * @param {boolean} [factWeatherFieldsHasWind]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataAnalysis: function (primaryEstimateParameterEstimateId, primaryEstimateParameterHasProductionDifference, primaryEstimateParameterEstimateWeatherFieldsHasTemperature, primaryEstimateParameterEstimateWeatherFieldsHasRadiation, primaryEstimateParameterEstimateWeatherFieldsHasWind, secondaryEstimateParameterEstimateId, secondaryEstimateParameterHasProductionDifference, secondaryEstimateParameterEstimateWeatherFieldsHasTemperature, secondaryEstimateParameterEstimateWeatherFieldsHasRadiation, secondaryEstimateParameterEstimateWeatherFieldsHasWind, isMKP, factWeatherFieldsHasTemperature, factWeatherFieldsHasRadiation, factWeatherFieldsHasWind, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.AnalysisApiAxiosParamCreator(configuration).getDataAnalysis(primaryEstimateParameterEstimateId, primaryEstimateParameterHasProductionDifference, primaryEstimateParameterEstimateWeatherFieldsHasTemperature, primaryEstimateParameterEstimateWeatherFieldsHasRadiation, primaryEstimateParameterEstimateWeatherFieldsHasWind, secondaryEstimateParameterEstimateId, secondaryEstimateParameterHasProductionDifference, secondaryEstimateParameterEstimateWeatherFieldsHasTemperature, secondaryEstimateParameterEstimateWeatherFieldsHasRadiation, secondaryEstimateParameterEstimateWeatherFieldsHasWind, isMKP, factWeatherFieldsHasTemperature, factWeatherFieldsHasRadiation, factWeatherFieldsHasWind, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} powerPlantGroupId
         * @param {string} deliveryDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEstimateDataSum: function (powerPlantGroupId, deliveryDate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.AnalysisApiAxiosParamCreator(configuration).getEstimateDataSum(powerPlantGroupId, deliveryDate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [powerPlantId]
         * @param {string} [deliveryDate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEstimates: function (powerPlantId, deliveryDate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.AnalysisApiAxiosParamCreator(configuration).getEstimates(powerPlantId, deliveryDate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [powerPlantId]
         * @param {string} [dateFrom]
         * @param {string} [dateTo]
         * @param {boolean} [isEstimate]
         * @param {boolean} [isFact]
         * @param {boolean} [isMKP]
         * @param {boolean} [isApprovedDA]
         * @param {boolean} [isFinalApprovedID]
         * @param {ForecastProvider} [forecastProvider]
         * @param {boolean} [weatherFieldsHasTemperature]
         * @param {boolean} [weatherFieldsHasRadiation]
         * @param {boolean} [weatherFieldsHasWind]
         * @param {boolean} [factWeatherFieldsHasTemperature]
         * @param {boolean} [factWeatherFieldsHasRadiation]
         * @param {boolean} [factWeatherFieldsHasWind]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLongTermDataAnalysis: function (powerPlantId, dateFrom, dateTo, isEstimate, isFact, isMKP, isApprovedDA, isFinalApprovedID, forecastProvider, weatherFieldsHasTemperature, weatherFieldsHasRadiation, weatherFieldsHasWind, factWeatherFieldsHasTemperature, factWeatherFieldsHasRadiation, factWeatherFieldsHasWind, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.AnalysisApiAxiosParamCreator(configuration).getLongTermDataAnalysis(powerPlantId, dateFrom, dateTo, isEstimate, isFact, isMKP, isApprovedDA, isFinalApprovedID, forecastProvider, weatherFieldsHasTemperature, weatherFieldsHasRadiation, weatherFieldsHasWind, factWeatherFieldsHasTemperature, factWeatherFieldsHasRadiation, factWeatherFieldsHasWind, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [powerPlantId]
         * @param {string} [dateFrom]
         * @param {string} [dateTo]
         * @param {boolean} [isEstimate]
         * @param {boolean} [isFact]
         * @param {boolean} [isMKP]
         * @param {boolean} [isApprovedDA]
         * @param {boolean} [isFinalApprovedID]
         * @param {ForecastProvider} [forecastProvider]
         * @param {boolean} [weatherFieldsHasTemperature]
         * @param {boolean} [weatherFieldsHasRadiation]
         * @param {boolean} [weatherFieldsHasWind]
         * @param {boolean} [factWeatherFieldsHasTemperature]
         * @param {boolean} [factWeatherFieldsHasRadiation]
         * @param {boolean} [factWeatherFieldsHasWind]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLongTermDataAnalysisExcelResult: function (powerPlantId, dateFrom, dateTo, isEstimate, isFact, isMKP, isApprovedDA, isFinalApprovedID, forecastProvider, weatherFieldsHasTemperature, weatherFieldsHasRadiation, weatherFieldsHasWind, factWeatherFieldsHasTemperature, factWeatherFieldsHasRadiation, factWeatherFieldsHasWind, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.AnalysisApiAxiosParamCreator(configuration).getLongTermDataAnalysisExcelResult(powerPlantId, dateFrom, dateTo, isEstimate, isFact, isMKP, isApprovedDA, isFinalApprovedID, forecastProvider, weatherFieldsHasTemperature, weatherFieldsHasRadiation, weatherFieldsHasWind, factWeatherFieldsHasTemperature, factWeatherFieldsHasRadiation, factWeatherFieldsHasWind, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {string} deliveryDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductionAnalysis: function (powerPlantId, deliveryDate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.AnalysisApiAxiosParamCreator(configuration).getProductionAnalysis(powerPlantId, deliveryDate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {string} targetDate
         * @param {ProductionDataProvider} provider
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductionData: function (powerPlantId, targetDate, provider, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.AnalysisApiAxiosParamCreator(configuration).getProductionData(powerPlantId, targetDate, provider, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [estimateId]
         * @param {number} [factProductionId]
         * @param {number} [mkpProductionId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductionDataDiffAnalysis: function (estimateId, factProductionId, mkpProductionId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.AnalysisApiAxiosParamCreator(configuration).getProductionDataDiffAnalysis(estimateId, factProductionId, mkpProductionId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [powerPlantId]
         * @param {string} [deliveryDate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSecondaryEstimates: function (powerPlantId, deliveryDate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.AnalysisApiAxiosParamCreator(configuration).getSecondaryEstimates(powerPlantId, deliveryDate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
/**
 * AnalysisApi - factory interface
 * @export
 */
exports.AnalysisApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {number} [estimateId]
         * @param {number} [factProductionId]
         * @param {number} [mkpProductionId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadProductionDiff: function (estimateId, factProductionId, mkpProductionId, options) {
            return exports.AnalysisApiFp(configuration).downloadProductionDiff(estimateId, factProductionId, mkpProductionId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {string} targetDate
         * @param {ProductionDataProvider} provider
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportProductionDataCsv: function (powerPlantId, targetDate, provider, options) {
            return exports.AnalysisApiFp(configuration).exportProductionDataCsv(powerPlantId, targetDate, provider, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} [primaryEstimateParameterEstimateId]
         * @param {boolean} [primaryEstimateParameterHasProductionDifference]
         * @param {boolean} [primaryEstimateParameterEstimateWeatherFieldsHasTemperature]
         * @param {boolean} [primaryEstimateParameterEstimateWeatherFieldsHasRadiation]
         * @param {boolean} [primaryEstimateParameterEstimateWeatherFieldsHasWind]
         * @param {number} [secondaryEstimateParameterEstimateId]
         * @param {boolean} [secondaryEstimateParameterHasProductionDifference]
         * @param {boolean} [secondaryEstimateParameterEstimateWeatherFieldsHasTemperature]
         * @param {boolean} [secondaryEstimateParameterEstimateWeatherFieldsHasRadiation]
         * @param {boolean} [secondaryEstimateParameterEstimateWeatherFieldsHasWind]
         * @param {boolean} [isMKP]
         * @param {boolean} [factWeatherFieldsHasTemperature]
         * @param {boolean} [factWeatherFieldsHasRadiation]
         * @param {boolean} [factWeatherFieldsHasWind]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataAnalysis: function (primaryEstimateParameterEstimateId, primaryEstimateParameterHasProductionDifference, primaryEstimateParameterEstimateWeatherFieldsHasTemperature, primaryEstimateParameterEstimateWeatherFieldsHasRadiation, primaryEstimateParameterEstimateWeatherFieldsHasWind, secondaryEstimateParameterEstimateId, secondaryEstimateParameterHasProductionDifference, secondaryEstimateParameterEstimateWeatherFieldsHasTemperature, secondaryEstimateParameterEstimateWeatherFieldsHasRadiation, secondaryEstimateParameterEstimateWeatherFieldsHasWind, isMKP, factWeatherFieldsHasTemperature, factWeatherFieldsHasRadiation, factWeatherFieldsHasWind, options) {
            return exports.AnalysisApiFp(configuration).getDataAnalysis(primaryEstimateParameterEstimateId, primaryEstimateParameterHasProductionDifference, primaryEstimateParameterEstimateWeatherFieldsHasTemperature, primaryEstimateParameterEstimateWeatherFieldsHasRadiation, primaryEstimateParameterEstimateWeatherFieldsHasWind, secondaryEstimateParameterEstimateId, secondaryEstimateParameterHasProductionDifference, secondaryEstimateParameterEstimateWeatherFieldsHasTemperature, secondaryEstimateParameterEstimateWeatherFieldsHasRadiation, secondaryEstimateParameterEstimateWeatherFieldsHasWind, isMKP, factWeatherFieldsHasTemperature, factWeatherFieldsHasRadiation, factWeatherFieldsHasWind, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} powerPlantGroupId
         * @param {string} deliveryDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEstimateDataSum: function (powerPlantGroupId, deliveryDate, options) {
            return exports.AnalysisApiFp(configuration).getEstimateDataSum(powerPlantGroupId, deliveryDate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} [powerPlantId]
         * @param {string} [deliveryDate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEstimates: function (powerPlantId, deliveryDate, options) {
            return exports.AnalysisApiFp(configuration).getEstimates(powerPlantId, deliveryDate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} [powerPlantId]
         * @param {string} [dateFrom]
         * @param {string} [dateTo]
         * @param {boolean} [isEstimate]
         * @param {boolean} [isFact]
         * @param {boolean} [isMKP]
         * @param {boolean} [isApprovedDA]
         * @param {boolean} [isFinalApprovedID]
         * @param {ForecastProvider} [forecastProvider]
         * @param {boolean} [weatherFieldsHasTemperature]
         * @param {boolean} [weatherFieldsHasRadiation]
         * @param {boolean} [weatherFieldsHasWind]
         * @param {boolean} [factWeatherFieldsHasTemperature]
         * @param {boolean} [factWeatherFieldsHasRadiation]
         * @param {boolean} [factWeatherFieldsHasWind]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLongTermDataAnalysis: function (powerPlantId, dateFrom, dateTo, isEstimate, isFact, isMKP, isApprovedDA, isFinalApprovedID, forecastProvider, weatherFieldsHasTemperature, weatherFieldsHasRadiation, weatherFieldsHasWind, factWeatherFieldsHasTemperature, factWeatherFieldsHasRadiation, factWeatherFieldsHasWind, options) {
            return exports.AnalysisApiFp(configuration).getLongTermDataAnalysis(powerPlantId, dateFrom, dateTo, isEstimate, isFact, isMKP, isApprovedDA, isFinalApprovedID, forecastProvider, weatherFieldsHasTemperature, weatherFieldsHasRadiation, weatherFieldsHasWind, factWeatherFieldsHasTemperature, factWeatherFieldsHasRadiation, factWeatherFieldsHasWind, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} [powerPlantId]
         * @param {string} [dateFrom]
         * @param {string} [dateTo]
         * @param {boolean} [isEstimate]
         * @param {boolean} [isFact]
         * @param {boolean} [isMKP]
         * @param {boolean} [isApprovedDA]
         * @param {boolean} [isFinalApprovedID]
         * @param {ForecastProvider} [forecastProvider]
         * @param {boolean} [weatherFieldsHasTemperature]
         * @param {boolean} [weatherFieldsHasRadiation]
         * @param {boolean} [weatherFieldsHasWind]
         * @param {boolean} [factWeatherFieldsHasTemperature]
         * @param {boolean} [factWeatherFieldsHasRadiation]
         * @param {boolean} [factWeatherFieldsHasWind]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLongTermDataAnalysisExcelResult: function (powerPlantId, dateFrom, dateTo, isEstimate, isFact, isMKP, isApprovedDA, isFinalApprovedID, forecastProvider, weatherFieldsHasTemperature, weatherFieldsHasRadiation, weatherFieldsHasWind, factWeatherFieldsHasTemperature, factWeatherFieldsHasRadiation, factWeatherFieldsHasWind, options) {
            return exports.AnalysisApiFp(configuration).getLongTermDataAnalysisExcelResult(powerPlantId, dateFrom, dateTo, isEstimate, isFact, isMKP, isApprovedDA, isFinalApprovedID, forecastProvider, weatherFieldsHasTemperature, weatherFieldsHasRadiation, weatherFieldsHasWind, factWeatherFieldsHasTemperature, factWeatherFieldsHasRadiation, factWeatherFieldsHasWind, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {string} deliveryDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductionAnalysis: function (powerPlantId, deliveryDate, options) {
            return exports.AnalysisApiFp(configuration).getProductionAnalysis(powerPlantId, deliveryDate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {string} targetDate
         * @param {ProductionDataProvider} provider
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductionData: function (powerPlantId, targetDate, provider, options) {
            return exports.AnalysisApiFp(configuration).getProductionData(powerPlantId, targetDate, provider, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} [estimateId]
         * @param {number} [factProductionId]
         * @param {number} [mkpProductionId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductionDataDiffAnalysis: function (estimateId, factProductionId, mkpProductionId, options) {
            return exports.AnalysisApiFp(configuration).getProductionDataDiffAnalysis(estimateId, factProductionId, mkpProductionId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} [powerPlantId]
         * @param {string} [deliveryDate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSecondaryEstimates: function (powerPlantId, deliveryDate, options) {
            return exports.AnalysisApiFp(configuration).getSecondaryEstimates(powerPlantId, deliveryDate, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * AnalysisApi - object-oriented interface
 * @export
 * @class AnalysisApi
 * @extends {BaseAPI}
 */
var AnalysisApi = /** @class */ (function (_super) {
    __extends(AnalysisApi, _super);
    function AnalysisApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {AnalysisApiDownloadProductionDiffRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisApi
     */
    AnalysisApi.prototype.downloadProductionDiff = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return exports.AnalysisApiFp(this.configuration).downloadProductionDiff(requestParameters.estimateId, requestParameters.factProductionId, requestParameters.mkpProductionId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {AnalysisApiExportProductionDataCsvRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisApi
     */
    AnalysisApi.prototype.exportProductionDataCsv = function (requestParameters, options) {
        var _this = this;
        return exports.AnalysisApiFp(this.configuration).exportProductionDataCsv(requestParameters.powerPlantId, requestParameters.targetDate, requestParameters.provider, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {AnalysisApiGetDataAnalysisRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisApi
     */
    AnalysisApi.prototype.getDataAnalysis = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return exports.AnalysisApiFp(this.configuration).getDataAnalysis(requestParameters.primaryEstimateParameterEstimateId, requestParameters.primaryEstimateParameterHasProductionDifference, requestParameters.primaryEstimateParameterEstimateWeatherFieldsHasTemperature, requestParameters.primaryEstimateParameterEstimateWeatherFieldsHasRadiation, requestParameters.primaryEstimateParameterEstimateWeatherFieldsHasWind, requestParameters.secondaryEstimateParameterEstimateId, requestParameters.secondaryEstimateParameterHasProductionDifference, requestParameters.secondaryEstimateParameterEstimateWeatherFieldsHasTemperature, requestParameters.secondaryEstimateParameterEstimateWeatherFieldsHasRadiation, requestParameters.secondaryEstimateParameterEstimateWeatherFieldsHasWind, requestParameters.isMKP, requestParameters.factWeatherFieldsHasTemperature, requestParameters.factWeatherFieldsHasRadiation, requestParameters.factWeatherFieldsHasWind, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {AnalysisApiGetEstimateDataSumRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisApi
     */
    AnalysisApi.prototype.getEstimateDataSum = function (requestParameters, options) {
        var _this = this;
        return exports.AnalysisApiFp(this.configuration).getEstimateDataSum(requestParameters.powerPlantGroupId, requestParameters.deliveryDate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {AnalysisApiGetEstimatesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisApi
     */
    AnalysisApi.prototype.getEstimates = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return exports.AnalysisApiFp(this.configuration).getEstimates(requestParameters.powerPlantId, requestParameters.deliveryDate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {AnalysisApiGetLongTermDataAnalysisRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisApi
     */
    AnalysisApi.prototype.getLongTermDataAnalysis = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return exports.AnalysisApiFp(this.configuration).getLongTermDataAnalysis(requestParameters.powerPlantId, requestParameters.dateFrom, requestParameters.dateTo, requestParameters.isEstimate, requestParameters.isFact, requestParameters.isMKP, requestParameters.isApprovedDA, requestParameters.isFinalApprovedID, requestParameters.forecastProvider, requestParameters.weatherFieldsHasTemperature, requestParameters.weatherFieldsHasRadiation, requestParameters.weatherFieldsHasWind, requestParameters.factWeatherFieldsHasTemperature, requestParameters.factWeatherFieldsHasRadiation, requestParameters.factWeatherFieldsHasWind, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {AnalysisApiGetLongTermDataAnalysisExcelResultRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisApi
     */
    AnalysisApi.prototype.getLongTermDataAnalysisExcelResult = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return exports.AnalysisApiFp(this.configuration).getLongTermDataAnalysisExcelResult(requestParameters.powerPlantId, requestParameters.dateFrom, requestParameters.dateTo, requestParameters.isEstimate, requestParameters.isFact, requestParameters.isMKP, requestParameters.isApprovedDA, requestParameters.isFinalApprovedID, requestParameters.forecastProvider, requestParameters.weatherFieldsHasTemperature, requestParameters.weatherFieldsHasRadiation, requestParameters.weatherFieldsHasWind, requestParameters.factWeatherFieldsHasTemperature, requestParameters.factWeatherFieldsHasRadiation, requestParameters.factWeatherFieldsHasWind, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {AnalysisApiGetProductionAnalysisRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisApi
     */
    AnalysisApi.prototype.getProductionAnalysis = function (requestParameters, options) {
        var _this = this;
        return exports.AnalysisApiFp(this.configuration).getProductionAnalysis(requestParameters.powerPlantId, requestParameters.deliveryDate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {AnalysisApiGetProductionDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisApi
     */
    AnalysisApi.prototype.getProductionData = function (requestParameters, options) {
        var _this = this;
        return exports.AnalysisApiFp(this.configuration).getProductionData(requestParameters.powerPlantId, requestParameters.targetDate, requestParameters.provider, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {AnalysisApiGetProductionDataDiffAnalysisRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisApi
     */
    AnalysisApi.prototype.getProductionDataDiffAnalysis = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return exports.AnalysisApiFp(this.configuration).getProductionDataDiffAnalysis(requestParameters.estimateId, requestParameters.factProductionId, requestParameters.mkpProductionId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {AnalysisApiGetSecondaryEstimatesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisApi
     */
    AnalysisApi.prototype.getSecondaryEstimates = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return exports.AnalysisApiFp(this.configuration).getSecondaryEstimates(requestParameters.powerPlantId, requestParameters.deliveryDate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return AnalysisApi;
}(base_1.BaseAPI));
exports.AnalysisApi = AnalysisApi;
