import { get } from 'lodash'
import { schemas } from 'config/openapi'
import { useMemo } from 'react'
import { exclusiveMaximum, exclusiveMinimum } from 'utils/validation'

const useSchema = (modelClass, defaultSchema = schemas) => {
  const schema = useMemo(() => {
    if (!defaultSchema[modelClass]) {
      console.error(`There is no <${modelClass}> schema. Check swagger or generate a new api.`)
    }
    return defaultSchema[modelClass]
  }, [defaultSchema, modelClass])

  const getProp = (path) => {
    path = `${path}`.includes('.') ? path.split('.').join('.properties.') : path
    return get(schema.properties, path, {})
  }

  const getConstraints = (path) => {
    const prop = getProp(path)
    const pattern = prop.pattern
    const required = schema.required && schema.required.includes(path)
    const min = prop.minLength || prop.minimum
    const max = prop.maxLength || (prop.maximum && Math.min(prop.maximum, Number.MAX_SAFE_INTEGER))

    const constraints = {
      ...(required && { required }),
      ...(pattern && { pattern })
    }

    if (['byte', 'date-time'].includes(prop.format)) {
      constraints.type = 'object'
    } else {
      constraints.type = prop.type
    }

    if (min && max && min === max) {
      constraints.len = max
    } else {
      if (!isNaN(min)) {
        constraints.min = min
      }

      if (!isNaN(max)) {
        constraints.max = max
      }
    }

    const rules = [constraints]

    if (prop.exclusiveMinimum) {
      rules.push(exclusiveMinimum(min))
    }

    if (prop.exclusiveMaximum) {
      rules.push(exclusiveMaximum(max))
    }

    return rules
  }

  return {
    schema,
    getConstraints
  }
}

export default useSchema
