import { errorHandler, successHandler } from 'utils/response'

export default () => next => action => {
  const error = action?.payload?.error
  if (error) {
    errorHandler(error)
  }

  const custom = action?.payload?.custom
  if (!custom) {
    const successActions = [
      { action: 'create', isSuccess: /\bcreate.*Success/g.test(action.type) },
      { action: 'edit', isSuccess: /\bedit.*Success/g.test(action.type) },
      { action: 'delete', isSuccess: /\bdelete.*Success/g.test(action.type) },
      { action: 'inactivate', isSuccess: /\binactivate.*Success/g.test(action.type) },
      { action: 'activate', isSuccess: /\bactivate.*Success/g.test(action.type) },
      { action: 'submit', isSuccess: /\bsubmit.*Success/g.test(action.type) },
      { action: 'flag', isSuccess: /\bflag.*Success/g.test(action.type) },
      { action: 'upload', isSuccess: /\bupload.*Success/g.test(action.type) },
      { action: 'apply', isSuccess: /\bapply.*Success/g.test(action.type) }
    ]

    const foundSuccess = successActions.find(({ isSuccess }) => isSuccess)
    if (foundSuccess) {
      successHandler(foundSuccess)
    }
  }

  next(action)
}
