
import React from 'react'
import PropTypes from 'prop-types'

const PrivateLayout = ({ children }) => (
  <div className="page">
    {children}
  </div>
)

PrivateLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default PrivateLayout
