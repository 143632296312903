import { createSlice } from '@reduxjs/toolkit'
import { scheduleLogApi } from 'services/api'

const initialState = {
  scheduleMessageCategoryList: {},
  isListLoading: false,
  error: null
}

const scheduleMessageCategorySlice = createSlice({
  name: 'schedulemessageCategory',
  initialState,
  reducers: {
    getScheduleMessageCategoryListStart (state) {
      state.error = null
      state.isListLoading = true
    },
    getScheduleMessageCategoryListSuccess (state, { payload }) {
      state.scheduleMessageCategoryList = payload
      state.error = null
      state.isListLoading = false
    },
    getScheduleMessageCategoryListFailed (state, { payload }) {
      state.scheduleMessageCategoryList = {}
      state.error = payload.error
      state.isListLoading = false
    },
    downloadACKResponseXMLSuccess (state) {
      state.error = null
    },
    downloadACKResponseXMLFailed (state, { payload }) {
      state.error = payload
    },
    downloadCNFResponseXMLSuccess (state) {
      state.error = null
    },
    downloadCNFResponseXMLFailed (state, { payload }) {
      state.error = payload
    },
    downloadGroupACKResponseXMLSuccess (state) {
      state.error = null
    },
    downloadGroupACKResponseXMLFailed (state, { payload }) {
      state.error = payload
    },
    downloadGroupCNFResponseXMLSuccess (state) {
      state.error = null
    },
    downloadGroupCNFResponseXMLFailed (state, { payload }) {
      state.error = payload
    },
    clear (state) {
      state.scheduleMessageCategoryList = {}
    }
  }
})

const {
  getScheduleMessageCategoryListStart,
  getScheduleMessageCategoryListSuccess,
  getScheduleMessageCategoryListFailed,
  downloadACKResponseXMLSuccess,
  downloadACKResponseXMLFailed,
  downloadCNFResponseXMLSuccess,
  downloadCNFResponseXMLFailed,
  downloadGroupACKResponseXMLSuccess,
  downloadGroupACKResponseXMLFailed,
  downloadGroupCNFResponseXMLSuccess,
  downloadGroupCNFResponseXMLFailed,
  clear
} = scheduleMessageCategorySlice.actions

const getScheduleMessageCategoryList = (params) => async dispatch => {
  try {
    dispatch(getScheduleMessageCategoryListStart())
    const scheduleMessageList = await scheduleLogApi.getPaginatedScheduleLogsCategory(params)
    dispatch(getScheduleMessageCategoryListSuccess(scheduleMessageList))
  } catch (error) {
    dispatch(getScheduleMessageCategoryListFailed({ error }))
  }
}

const downloadACKResponseXML = (id) => async dispatch => {
  try {
    const response = await scheduleLogApi.ackResponseXml({ id }, { responseType: 'blob' })
    dispatch(downloadACKResponseXMLSuccess())
    return response
  } catch (error) {
    dispatch(downloadACKResponseXMLFailed({ error }))
    return null
  }
}

const downloadCNFResponseXML = (id) => async dispatch => {
  try {
    const response = await scheduleLogApi.cnfResponseXml({ id }, { responseType: 'blob' })
    dispatch(downloadCNFResponseXMLSuccess())
    return response
  } catch (error) {
    dispatch(downloadCNFResponseXMLFailed({ error }))
    return null
  }
}

const downloadGroupACKResponseXML = (id) => async dispatch => {
  try {
    const response = await scheduleLogApi.groupAckResponseXml({ id }, { responseType: 'blob' })
    dispatch(downloadGroupACKResponseXMLSuccess())
    return response
  } catch (error) {
    dispatch(downloadGroupACKResponseXMLFailed({ error }))
    return null
  }
}

const downloadGroupCNFResponseXML = (id) => async dispatch => {
  try {
    const response = await scheduleLogApi.groupCnfResponseXml({ id }, { responseType: 'blob' })
    dispatch(downloadGroupCNFResponseXMLSuccess())
    return response
  } catch (error) {
    dispatch(downloadGroupCNFResponseXMLFailed({ error }))
    return null
  }
}

export {
  getScheduleMessageCategoryList,
  downloadACKResponseXML,
  downloadCNFResponseXML,
  downloadGroupACKResponseXML,
  downloadGroupCNFResponseXML,
  clear
}

export default scheduleMessageCategorySlice.reducer
