import React from 'react'
import PropTypes from 'prop-types'
import { Spin } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSun } from '@fortawesome/free-solid-svg-icons'

const SpinnerWidget = ({ children, ...props }) => {
  const indicator = <FontAwesomeIcon icon={faSun} spin style={{ fontSize: 30 }} />

  return (
    <Spin size="large" indicator={indicator} {...props}>
      {children}
    </Spin>
  )
}

export default SpinnerWidget

SpinnerWidget.propTypes = {
  children: PropTypes.node
}
