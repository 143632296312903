import { createSlice } from '@reduxjs/toolkit'
import { translateCodeList } from 'utils/translate'
import {
  balanceResponsibleApi,
  companyProfileApi,
  estimateApi,
  groupApi,
  inverterApi,
  powerPlantApi,
  powerPlantGroupGlobalApi,
  solarPanelApi,
  userApi
} from 'services/api'

const initialState = {
  powerPlantList: [],
  positionList: [],
  inverterList: [],
  solarPanelList: [],
  balanceResponsibleList: [],
  roleCodeList: [],
  jobTitleList: [],
  powerPlantGroupList: [],
  powerPlantGroupGlobalList: [],
  companyProfileList: [],
  forecastProviderList: [],
  isForecastProviderListLoading: false,
  isCompanyProfileListLoading: false,
  error: null
}

const entitySlice = createSlice({
  name: 'entity',
  initialState,
  reducers: {
    getPowerPlantListSuccess (state, { payload }) {
      state.powerPlantList = payload
      state.error = null
    },
    getPowerPlantListFailed (state, { payload }) {
      state.powerPlantList = []
      state.error = payload.error
    },
    getPositionListSuccess (state, { payload }) {
      state.positionList = payload
      state.error = null
    },
    getPositionListFailed (state, { payload }) {
      state.positionList = []
      state.error = payload.error
    },
    getSolarPanelListSuccess (state, { payload }) {
      state.solarPanelList = payload
      state.error = null
    },
    getSolarPanelListFailed (state, { payload }) {
      state.solarPanelList = []
      state.error = payload.error
    },
    getInverterListSuccess (state, { payload }) {
      state.inverterList = payload
      state.error = null
    },
    getInverterListFailed (state, { payload }) {
      state.inverterList = []
      state.error = payload.error
    },
    getBalanceResponsibleListSuccess (state, { payload }) {
      state.balanceResponsibleList = payload
      state.error = null
    },
    getBalanceResponsibleListFailed (state, { payload }) {
      state.balanceResponsibleList = []
      state.error = payload.error
    },
    getRoleCodeListSuccess (state, { payload }) {
      state.roleCodeList = payload
      state.error = null
    },
    getRoleCodeListFailed (state, { payload }) {
      state.roleCodeList = []
      state.error = payload.error
    },
    getJobTitleListSuccess (state, { payload }) {
      state.jobTitleList = payload
      state.error = null
    },
    getJobTitleListFailed (state, { payload }) {
      state.jobTitleList = []
      state.error = payload.error
    },
    getPowerPlantGroupListSuccess (state, { payload }) {
      state.powerPlantGroupList = payload
      state.error = null
    },
    getPowerPlantGroupListFailed (state, { payload }) {
      state.powerPlantGroupList = []
      state.error = payload.error
    },
    getPowerPlantGroupGlobalListSuccess (state, { payload }) {
      state.powerPlantGroupGlobalList = payload
      state.error = null
    },
    getPowerPlantGroupGlobalListFailed (state, { payload }) {
      state.powerPlantGroupGlobalList = []
      state.error = payload.error
    },
    getCompanyProfileListStarted (state) {
      state.isCompanyProfileListLoading = true
    },
    getCompanyProfileListSuccess (state, { payload }) {
      state.error = null
      state.companyProfileList = payload
      state.isCompanyProfileListLoading = false
    },
    getCompanyProfileListFailed (state, { payload }) {
      state.error = payload.error
      state.isCompanyProfileListLoading = false
    },
    getForecastProviderListStarted (state) {
      state.isForecastProviderListLoading = true
    },
    getForecastProviderListSuccess (state, { payload }) {
      state.error = null
      state.forecastProviderList = payload
      state.isForecastProviderListLoading = false
    },
    getForecastProviderListFailed (state, { payload }) {
      state.error = payload.error
      state.isForecastProviderListLoading = false
    },
    clearProfileList (state) {
      state.companyProfileList = []
    }
  }
})

const {
  getPowerPlantListSuccess,
  getPowerPlantListFailed,
  getPositionListSuccess,
  getPositionListFailed,
  getSolarPanelListSuccess,
  getSolarPanelListFailed,
  getInverterListSuccess,
  getInverterListFailed,
  getBalanceResponsibleListSuccess,
  getBalanceResponsibleListFailed,
  getRoleCodeListSuccess,
  getRoleCodeListFailed,
  getJobTitleListSuccess,
  getJobTitleListFailed,
  getPowerPlantGroupListSuccess,
  getPowerPlantGroupListFailed,
  getPowerPlantGroupGlobalListSuccess,
  getPowerPlantGroupGlobalListFailed,
  getCompanyProfileListStarted,
  getCompanyProfileListSuccess,
  getCompanyProfileListFailed,
  getForecastProviderListStarted,
  getForecastProviderListSuccess,
  getForecastProviderListFailed,
  clearProfileList
} = entitySlice.actions

const getPowerPlantList = () => async dispatch => {
  try {
    const powerPlantList = await powerPlantApi.getAllActivePowerPlants()
    const reducedPowerPlantList = powerPlantList.map(({ id, name }) => ({
      id,
      name
    }))
    dispatch(getPowerPlantListSuccess(reducedPowerPlantList))
  } catch (error) {
    dispatch(getPowerPlantListFailed({ error }))
  }
}

const getPowerPlantListForAdmin = () => async dispatch => {
  try {
    const powerPlantList = await powerPlantApi.getAllActivePowerPlantsForAdmin()
    const reducedPowerPlantList = powerPlantList.map(({ id, name }) => ({
      id,
      name
    }))
    dispatch(getPowerPlantListSuccess(reducedPowerPlantList))
  } catch (error) {
    dispatch(getPowerPlantListFailed({ error }))
  }
}

const getPositionList = () => async dispatch => {
  try {
    const positionList = await powerPlantApi.getPowerPlantConfigurationPositionInfo()
    dispatch(getPositionListSuccess(translateCodeList(positionList, 'positionCode')))
  } catch (error) {
    dispatch(getPositionListFailed({ error }))
  }
}

const getSolarPanelList = (elementsGroupId) => async dispatch => {
  try {
    const solarPanelList = await solarPanelApi.getAllSelectableSolarPanel({ elementsGroupId })
    const reducedSolarPanelList = solarPanelList.map(({ id, name, pmax }) => ({
      id,
      name,
      pmax
    }))
    dispatch(getSolarPanelListSuccess(reducedSolarPanelList))
  } catch (error) {
    dispatch(getSolarPanelListFailed({ error }))
  }
}

const getInverterList = (elementsGroupId) => async dispatch => {
  try {
    const inverterList = await inverterApi.getAllSelectableInverter({ elementsGroupId })
    const reducedInverterLit = inverterList.map(({ id, name, pac }) => ({
      id,
      name,
      pac
    }))
    dispatch(getInverterListSuccess(reducedInverterLit))
  } catch (error) {
    dispatch(getInverterListFailed({ error }))
  }
}

const getBalanceResponsibleList = () => async dispatch => {
  try {
    const balanceResponsibleList = await balanceResponsibleApi.getAllBalanceResponsibleParty()
    const reducedBalanceResponsibleList = balanceResponsibleList.map(({ id, name }) => ({ id, name }))
    dispatch(getBalanceResponsibleListSuccess(reducedBalanceResponsibleList))
  } catch (error) {
    dispatch(getBalanceResponsibleListFailed({ error }))
  }
}

const getRoleCodeList = (profileId) => async dispatch => {
  try {
    const roleCodeList = await userApi.getUserRoleInfo({ profileId })
    dispatch(getRoleCodeListSuccess(translateCodeList(roleCodeList, 'userRoleCode')))
  } catch (error) {
    dispatch(getRoleCodeListFailed({ error }))
  }
}

const getJobTitleList = (profileId) => async dispatch => {
  try {
    const jobTitleList = await userApi.getJobTitleInfo({ profileId })
    dispatch(getJobTitleListSuccess(translateCodeList(jobTitleList, 'jobTitleCode')))
  } catch (error) {
    dispatch(getJobTitleListFailed({ error }))
  }
}

const getPowerPlantGroupList = () => async dispatch => {
  try {
    const powerPlantGroupList = await groupApi.getAllPowerPlantGroupInfo()
    dispatch(getPowerPlantGroupListSuccess(powerPlantGroupList))
  } catch (error) {
    dispatch(getPowerPlantGroupListFailed({ error }))
  }
}

const getPowerPlantGroupGlobalList = () => async dispatch => {
  try {
    const powerPlantGroupGlobalList = await powerPlantGroupGlobalApi.getAllPowerPlantGroupGlobalInfo()
    dispatch(getPowerPlantGroupGlobalListSuccess(powerPlantGroupGlobalList))
  } catch (error) {
    dispatch(getPowerPlantGroupGlobalListFailed({ error }))
  }
}

const getCompanyProfileList = () => async dispatch => {
  try {
    dispatch(getCompanyProfileListStarted())
    const companyProfileList = await companyProfileApi.getAllCustomerProfile()
    dispatch(getCompanyProfileListSuccess(companyProfileList))
  } catch (error) {
    dispatch(getCompanyProfileListFailed({ error }))
  }
}

const getForecastProviderList = () => async dispatch => {
  try {
    dispatch(getForecastProviderListStarted())
    const forecastProviderList = await estimateApi.getForecastSources()
    dispatch(getForecastProviderListSuccess(translateCodeList(forecastProviderList, 'forecastSource')))
  } catch (error) {
    dispatch(getForecastProviderListFailed({ error }))
  }
}

export {
  getPowerPlantList,
  getPowerPlantListForAdmin,
  getPositionList,
  getSolarPanelList,
  getInverterList,
  getBalanceResponsibleList,
  getRoleCodeList,
  getJobTitleList,
  getPowerPlantGroupList,
  getPowerPlantGroupGlobalList,
  getCompanyProfileList,
  getForecastProviderList,
  clearProfileList
}

export default entitySlice.reducer
