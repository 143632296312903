import { createSlice } from '@reduxjs/toolkit'
import { MekhCategory, MekhStatus, ModalStatus } from 'models/enum'
import { mekhDataApi } from 'services/api'
import { set as _set } from 'lodash'
import { findParentRowById, findRowById } from './mekhdatav310.utils'

const initialState = {
  mekhDataV310List: {},
  isMekhDataV310ListLoading: false,
  isEntityLoading: false,
  isV310Enabled: false,
  createV310DataStatus: null,
  mekhDataV310: {},
  mekhDataV310ModalStatus: ModalStatus.Hidden,
  error: null
}

const mekhDataV310 = createSlice({
  name: 'mekhdatav310',
  initialState,
  reducers: {
    getMekhDataV310ListStarted (state) {
      state.isMekhDataV310ListLoading = true
    },
    getMekhDataV310ListSuccess (state, { payload }) {
      state.mekhDataV310List = payload
      state.isMekhDataV310ListLoading = false
      state.error = null
    },
    getMekhDataV310ListFailed (state, { payload }) {
      state.mekhDataV310List = {}
      state.isMekhDataV310ListLoading = false
      state.error = payload.error
    },
    getMekhDataV310Success (state, { payload }) {
      state.mekhDataV310 = payload
      state.error = null
      state.isEntityLoading = false
    },
    getMekhDataV310Failed (state, { payload }) {
      state.mekhDataV310 = {}
      state.error = payload.error
      state.isEntityLoading = false
    },
    downloadMekhV310FileSuccess (state) {
      state.error = null
    },
    downloadMekhV310FileFailed (state, { payload }) {
      state.error = payload.error
    },
    createMekhDataV310Success (state) {
      state.mekhDataV310 = {}
      state.error = null
      state.mekhDataV310ModalStatus = ModalStatus.Hidden
      state.isEntityLoading = false
    },
    createMekhDataV310Failed (state, { payload }) {
      state.error = payload.error
      state.isEntityLoading = false
    },
    editMekhDataV310Success (state) {
      state.mekhDataV310 = {}
      state.error = {}
      state.mekhDataV310ModalStatus = ModalStatus.Hidden
      state.isEntityLoading = false
    },
    editMekhDataV310Failed (state, { payload }) {
      state.error = payload.error
      state.isEntityLoading = false
    },
    modifyMekhDataV310Cell (state, { payload }) {
      const { dto, modifiedValue } = payload
      const { key, value } = modifiedValue
      const foundRow = findRowById(state.mekhDataV310.dataSections, dto.technicalId)

      _set(foundRow, key, value)
    },
    modifyMekhDataV310ChildCell (state, { payload }) {
      const { dto, modifiedValue } = payload
      const { key, value } = modifiedValue
      const foundRow = findRowById(state.mekhDataV310.dataSections, dto.technicalId)

      _set(foundRow, key, value)
    },
    addMekhDataV310Row (state, { payload }) {
      const { record } = payload
      const foundRow = findRowById(state.mekhDataV310.dataSections, record.technicalId)
      const { childRowTemplate, children } = foundRow
      const ids = children.map(({ technicalId }) => technicalId)
      const largest = Math.max(Math.max(...ids), childRowTemplate.technicalId)

      _set(foundRow, 'children', [...children, {
        ...childRowTemplate,
        technicalId: largest + 1,
        isChildRow: true
      }])
    },
    removeMekhDataV310Row (state, { payload }) {
      const { record: { technicalId } } = payload
      const foundParentRow = findParentRowById(state.mekhDataV310.dataSections, technicalId)
      foundParentRow.children = foundParentRow.children.filter(children => children.technicalId !== technicalId)
    },
    setMekhDataV310Loading (state) {
      state.isEntityLoading = true
    },
    setMekhDataV310ModalStatus (state, { payload }) {
      state.mekhDataV310ModalStatus = payload
      if (payload === ModalStatus.Hidden) {
        state.mekhDataV310 = {}
      }
    },
    setIsV310Enabled (state, { payload }) {
      state.isV310Enabled = payload
    },
    setIsV310EnabledFailed (state, { payload }) {
      state.isV310Enabled = false
      state.error = payload.error
    },
    setCreateV310DataStatusSuccess (state, { payload }) {
      state.createV310DataStatus = payload
      state.error = null
    },
    setCreateV310DataStatusFailed (state, { payload }) {
      state.createV310DataStatus = payload
      state.error = payload.error
    },
    clear (state) {
      state.mekhDataV310List = {}
      state.mekhDataV310ModalStatus = ModalStatus.Hidden
      state.error = null
    }
  }
})

const {
  getMekhDataV310ListStarted,
  getMekhDataV310ListSuccess,
  getMekhDataV310ListFailed,
  setMekhDataV310Loading,
  getMekhDataV310Success,
  getMekhDataV310Failed,
  createMekhDataV310Success,
  createMekhDataV310Failed,
  editMekhDataV310Success,
  editMekhDataV310Failed,
  addMekhDataV310Row,
  removeMekhDataV310Row,
  modifyMekhDataV310Cell,
  modifyMekhDataV310ChildCell,
  downloadMekhV310FileSuccess,
  downloadMekhV310FileFailed,
  setMekhDataV310ModalStatus,
  setIsV310Enabled,
  setIsV310EnabledFailed,
  setCreateV310DataStatusSuccess,
  setCreateV310DataStatusFailed,
  clear
} = mekhDataV310.actions

const getMekhDataV310List = (params) => async dispatch => {
  try {
    dispatch(getMekhDataV310ListStarted())
    const mekhDataV310List = await mekhDataApi.getPaginatedMekhV310Data(params)
    dispatch(getMekhDataV310ListSuccess(mekhDataV310List))
  } catch (error) {
    dispatch(getMekhDataV310ListFailed({ error }))
  }
}

const getMekhDataV310 = (id) => async dispatch => {
  try {
    dispatch(setMekhDataV310Loading())
    const mekhDataV310 = await mekhDataApi.getMekhV310Data({ id })
    dispatch(getMekhDataV310Success(mekhDataV310))
  } catch (error) {
    dispatch(getMekhDataV310Failed({ error }))
  }
}
const getLatestMekhDataV310 = (params) => async dispatch => {
  try {
    dispatch(setMekhDataV310Loading())
    const mekhDataV310 = await mekhDataApi.getLatestMekhV310Data(params)
    dispatch(getMekhDataV310Success(mekhDataV310))
  } catch (error) {
    dispatch(getMekhDataV310Failed({ error }))
  }
}

const createMekhDataV310 = (createMekhV310Dto, params) => async dispatch => {
  try {
    dispatch(setMekhDataV310Loading())
    await mekhDataApi.createMekhV310Data({ createMekhV310Dto })
    dispatch(createMekhDataV310Success())
    dispatch(getMekhDataV310List(params))
    dispatch(getCreateV310DataStatus(params))
  } catch (error) {
    dispatch(createMekhDataV310Failed({ error }))
  }
}

const editMekhDataV310 = (updateMekhV310Dto, params) => async dispatch => {
  try {
    dispatch(setMekhDataV310Loading())
    await mekhDataApi.updateMekhV310Data({ updateMekhV310Dto })
    dispatch(editMekhDataV310Success())
    dispatch(getMekhDataV310List(params))
  } catch (error) {
    dispatch(editMekhDataV310Failed({ error }))
  }
}

const downloadMekhV310File = (id, params) => async dispatch => {
  try {
    const mekhV310File = await mekhDataApi.downloadMekhV310({ id }, { responseType: 'blob' })
    dispatch(downloadMekhV310FileSuccess())
    dispatch(getMekhDataV310List(params))
    return mekhV310File
  } catch (error) {
    dispatch(downloadMekhV310FileFailed({ error }))
    return null
  }
}
const getIsV310Enabled = (params) => async dispatch => {
  try {
    const payload = { ...params, category: MekhCategory.V310 }
    const { createMekhDataStatus } = await mekhDataApi.isCreateMekhDataEnabled(payload)
    const enabled = createMekhDataStatus === MekhStatus.EnabledV310Tab
    dispatch(setIsV310Enabled(enabled))
  } catch (error) {
    dispatch(setIsV310EnabledFailed({ error }))
    return null
  }
}

const getCreateV310DataStatus = (params) => async dispatch => {
  try {
    const payload = { ...params, category: MekhCategory.V310 }
    const { createMekhDataStatus } = await mekhDataApi.isCreateMekhDataEnabled(payload)
    dispatch(setCreateV310DataStatusSuccess(createMekhDataStatus))
  } catch (error) {
    dispatch(setCreateV310DataStatusFailed({ error }))
    return null
  }
}

export {
  getMekhDataV310List,
  getMekhDataV310,
  getLatestMekhDataV310,
  createMekhDataV310,
  editMekhDataV310,
  downloadMekhV310File,
  addMekhDataV310Row,
  removeMekhDataV310Row,
  modifyMekhDataV310Cell,
  modifyMekhDataV310ChildCell,
  setMekhDataV310ModalStatus,
  getIsV310Enabled,
  getCreateV310DataStatus,
  clear
}

export default mekhDataV310.reducer
