import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import responseHandlerMiddleware from './general/responsehandler.middleware'
import language from './general/language.slice'
import auth from './auth/auth.slice'
import solarpanel from './masterdata/solarpanel.slice'
import inverter from './masterdata/inverter.slice'
import powerplant from './powerplant/powerplant.slice'
import suncollector from './powerplant/suncollector.slice'
import intervalconfig from './powerplant/intervalconfig.slice'
import entity from './general/entity.slice'
import efficiencymatrix from './masterdata/efficiencymatrix.slice'
import overview from './overview/overview.slice'
import groupresponsible from './masterdata/groupresponsible.slice'
import balanceresponsible from './masterdata/balanceresponsible.slice'
import powerplantgroup from './masterdata/powerplantgroup.slice'
import powerplantgroupglobal from './masterdata/powerplantgroupglobal.slice'
import reportage from './reportage/reportage.slice'
import estimatetest from './estimate/estimatetest.slice'
import estimate from './estimate/estimate.slice'
import estimateGrouped from './estimate/estimateGrouped.slice'
import estimateerrors from './estimate/estimateerrors.slice'
import batchestimate from './estimate/batchEstimate.slice'
import profile from './profile/profile.slice'
import companyprofile from './profile/companyprofile.slice'
import user from './profile/user.slice'
import schedule from './schedule/schedule.slice'
import schedulegroup from './schedule/schedule-group.slice'
import finalizedschedulemanagement from './schedule/finalized-schedule-management.slice'
import schedulemanagement from './schedule/schedule-management.slice'
import schedulegroupmanagement from './schedule/schedule-group-management.slice'
import schedulemessage from './schedule/schedule-message.slice'
import schedulereportage from './schedule/schedule-reportage.slice'
import schedulemessageCategory from './schedule/schedule-message-category.slice'
import estimateoption from './settings/estimateoption.slice'
import log from './log/log.slice'
import systemlog from './log/systemlog.slice'
import dataimport from './dataimport/dataimport.slice'
import operatorimport from './dataimport/operatorimport.slice'
import analyzes from './analyzes/analyzes.slice'
import annualestimate from './reportage/annualestimate.slice'
import productiondiff from './reportage/productiondiff.slice'
import productiondata from './reportage/productiondata.slice'
import activityList from './reportage/activityList.slice'
import providersettings from './prognosis/providersettings.slice'
import mekhdatav300 from './mekh/mekhdatav300.slice'
import mekhdatav310 from './mekh/mekhdatav310.slice'
import neurallearning from './neurallearning/neurallearning.slice'
import invertermonitoring from './monitoring/invertermonitoring.slice'
import forecastsyncjob from './forecastsyncjob/forecastsyncjob.slice'
import prognosisValidations from './prognosis/prognosisvalidations.slice'

const store = configureStore({
  reducer: combineReducers({
    activityList,
    analyzes,
    annualestimate,
    auth,
    balanceresponsible,
    batchestimate,
    companyprofile,
    dataimport,
    efficiencymatrix,
    entity,
    estimate,
    estimateGrouped,
    estimateoption,
    estimatetest,
    estimateerrors,
    finalizedschedulemanagement,
    forecastsyncjob,
    groupresponsible,
    intervalconfig,
    inverter,
    invertermonitoring,
    language,
    log,
    mekhdatav300,
    mekhdatav310,
    neurallearning,
    operatorimport,
    overview,
    powerplant,
    powerplantgroup,
    powerplantgroupglobal,
    productiondata,
    productiondiff,
    profile,
    providersettings,
    prognosisValidations,
    reportage,
    schedule,
    schedulegroup,
    schedulegroupmanagement,
    schedulemanagement,
    schedulemessage,
    schedulemessageCategory,
    schedulereportage,
    solarpanel,
    suncollector,
    systemlog,
    user
  }),
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false
    }),
    responseHandlerMiddleware
  ],
  devTools: process.env.NODE_ENV !== 'production'
})

export default store
