import React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Button, Form, Input } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faUser } from '@fortawesome/free-solid-svg-icons'
import useLogin from './useLogin'
import logo from 'assets/img/logo-yellow.svg'
import styles from './Login.module.scss'
import SchemaContext from 'contexts/SchemaContext'
import { useSchema } from 'hooks'
import ModalFormWidget from 'components/widgets/ModalFormWidget'
import FormWidget from 'components/widgets/Form/FormWidget'
import FormItemWidget from 'components/widgets/FormItem/FormItemWidget'

const LoginComponent = () => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const schema = useSchema('LoginRequestDto')
  const {
    error,
    isLoading,
    isLoginModalVisible,
    handleOnFinish,
    handleOnFinishFailed,
    handleHideLoginModal
  } = useLogin()

  const cardTitle = <img className={styles.logo} src={logo} alt={t('general.title')} />
  const alertMessage = error && (
    <Alert
      className={styles.alert}
      message={t('login.errors.auth-failed')}
      type="error"
    />
  )

  return (
    <SchemaContext.Provider value={{ schema, formNamespace: 'login' }}>
      <ModalFormWidget
        closable
        forceRender
        form={form}
        width="35rem"
        className={styles.login}
        visible={isLoginModalVisible}
        onCancel={handleHideLoginModal}
        maskStyle={{ backgroundColor: 'rgba(0, 132, 127, 0.9)' }}
        hideTitle
        footer={null}
      >
        <div className={styles.card}>
          <div className={styles.formWrapper}>
            <h1 className={styles.title}>{t('login.title')}</h1>
            {alertMessage}
            <FormWidget
              form={form}
              layout="vertical"
              onFinish={handleOnFinish}
              onFinishFailed={handleOnFinishFailed}
            >
              <FormItemWidget className={styles.username} name="username" label={null}>
                <Input
                  autoComplete="username"
                  prefix={<FontAwesomeIcon icon={faUser} />}
                  placeholder={t('login.entity.username')}
                />
              </FormItemWidget>
              <FormItemWidget className={styles.password} name="password" label={null}>
                <Input
                  autoComplete="current-password"
                  prefix={<FontAwesomeIcon icon={faLock} />}
                  type="password"
                  placeholder={t('login.entity.password')}
                />
              </FormItemWidget>
              <Button
                ghost
                type="primary"
                size="large"
                htmlType="submit"
                loading={isLoading}
                className={styles.submit}
              >
                {t('login.submit')}
              </Button>
            </FormWidget>
          </div>
          <div className={styles.loginBottom}>
            {cardTitle}
          </div>
        </div>

      </ModalFormWidget>
    </SchemaContext.Provider>
  )
}

export default LoginComponent
