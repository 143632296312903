"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MekhDataApi = exports.MekhDataApiFactory = exports.MekhDataApiFp = exports.MekhDataApiAxiosParamCreator = void 0;
var globalImportUrl = require("url");
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var base_1 = require("../base");
/**
 * MekhDataApi - axios parameter creator
 * @export
 */
exports.MekhDataApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {CreateMekhV300Dto} [createMekhV300Dto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMekhV300Data: function (createMekhV300Dto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions, needsSerialization;
                return __generator(this, function (_a) {
                    localVarPath = "/api/MekhData/CreateV300";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    needsSerialization = (typeof createMekhV300Dto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
                    localVarRequestOptions.data = needsSerialization ? JSON.stringify(createMekhV300Dto !== undefined ? createMekhV300Dto : {}) : (createMekhV300Dto || "");
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {CreateMekhV310Dto} [createMekhV310Dto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMekhV310Data: function (createMekhV310Dto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions, needsSerialization;
                return __generator(this, function (_a) {
                    localVarPath = "/api/MekhData/CreateV310";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    needsSerialization = (typeof createMekhV310Dto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
                    localVarRequestOptions.data = needsSerialization ? JSON.stringify(createMekhV310Dto !== undefined ? createMekhV310Dto : {}) : (createMekhV310Dto || "");
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMekhV300: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/api/MekhData/Download/MekhV300";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    if (id !== undefined) {
                        localVarQueryParameter['id'] = id;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMekhV310: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/api/MekhData/Download/MekhV310";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    if (id !== undefined) {
                        localVarQueryParameter['id'] = id;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} [powerPlantId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestMekhV300Data: function (powerPlantId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/api/MekhData/GetLatestMekhV300Data";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    if (powerPlantId !== undefined) {
                        localVarQueryParameter['powerPlantId'] = powerPlantId;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} [powerPlantId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestMekhV310Data: function (powerPlantId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/api/MekhData/GetLatestMekhV310Data";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    if (powerPlantId !== undefined) {
                        localVarQueryParameter['powerPlantId'] = powerPlantId;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMekhV300Data: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/api/MekhData/GetMekhV300Data";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    if (id !== undefined) {
                        localVarQueryParameter['id'] = id;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMekhV310Data: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/api/MekhData/GetMekhV310Data";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    if (id !== undefined) {
                        localVarQueryParameter['id'] = id;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} [powerPlantId]
         * @param {string} [targetDateMonth]
         * @param {ListSortDirection} [orderByDirection]
         * @param {string} [orderByFieldName]
         * @param {number} [paginationPageNumber]
         * @param {number} [paginationPageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedMekhV300Data: function (powerPlantId, targetDateMonth, orderByDirection, orderByFieldName, paginationPageNumber, paginationPageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/api/MekhData/PaginatedMekhV300";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    if (powerPlantId !== undefined) {
                        localVarQueryParameter['PowerPlantId'] = powerPlantId;
                    }
                    if (targetDateMonth !== undefined) {
                        localVarQueryParameter['TargetDateMonth'] = (targetDateMonth instanceof Date) ?
                            targetDateMonth.toISOString() :
                            targetDateMonth;
                    }
                    if (orderByDirection !== undefined) {
                        localVarQueryParameter['OrderBy.Direction'] = orderByDirection;
                    }
                    if (orderByFieldName !== undefined) {
                        localVarQueryParameter['OrderBy.FieldName'] = orderByFieldName;
                    }
                    if (paginationPageNumber !== undefined) {
                        localVarQueryParameter['Pagination.PageNumber'] = paginationPageNumber;
                    }
                    if (paginationPageSize !== undefined) {
                        localVarQueryParameter['Pagination.PageSize'] = paginationPageSize;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} [powerPlantId]
         * @param {string} [targetDateMonth]
         * @param {ListSortDirection} [orderByDirection]
         * @param {string} [orderByFieldName]
         * @param {number} [paginationPageNumber]
         * @param {number} [paginationPageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedMekhV310Data: function (powerPlantId, targetDateMonth, orderByDirection, orderByFieldName, paginationPageNumber, paginationPageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/api/MekhData/PaginatedMekhV310";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    if (powerPlantId !== undefined) {
                        localVarQueryParameter['PowerPlantId'] = powerPlantId;
                    }
                    if (targetDateMonth !== undefined) {
                        localVarQueryParameter['TargetDateMonth'] = (targetDateMonth instanceof Date) ?
                            targetDateMonth.toISOString() :
                            targetDateMonth;
                    }
                    if (orderByDirection !== undefined) {
                        localVarQueryParameter['OrderBy.Direction'] = orderByDirection;
                    }
                    if (orderByFieldName !== undefined) {
                        localVarQueryParameter['OrderBy.FieldName'] = orderByFieldName;
                    }
                    if (paginationPageNumber !== undefined) {
                        localVarQueryParameter['Pagination.PageNumber'] = paginationPageNumber;
                    }
                    if (paginationPageSize !== undefined) {
                        localVarQueryParameter['Pagination.PageSize'] = paginationPageSize;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} [category]
         * @param {number} [powerPlantId]
         * @param {string} [targetDateMonth]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isCreateMekhDataEnabled: function (category, powerPlantId, targetDateMonth, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/api/MekhData/IsCreateMekhDataEnabled";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    if (category !== undefined) {
                        localVarQueryParameter['category'] = category;
                    }
                    if (powerPlantId !== undefined) {
                        localVarQueryParameter['powerPlantId'] = powerPlantId;
                    }
                    if (targetDateMonth !== undefined) {
                        localVarQueryParameter['targetDateMonth'] = (targetDateMonth instanceof Date) ?
                            targetDateMonth.toISOString() :
                            targetDateMonth;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {UpdateMekhV300Dto} [updateMekhV300Dto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMekhV300Data: function (updateMekhV300Dto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions, needsSerialization;
                return __generator(this, function (_a) {
                    localVarPath = "/api/MekhData/UpdateV300";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    needsSerialization = (typeof updateMekhV300Dto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
                    localVarRequestOptions.data = needsSerialization ? JSON.stringify(updateMekhV300Dto !== undefined ? updateMekhV300Dto : {}) : (updateMekhV300Dto || "");
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {UpdateMekhV310Dto} [updateMekhV310Dto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMekhV310Data: function (updateMekhV310Dto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions, needsSerialization;
                return __generator(this, function (_a) {
                    localVarPath = "/api/MekhData/UpdateV310";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    needsSerialization = (typeof updateMekhV310Dto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
                    localVarRequestOptions.data = needsSerialization ? JSON.stringify(updateMekhV310Dto !== undefined ? updateMekhV310Dto : {}) : (updateMekhV310Dto || "");
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * MekhDataApi - functional programming interface
 * @export
 */
exports.MekhDataApiFp = function (configuration) {
    return {
        /**
         *
         * @param {CreateMekhV300Dto} [createMekhV300Dto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMekhV300Data: function (createMekhV300Dto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.MekhDataApiAxiosParamCreator(configuration).createMekhV300Data(createMekhV300Dto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CreateMekhV310Dto} [createMekhV310Dto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMekhV310Data: function (createMekhV310Dto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.MekhDataApiAxiosParamCreator(configuration).createMekhV310Data(createMekhV310Dto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMekhV300: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.MekhDataApiAxiosParamCreator(configuration).downloadMekhV300(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMekhV310: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.MekhDataApiAxiosParamCreator(configuration).downloadMekhV310(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [powerPlantId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestMekhV300Data: function (powerPlantId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.MekhDataApiAxiosParamCreator(configuration).getLatestMekhV300Data(powerPlantId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [powerPlantId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestMekhV310Data: function (powerPlantId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.MekhDataApiAxiosParamCreator(configuration).getLatestMekhV310Data(powerPlantId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMekhV300Data: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.MekhDataApiAxiosParamCreator(configuration).getMekhV300Data(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMekhV310Data: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.MekhDataApiAxiosParamCreator(configuration).getMekhV310Data(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [powerPlantId]
         * @param {string} [targetDateMonth]
         * @param {ListSortDirection} [orderByDirection]
         * @param {string} [orderByFieldName]
         * @param {number} [paginationPageNumber]
         * @param {number} [paginationPageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedMekhV300Data: function (powerPlantId, targetDateMonth, orderByDirection, orderByFieldName, paginationPageNumber, paginationPageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.MekhDataApiAxiosParamCreator(configuration).getPaginatedMekhV300Data(powerPlantId, targetDateMonth, orderByDirection, orderByFieldName, paginationPageNumber, paginationPageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [powerPlantId]
         * @param {string} [targetDateMonth]
         * @param {ListSortDirection} [orderByDirection]
         * @param {string} [orderByFieldName]
         * @param {number} [paginationPageNumber]
         * @param {number} [paginationPageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedMekhV310Data: function (powerPlantId, targetDateMonth, orderByDirection, orderByFieldName, paginationPageNumber, paginationPageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.MekhDataApiAxiosParamCreator(configuration).getPaginatedMekhV310Data(powerPlantId, targetDateMonth, orderByDirection, orderByFieldName, paginationPageNumber, paginationPageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [category]
         * @param {number} [powerPlantId]
         * @param {string} [targetDateMonth]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isCreateMekhDataEnabled: function (category, powerPlantId, targetDateMonth, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.MekhDataApiAxiosParamCreator(configuration).isCreateMekhDataEnabled(category, powerPlantId, targetDateMonth, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {UpdateMekhV300Dto} [updateMekhV300Dto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMekhV300Data: function (updateMekhV300Dto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.MekhDataApiAxiosParamCreator(configuration).updateMekhV300Data(updateMekhV300Dto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {UpdateMekhV310Dto} [updateMekhV310Dto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMekhV310Data: function (updateMekhV310Dto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.MekhDataApiAxiosParamCreator(configuration).updateMekhV310Data(updateMekhV310Dto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
/**
 * MekhDataApi - factory interface
 * @export
 */
exports.MekhDataApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {CreateMekhV300Dto} [createMekhV300Dto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMekhV300Data: function (createMekhV300Dto, options) {
            return exports.MekhDataApiFp(configuration).createMekhV300Data(createMekhV300Dto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CreateMekhV310Dto} [createMekhV310Dto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMekhV310Data: function (createMekhV310Dto, options) {
            return exports.MekhDataApiFp(configuration).createMekhV310Data(createMekhV310Dto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMekhV300: function (id, options) {
            return exports.MekhDataApiFp(configuration).downloadMekhV300(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMekhV310: function (id, options) {
            return exports.MekhDataApiFp(configuration).downloadMekhV310(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} [powerPlantId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestMekhV300Data: function (powerPlantId, options) {
            return exports.MekhDataApiFp(configuration).getLatestMekhV300Data(powerPlantId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} [powerPlantId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestMekhV310Data: function (powerPlantId, options) {
            return exports.MekhDataApiFp(configuration).getLatestMekhV310Data(powerPlantId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMekhV300Data: function (id, options) {
            return exports.MekhDataApiFp(configuration).getMekhV300Data(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMekhV310Data: function (id, options) {
            return exports.MekhDataApiFp(configuration).getMekhV310Data(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} [powerPlantId]
         * @param {string} [targetDateMonth]
         * @param {ListSortDirection} [orderByDirection]
         * @param {string} [orderByFieldName]
         * @param {number} [paginationPageNumber]
         * @param {number} [paginationPageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedMekhV300Data: function (powerPlantId, targetDateMonth, orderByDirection, orderByFieldName, paginationPageNumber, paginationPageSize, options) {
            return exports.MekhDataApiFp(configuration).getPaginatedMekhV300Data(powerPlantId, targetDateMonth, orderByDirection, orderByFieldName, paginationPageNumber, paginationPageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} [powerPlantId]
         * @param {string} [targetDateMonth]
         * @param {ListSortDirection} [orderByDirection]
         * @param {string} [orderByFieldName]
         * @param {number} [paginationPageNumber]
         * @param {number} [paginationPageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedMekhV310Data: function (powerPlantId, targetDateMonth, orderByDirection, orderByFieldName, paginationPageNumber, paginationPageSize, options) {
            return exports.MekhDataApiFp(configuration).getPaginatedMekhV310Data(powerPlantId, targetDateMonth, orderByDirection, orderByFieldName, paginationPageNumber, paginationPageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [category]
         * @param {number} [powerPlantId]
         * @param {string} [targetDateMonth]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isCreateMekhDataEnabled: function (category, powerPlantId, targetDateMonth, options) {
            return exports.MekhDataApiFp(configuration).isCreateMekhDataEnabled(category, powerPlantId, targetDateMonth, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {UpdateMekhV300Dto} [updateMekhV300Dto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMekhV300Data: function (updateMekhV300Dto, options) {
            return exports.MekhDataApiFp(configuration).updateMekhV300Data(updateMekhV300Dto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {UpdateMekhV310Dto} [updateMekhV310Dto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMekhV310Data: function (updateMekhV310Dto, options) {
            return exports.MekhDataApiFp(configuration).updateMekhV310Data(updateMekhV310Dto, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * MekhDataApi - object-oriented interface
 * @export
 * @class MekhDataApi
 * @extends {BaseAPI}
 */
var MekhDataApi = /** @class */ (function (_super) {
    __extends(MekhDataApi, _super);
    function MekhDataApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {MekhDataApiCreateMekhV300DataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MekhDataApi
     */
    MekhDataApi.prototype.createMekhV300Data = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return exports.MekhDataApiFp(this.configuration).createMekhV300Data(requestParameters.createMekhV300Dto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {MekhDataApiCreateMekhV310DataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MekhDataApi
     */
    MekhDataApi.prototype.createMekhV310Data = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return exports.MekhDataApiFp(this.configuration).createMekhV310Data(requestParameters.createMekhV310Dto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {MekhDataApiDownloadMekhV300Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MekhDataApi
     */
    MekhDataApi.prototype.downloadMekhV300 = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return exports.MekhDataApiFp(this.configuration).downloadMekhV300(requestParameters.id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {MekhDataApiDownloadMekhV310Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MekhDataApi
     */
    MekhDataApi.prototype.downloadMekhV310 = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return exports.MekhDataApiFp(this.configuration).downloadMekhV310(requestParameters.id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {MekhDataApiGetLatestMekhV300DataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MekhDataApi
     */
    MekhDataApi.prototype.getLatestMekhV300Data = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return exports.MekhDataApiFp(this.configuration).getLatestMekhV300Data(requestParameters.powerPlantId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {MekhDataApiGetLatestMekhV310DataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MekhDataApi
     */
    MekhDataApi.prototype.getLatestMekhV310Data = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return exports.MekhDataApiFp(this.configuration).getLatestMekhV310Data(requestParameters.powerPlantId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {MekhDataApiGetMekhV300DataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MekhDataApi
     */
    MekhDataApi.prototype.getMekhV300Data = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return exports.MekhDataApiFp(this.configuration).getMekhV300Data(requestParameters.id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {MekhDataApiGetMekhV310DataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MekhDataApi
     */
    MekhDataApi.prototype.getMekhV310Data = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return exports.MekhDataApiFp(this.configuration).getMekhV310Data(requestParameters.id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {MekhDataApiGetPaginatedMekhV300DataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MekhDataApi
     */
    MekhDataApi.prototype.getPaginatedMekhV300Data = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return exports.MekhDataApiFp(this.configuration).getPaginatedMekhV300Data(requestParameters.powerPlantId, requestParameters.targetDateMonth, requestParameters.orderByDirection, requestParameters.orderByFieldName, requestParameters.paginationPageNumber, requestParameters.paginationPageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {MekhDataApiGetPaginatedMekhV310DataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MekhDataApi
     */
    MekhDataApi.prototype.getPaginatedMekhV310Data = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return exports.MekhDataApiFp(this.configuration).getPaginatedMekhV310Data(requestParameters.powerPlantId, requestParameters.targetDateMonth, requestParameters.orderByDirection, requestParameters.orderByFieldName, requestParameters.paginationPageNumber, requestParameters.paginationPageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {MekhDataApiIsCreateMekhDataEnabledRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MekhDataApi
     */
    MekhDataApi.prototype.isCreateMekhDataEnabled = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return exports.MekhDataApiFp(this.configuration).isCreateMekhDataEnabled(requestParameters.category, requestParameters.powerPlantId, requestParameters.targetDateMonth, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {MekhDataApiUpdateMekhV300DataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MekhDataApi
     */
    MekhDataApi.prototype.updateMekhV300Data = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return exports.MekhDataApiFp(this.configuration).updateMekhV300Data(requestParameters.updateMekhV300Dto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {MekhDataApiUpdateMekhV310DataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MekhDataApi
     */
    MekhDataApi.prototype.updateMekhV310Data = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return exports.MekhDataApiFp(this.configuration).updateMekhV310Data(requestParameters.updateMekhV310Dto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return MekhDataApi;
}(base_1.BaseAPI));
exports.MekhDataApi = MekhDataApi;
