import axios from 'axios'
import { logout, refreshToken } from 'store/auth/auth.slice'
import store from 'store'
import Cookies from 'js-cookie'
import { COOKIE_PREFIX } from 'utils/token'

/**
 * For multiple requests on 401 error
 */
let isRefreshing = false
let failedQueue = []

const processQueue = error => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve()
    }
  })

  failedQueue = []
}

const axiosRefreshTokenHandler = ({ error, asyncTokenRefresher, onRefreshFailed = () => {} }) => {
  const originalRequest = error.config

  if (originalRequest._retry) {
    return Promise.reject(error)
  }

  if (isRefreshing) {
    return new Promise(function (resolve, reject) {
      failedQueue.push({ resolve, reject })
    })
      .then(() => axios(originalRequest))
      .catch(err => {
        return Promise.reject(err)
      })
  }

  originalRequest._retry = true
  isRefreshing = true

  return new Promise(function (resolve, reject) {
    asyncTokenRefresher()
      .then((accessToken) => {
        processQueue(null)
        originalRequest.headers.Authorization = `Bearer ${accessToken}`
        resolve(axios(originalRequest))
      })
      .catch(err => {
        onRefreshFailed()
        processQueue(err)
        reject(err)
      })
      .then(() => {
        isRefreshing = false
      })
  })
}

const setupAxiosInterceptors = ({
  onRequestFulfilled = config => config,
  onRequestRejected = error => Promise.reject(error),
  onResponseFulfilled = response => response,
  onResponseRejected = error => Promise.reject(error)
}) => {
  // Request interceptor
  axios.interceptors.request.use(
    function (config) {
      // Before request is sent
      const token = Cookies.get(`${COOKIE_PREFIX}-token`)
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      return onRequestFulfilled(config)
    },
    function (error) {
      return onRequestRejected(error)
    }
  )

  // Response interceptor
  axios.interceptors.response.use(
    // Any status code that lie within the range of 2xx cause this function to trigger
    function (response) {
      // Get file name and prepare response for downloadjs
      const contentHeader = response?.headers['content-disposition']
      if (contentHeader) {
        const parts = contentHeader.match(/filename\*=UTF-8''(.*)/)
        const filename = (parts && parts[1]) || 'unnamed_file'
        return onResponseFulfilled({ body: response.data, filename: decodeURIComponent(filename) })
      }

      return onResponseFulfilled(response.data)
    },
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    async function (error) {
      if (error.response?.status === 401) {
        return onResponseRejected(error)
      }

      if (error.request.responseType === 'blob') {
        const e = error.response
        const text = await e.data.text()
        return onResponseRejected({
          ...JSON.parse(text),
          isBlob: true
        })
      }

      return onResponseRejected(error.response.data)
    }
  )
}

const setupAxios = () => {
  setupAxiosInterceptors({
    onResponseRejected: (error) => {
      const errorStatus = error.response && error.response.status

      if (errorStatus === 401 && !error.request.responseURL.endsWith('/Logout')) {
        return axiosRefreshTokenHandler({
          error,
          asyncTokenRefresher: () => store.dispatch(refreshToken()),
          onRefreshFailed: () => {
            store.dispatch(logout(true))
          }
        })
      }

      return Promise.reject(error)
    }
  })
}

export default setupAxios
