import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { powerPlantGroupEstimateApi, scheduleMetadataGroupApi } from 'services/api'

const initialState = {
  scheduleGroupList: {},
  deliveryDateList: [],
  isDeliveryDateListLoading: false,
  isListLoading: false,
  error: null
}

const generateXmlFile = createAsyncThunk(
  'schedulegroup/generateXmlFile',
  async (values, { rejectWithValue }) => {
    try {
      const generate = await Promise.all([
        scheduleMetadataGroupApi.generateScheduleMetadataGroup(values),
        new Promise((resolve) => setTimeout(() => resolve(), 3000)) // min 3sec
      ])
      return generate
    } catch (error) {
      return rejectWithValue({ error })
    }
  }
)

const scheduleSlice = createSlice({
  name: 'schedulegroup',
  initialState,
  reducers: {
    getScheduleGroupListStart (state) {
      state.error = null
      state.isListLoading = true
    },
    getScheduleGroupListSuccess (state, { payload }) {
      state.scheduleGroupList = payload
      state.error = null
      state.isListLoading = false
    },
    getScheduleGroupListFailed (state, { payload }) {
      state.scheduleGroupList = {}
      state.error = payload.error
      state.isListLoading = false
    },
    getDeliveryDateListStart (state) {
      state.error = null
      state.isDeliveryDateListLoading = true
    },
    getDeliveryDateListSuccess (state, { payload }) {
      state.deliveryDateList = payload
      state.error = null
      state.isDeliveryDateListLoading = false
    },
    getDeliveryDateListFailed (state, { payload }) {
      state.deliveryDateList = []
      state.error = payload.error
      state.isDeliveryDateListLoading = false
    },
    clear (state) {
      state.scheduleGroupList = {}
    }
  },
  extraReducers: {
    [generateXmlFile.rejected]: (state, { payload }) => {
      state.error = payload.error
    }
  }
})

const {
  getScheduleGroupListStart,
  getScheduleGroupListSuccess,
  getScheduleGroupListFailed,
  getDeliveryDateListStart,
  getDeliveryDateListSuccess,
  getDeliveryDateListFailed,
  clear
} = scheduleSlice.actions

const getScheduleGroupList = (params) => async dispatch => {
  try {
    dispatch(getScheduleGroupListStart())
    const scheduleGroupList = await powerPlantGroupEstimateApi.getPaginatedPowerPlantGroupEstimates(params)
    dispatch(getScheduleGroupListSuccess(scheduleGroupList))
  } catch (error) {
    dispatch(getScheduleGroupListFailed({ error }))
  }
}

const getDeliveryDateList = (powerPlantGroupId) => async dispatch => {
  try {
    dispatch(getDeliveryDateListStart())
    const deliveryDateList = await powerPlantGroupEstimateApi.getAllPowerPlantGroupEstimateDeliveryDate({ powerPlantGroupId })
    dispatch(getDeliveryDateListSuccess(deliveryDateList))
  } catch (error) {
    dispatch(getDeliveryDateListFailed({ error }))
  }
}

export {
  getScheduleGroupList,
  getDeliveryDateList,
  generateXmlFile,
  clear
}

export default scheduleSlice.reducer
