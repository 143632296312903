import { isEmpty } from 'lodash'
import dayjs from 'dayjs'

export const unWrapValues = (obj, propKey) => {
  if (isEmpty(obj)) {
    return undefined
  }

  return Object.entries(obj)
    .filter(([, value]) => value)
    .reduce((acc, [key, value]) => {
      let modifiedKey = key
      if (propKey) {
        const [firstChar, ...chars] = key
        modifiedKey = `${propKey}${firstChar.toUpperCase()}${chars.join('')}`
      }

      let modValue = Array.isArray(value) && value.length === 1 ? value[0] : value

      if (typeof modValue === 'object' && dayjs(modValue).isValid()) {
        modValue = `${dayjs(modValue).format('YYYY-MM-DD')}T00:00:00Z`
      }

      return {
        ...acc,
        [modifiedKey]: modValue
      }
    }, {})
}
