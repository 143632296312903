import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

export const getUTC = (date) => {
  dayjs.extend(utc)
  return date.utc().hour(0).minute(0).second(0).format()
}

export const getToday = () => dayjs()
export const getTodayUTC = () => {
  return getUTC(getToday())
}

export const getTomorrow = () => dayjs().add(1, 'day')
export const getTomorrowUTC = () => {
  return getUTC(getTomorrow())
}

export const isTodayOrInFuture = (timestamp) => {
  return getUTC(dayjs(timestamp)) >= getTodayUTC()
}
