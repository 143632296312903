import { createSelector, createSlice } from '@reduxjs/toolkit'
import { neuralLearningApi } from 'services/api'

const initialState = {
  neuralLearningList: {},
  resultDetails: null,
  compareResultsGraph: null,
  isListLoading: false,
  isResultDetailsLoading: false,
  isCompareResultsGraphLoading: false,
  isApplyMethodLoading: false,
  error: null
}

const neuralLearning = createSlice({
  name: 'neurallearning',
  initialState,
  reducers: {
    getNeuralLearningListStarted (state) {
      state.isListLoading = true
    },
    getNeuralLearningListSuccess (state, { payload }) {
      state.neuralLearningList = payload
      state.isListLoading = false
      state.error = null
    },
    getNeuralLearningListFailed (state, { payload }) {
      state.neuralLearningList = {}
      state.isListLoading = false
      state.error = payload.error
    },
    getResultDetailsStarted (state) {
      state.isResultDetailsLoading = true
    },
    getResultDetailsSuccess (state, { payload }) {
      state.isResultDetailsLoading = false
      state.resultDetails = payload
    },
    getResultDetailsFailed (state, { payload }) {
      state.isResultDetailsLoading = false
      state.resultDetails = null
      state.error = payload.error
    },
    getCompareResultsGraphStarted (state) {
      state.isCompareResultsGraphLoading = true
    },
    getCompareResultsGraphSuccess (state, { payload }) {
      state.compareResultsGraph = payload
      state.isCompareResultsGraphLoading = false
    },
    getCompareResultsGraphFailed (state, { payload }) {
      state.isCompareResultsGraphLoading = false
      state.error = payload.error
    },
    startLearningFailed (state, { payload }) {
      state.error = payload.error
    },
    applyMethodStarted (state) {
      state.isApplyMethodLoading = true
    },
    applyMethodSuccess (state, { payload }) {
      state.isApplyMethodLoading = false
    },
    applyMethodFailed (state, { payload }) {
      state.isApplyMethodLoading = false
      state.error = payload.error
    },
    clear (state) {
      state.neuralLearningList = {}
      state.resultDetails = null
      state.compareResultsGraph = null
      state.error = null
    }
  }
})

const {
  getNeuralLearningListStarted,
  getNeuralLearningListSuccess,
  getNeuralLearningListFailed,
  getResultDetailsStarted,
  getResultDetailsSuccess,
  getResultDetailsFailed,
  getCompareResultsGraphStarted,
  getCompareResultsGraphSuccess,
  getCompareResultsGraphFailed,
  startLearningFailed,
  applyMethodStarted,
  applyMethodSuccess,
  applyMethodFailed,
  clear
} = neuralLearning.actions

const getNeuralLearningList = (params) => async dispatch => {
  try {
    dispatch(getNeuralLearningListStarted())
    const neuralLearningList = await neuralLearningApi.getPaginatedLearningResults(params)
    dispatch(getNeuralLearningListSuccess(neuralLearningList))
  } catch (error) {
    dispatch(getNeuralLearningListFailed({ error }))
  }
}

const getResultDetails = (params) => async dispatch => {
  try {
    dispatch(getResultDetailsStarted())
    const resultDetails = await neuralLearningApi.compareResults(params)
    dispatch(getResultDetailsSuccess(resultDetails))
  } catch (error) {
    dispatch(getResultDetailsFailed({ error }))
  }
}

const getCompareResultsGraph = (params) => async dispatch => {
  try {
    dispatch(getCompareResultsGraphStarted())
    const compareResultsGraph = await neuralLearningApi.compareResultsGraph(params)
    dispatch(getCompareResultsGraphSuccess(compareResultsGraph))
  } catch (error) {
    dispatch(getCompareResultsGraphFailed({ error }))
  }
}

const startLearning = (learningParameters, filterParameters) => dispatch => {
  try {
    // don't wait for response
    neuralLearningApi.startLearning({ learningParameters })
      .then(() => dispatch(getNeuralLearningList(filterParameters)))
    dispatch(getNeuralLearningList(filterParameters))
  } catch (error) {
    dispatch(startLearningFailed({ error }))
  }
}

const applyMethod = ({ id, calculationMethod }) => async dispatch => {
  try {
    dispatch(applyMethodStarted())
    const applyMethod = await neuralLearningApi.applyMethod({ id, calculationMethod })
    dispatch(applyMethodSuccess(applyMethod))
  } catch (error) {
    dispatch(applyMethodFailed({ error }))
  }
}

const filterForecastProviders = createSelector(
  state => state.entity.forecastProviderList,
  forecastProviders => forecastProviders.filter(provider => !['Fact'].includes(provider.code))
)

export {
  getNeuralLearningList,
  getResultDetails,
  getCompareResultsGraph,
  startLearning,
  applyMethod,
  filterForecastProviders,
  clear
}

export default neuralLearning.reducer
