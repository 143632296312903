import { createSlice } from '@reduxjs/toolkit'
import { forecastValidationApi } from 'services/api'

const initialState = {
  validationSetting: {},
  isLoading: false,
  error: null
}

const prognosisValidations = createSlice({
  name: 'prognosisValidations',
  initialState,
  reducers: {
    getValidationSettingStart (state) {
      state.isLoading = true
    },
    getValidationSettingSuccess (state, { payload }) {
      state.validationSetting = payload
      state.error = null
      state.isLoading = false
    },
    getValidationSettingFailed (state, { payload }) {
      state.validationSetting = {}
      state.error = payload.error
      state.isLoading = false
    },
    editValidationSettingStart (state) {
      state.isLoading = true
    },
    editValidationSettingSuccess (state) {
      state.validationSetting = {}
      state.error = null
      state.isLoading = false
    },
    editValidationSettingFailed (state, { payload }) {
      state.error = payload.error
      state.isLoading = false
    },
    clear (state) {
      state.validationSetting = {}
      state.error = null
      state.isLoading = false
    }
  }
})

const {
  getValidationSettingStart,
  getValidationSettingSuccess,
  getValidationSettingFailed,
  editValidationSettingStart,
  editValidationSettingSuccess,
  editValidationSettingFailed,
  clear
} = prognosisValidations.actions

const getValidationSetting = () => async (dispatch) => {
  try {
    dispatch(getValidationSettingStart())
    const validationSetting = await forecastValidationApi.getForecastValidation()
    dispatch(getValidationSettingSuccess(validationSetting))
  } catch (error) {
    dispatch(getValidationSettingFailed({ error }))
  }
}

const editValidationSetting = (updateForecastValidationDto) => async (
  dispatch
) => {
  try {
    dispatch(editValidationSettingStart())
    await forecastValidationApi.editForecastValidation({
      updateForecastValidationDto
    })
    dispatch(editValidationSettingSuccess())
  } catch (error) {
    dispatch(editValidationSettingFailed({ error }))
  }
}

export { getValidationSetting, editValidationSetting, clear }

export default prognosisValidations.reducer
