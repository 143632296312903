import { createSelector, createSlice } from '@reduxjs/toolkit'
import { ModalStatus } from 'models/enum'
import { powerPlantApi } from 'services/api'

const initialState = {
  providerSetting: {},
  providerSettingsList: [],
  providerSettingModalStatus: ModalStatus.Hidden,
  isListLoading: false,
  isEntityLoading: false,
  error: null
}

const providerSettings = createSlice({
  name: 'providersettings',
  initialState,
  reducers: {
    getProviderSettingStart (state) {
      state.isEntityLoading = true
    },
    getProviderSettingSuccess (state, { payload }) {
      state.providerSetting = payload
      state.error = null
      state.isEntityLoading = false
    },
    getProviderSettingFailed (state, { payload }) {
      state.providerSetting = {}
      state.error = payload.error
      state.isEntityLoading = false
    },
    editProviderSettingSuccess (state) {
      state.providerSetting = {}
      state.error = null
      state.providerSettingModalStatus = ModalStatus.Hidden
      state.isEntityLoading = false
    },
    editProviderSettingFailed (state, { payload }) {
      state.error = payload.error
      state.isEntityLoading = false
    },
    getProviderSettingsListStart (state) {
      state.isListLoading = true
      state.error = null
    },
    getProviderSettingsListSuccess (state, { payload }) {
      state.isListLoading = false
      state.error = null
      state.providerSettingsList = payload
    },
    getProviderSettingsListFailed (state, { payload }) {
      state.isListLoading = false
      state.error = payload.error
      state.providerSettingsList = []
    },
    setProviderSettingModalStatus (state, { payload }) {
      state.providerSettingModalStatus = payload
      if (payload === ModalStatus.Hidden) {
        state.providerSetting = {}
      }
    },
    setProviderSettingLoading (state) {
      state.isEntityLoading = true
    },
    clear (state) {
      state.providerSetting = {}
      state.providerSettingsList = []
      state.providerSettingModalStatus = ModalStatus.Hidden
      state.error = null
    }
  }
})

const {
  getProviderSettingStart,
  getProviderSettingSuccess,
  getProviderSettingFailed,
  editProviderSettingSuccess,
  editProviderSettingFailed,
  getProviderSettingsListStart,
  getProviderSettingsListSuccess,
  getProviderSettingsListFailed,
  setProviderSettingModalStatus,
  setProviderSettingLoading,
  clear
} = providerSettings.actions

const getProviderSetting = (id) => async dispatch => {
  try {
    dispatch(getProviderSettingStart())
    const providerSetting = await powerPlantApi.getPowerPlant({ id })
    dispatch(getProviderSettingSuccess(providerSetting))
  } catch (error) {
    dispatch(getProviderSettingFailed({ error }))
  }
}

const editProviderSetting = (id, updatePowerPlantWeatherForecastProviderInformationDto) => async dispatch => {
  try {
    dispatch(setProviderSettingLoading())
    await powerPlantApi.updateWeatherForecast({
      id,
      updatePowerPlantWeatherForecastProviderInformationDto
    })
    dispatch(editProviderSettingSuccess())
    dispatch(getProviderSettingsList())
  } catch (error) {
    dispatch(editProviderSettingFailed({ error }))
  }
}

const getProviderSettingsList = () => async dispatch => {
  try {
    dispatch(getProviderSettingsListStart())
    const providerSettingsList = await powerPlantApi.getAllPowerPlantsWeatherForecastInformation()
    dispatch(getProviderSettingsListSuccess(providerSettingsList))
  } catch (error) {
    dispatch(getProviderSettingsListFailed({ error }))
  }
}

const filterForecastProviders = createSelector(
  state => state.entity.forecastProviderList,
  forecastProviders => forecastProviders.filter(provider => !['Manual', 'Fact'].includes(provider.code))
)

export {
  getProviderSetting,
  getProviderSettingsList,
  editProviderSetting,
  setProviderSettingModalStatus,
  filterForecastProviders,
  clear
}

export default providerSettings.reducer
