import { createSlice } from '@reduxjs/toolkit'
import { groupResponsibleApi } from 'services/api'
import { ModalStatus } from 'models/enum'

const initialState = {
  groupResponsibleList: [],
  groupResponsible: {},
  groupResponsibleModalStatus: ModalStatus.Hidden,
  isListLoading: false,
  isEntityLoading: false,
  error: null
}

const groupResponsibleSlice = createSlice({
  name: 'groupresponsible',
  initialState,
  reducers: {
    getGroupResponsibleListStart (state) {
      state.error = null
      state.isListLoading = true
    },
    getGroupResponsibleListSuccess (state, { payload }) {
      state.groupResponsibleList = payload
      state.error = null
      state.isListLoading = false
    },
    getGroupResponsibleListFailed (state, { payload }) {
      state.groupResponsibleList = []
      state.error = payload.error
      state.isListLoading = false
    },
    getGroupResponsibleSuccess (state, { payload }) {
      state.groupResponsible = payload
      state.error = null
      state.isEntityLoading = false
    },
    getGroupResponsibleFailed (state, { payload }) {
      state.groupResponsible = {}
      state.error = payload.error
      state.isEntityLoading = false
    },
    createGroupResponsibleSuccess (state) {
      state.groupResponsible = {}
      state.error = null
      state.groupResponsibleModalStatus = ModalStatus.Hidden
      state.isEntityLoading = false
    },
    createGroupResponsibleFailed (state, { payload }) {
      state.error = payload.error
      state.isEntityLoading = false
    },
    editGroupResponsibleSuccess (state) {
      state.groupResponsible = {}
      state.error = null
      state.groupResponsibleModalStatus = ModalStatus.Hidden
      state.isEntityLoading = false
    },
    editGroupResponsibleFailed (state, { payload }) {
      state.error = payload.error
      state.isEntityLoading = false
    },
    inactivateGroupResponsibleSuccess (state) {
      state.groupResponsible = {}
      state.error = null
    },
    inactivateGroupResponsibleFailed (state, { payload }) {
      state.error = payload.error
    },
    activateGroupResponsibleSuccess (state) {
      state.groupResponsible = {}
      state.error = null
    },
    activateGroupResponsibleFailed (state, { payload }) {
      state.error = payload.error
    },
    setGroupResponsibleModalStatus (state, { payload }) {
      state.groupResponsibleModalStatus = payload
      if (payload === ModalStatus.Hidden) {
        state.groupResponsible = {}
      }
    },
    setGroupResponsibleLoading (state) {
      state.isEntityLoading = true
    },
    clear (state) {
      state.groupResponsibleList = []
      state.groupResponsible = {}
      state.groupResponsibleModalStatus = ModalStatus.Hidden
      state.error = null
    }
  }
})

const {
  getGroupResponsibleListStart,
  getGroupResponsibleListSuccess,
  getGroupResponsibleListFailed,
  getGroupResponsibleSuccess,
  getGroupResponsibleFailed,
  createGroupResponsibleSuccess,
  createGroupResponsibleFailed,
  editGroupResponsibleSuccess,
  editGroupResponsibleFailed,
  inactivateGroupResponsibleSuccess,
  inactivateGroupResponsibleFailed,
  activateGroupResponsibleSuccess,
  activateGroupResponsibleFailed,
  setGroupResponsibleModalStatus,
  setGroupResponsibleLoading,
  clear
} = groupResponsibleSlice.actions

const getGroupResponsibleList = () => async dispatch => {
  try {
    dispatch(getGroupResponsibleListStart())
    const groupResponsible = await groupResponsibleApi.getAllPowerPlantGroupResponsibleParty()
    dispatch(getGroupResponsibleListSuccess(groupResponsible))
  } catch (error) {
    dispatch(getGroupResponsibleListFailed({ error }))
  }
}

const getGroupResponsible = (id) => async dispatch => {
  try {
    dispatch(setGroupResponsibleLoading())
    const groupResponsible = await groupResponsibleApi.getPowerPlantGroupResponsibleParty({ id })
    dispatch(getGroupResponsibleSuccess(groupResponsible))
  } catch (error) {
    dispatch(getGroupResponsibleFailed({ error }))
  }
}

const createGroupResponsible = (createPowerPlantGroupResponsiblePartyDto) => async dispatch => {
  try {
    dispatch(setGroupResponsibleLoading())
    await groupResponsibleApi.createPowerPlantGroupResponsibleParty({
      createPowerPlantGroupResponsiblePartyDto
    })
    dispatch(createGroupResponsibleSuccess())
    dispatch(getGroupResponsibleList())
  } catch (error) {
    dispatch(createGroupResponsibleFailed({ error }))
  }
}

const editGroupResponsible = (id, updatePowerPlantGroupResponsiblePartyDto) => async dispatch => {
  try {
    dispatch(setGroupResponsibleLoading())
    await groupResponsibleApi.editPowerPlantGroupResponsibleParty({
      id,
      updatePowerPlantGroupResponsiblePartyDto
    })
    dispatch(editGroupResponsibleSuccess())
    dispatch(getGroupResponsibleList())
  } catch (error) {
    dispatch(editGroupResponsibleFailed({ error }))
  }
}

const inactivateGroupResponsible = (id) => async dispatch => {
  try {
    await groupResponsibleApi.inactivatePowerPlantGroupResponsibleParty({ id })
    dispatch(inactivateGroupResponsibleSuccess())
    dispatch(getGroupResponsibleList())
  } catch (error) {
    dispatch(inactivateGroupResponsibleFailed({ error }))
  }
}

const activateGroupResponsible = (id) => async dispatch => {
  try {
    await groupResponsibleApi.activatePowerPlantGroupResponsibleParty({ id })
    dispatch(activateGroupResponsibleSuccess())
    dispatch(getGroupResponsibleList())
  } catch (error) {
    dispatch(activateGroupResponsibleFailed({ error }))
  }
}

const checkGroupResponsibleNameUniqueness = (name) => async dispatch => {
  try {
    const groupResponsible = await groupResponsibleApi.getAllPowerPlantGroupResponsibleParty({ name })
    return groupResponsible.length
  } catch (error) {
    return 0
  }
}

export {
  getGroupResponsibleList,
  getGroupResponsible,
  createGroupResponsible,
  editGroupResponsible,
  inactivateGroupResponsible,
  activateGroupResponsible,
  setGroupResponsibleModalStatus,
  checkGroupResponsibleNameUniqueness,
  clear
}

export default groupResponsibleSlice.reducer
