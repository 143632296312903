import React, { useMemo } from 'react'
import classnames from 'classnames'
// import bannerImage from 'assets/img/landing/banner_small.png'
import styles from './Landing.module.scss'
import sunSvg from 'assets/img/landing/sun.svg'
import sunPlaceHolderSvg from 'assets/img/landing/sun-placeholder.svg'
import powerSvg from 'assets/img/landing/power.svg'
import calendarSvg from 'assets/img/landing/calendar.svg'
import analysisSvg from 'assets/img/landing/analysis.svg'
import laptopSvg from 'assets/img/landing/laptop.svg'
import estimateSvg from 'assets/img/landing/estimate.svg'
import intelligenceSvg from 'assets/img/landing/intelligence.svg'
import workerSvg from 'assets/img/landing/worker.svg'
import supportSvg from 'assets/img/landing/support.svg'
import { useTranslation } from 'react-i18next'
import AdaptiveImageWidget from 'components/widgets/AdaptiveImage/AdaptiveImageWidget'

const LandingComponent = () => {
  const { t } = useTranslation()
  const serviceIcons = useMemo(() => [
    sunSvg, powerSvg, calendarSvg,
    analysisSvg, laptopSvg, estimateSvg,
    intelligenceSvg, workerSvg, supportSvg
  ], [])

  return (
    <>
      <header className={styles.banner}>
        <h1 className={styles.bannerHeader}>
          {t('landing.banner.header')}
        </h1>
        <div className={styles.bannerImageContainer}>
          <AdaptiveImageWidget
            image="landing/banner"
            className={styles.bannerImage}
            alt={t('landing.banner.header')}
          />
        </div>
      </header>

      <article className={styles.information}>
        <p className={styles.informationText}>
          {t('landing.information.text')}
        </p>
        <button className={classnames(styles.button, styles.informationButton)}>
          {t('landing.information.button')}
        </button>
      </article>

      <section id="#services" className={styles.services}>
        <h1 className={styles.header}>
          {t('landing.services.header')}
        </h1>
        <div className={styles.serviceBoxes}>
          {
            serviceIcons.map((serviceIcon, index) => (
              <article key={`service-${index}`} className={styles.serviceBox}>
                <div>
                  <img
                    className={styles.serviceImage}
                    src={serviceIcon}
                    alt={t(`landing.services.box${index + 1}.title`)}
                  />
                  <h2 className={styles.serviceTitle}>
                    {t(`landing.services.box${index + 1}.title`)}
                  </h2>
                  <p className={styles.serviceDesc}>
                    {t(`landing.services.box${index + 1}.text`)}
                  </p>
                </div>
                <button className={classnames(styles.button, styles.serviceButton)}>
                  {t('landing.next')}
                </button>
              </article>
            ))
          }
        </div>
      </section>

      <article id="#about" className={styles.about}>
        <div className={styles.aboutContainer}>
          <AdaptiveImageWidget
            image="landing/banner"
            className={styles.aboutImage}
            alt={t('landing.about.header')}
          />
          <div>
            <h2 className={styles.aboutHeader}>{t('landing.about.header')}</h2>
            <p className={styles.aboutText}>{t('landing.about.text')}</p>
          </div>
        </div>
      </article>

      <article id="#references" className={styles.references}>
        <h1 className={styles.header}>
          {t('landing.references.header')}
        </h1>
        <div className={styles.referenceContainer}>
          <div>
            <img src={sunPlaceHolderSvg} alt={t('landing.references.title')} />
            <p className={styles.referenceCompany}>{t('landing.references.title')}</p>
          </div>
          <div>
            <img src={sunPlaceHolderSvg} alt={t('landing.references.title')} />
            <p className={styles.referenceCompany}>{t('landing.references.title')}</p>
          </div>
          <div>
            <img src={sunPlaceHolderSvg} alt={t('landing.references.title')} />
            <p className={styles.referenceCompany}>{t('landing.references.title')}</p>
          </div>
          <div>
            <img src={sunPlaceHolderSvg} alt={t('landing.references.title')} />
            <p className={styles.referenceCompany}>{t('landing.references.title')}</p>
          </div>
        </div>
      </article>

      <article id="#contact" className={styles.contact}>
        <h1 className={styles.contactHeader}>
          {t('landing.contact.header')}
        </h1>
        <form className={styles.contactForm}>
          <div className={styles.contactInputContainer}>
            <input
              required
              className={styles.contactInput}
              placeholder={t('landing.contact.name')}
            />
            <input
              required
              className={styles.contactInput}
              placeholder={t('landing.contact.email')}
            />
          </div>
          <textarea
            required
            className={styles.contactMessage}
            placeholder={t('landing.contact.message')}
            rows="5"
          />
          <button
            className={classnames(styles.button, styles.contactButton)}
            type="submit"
          >
            {t('landing.contact.send')}
          </button>
        </form>
      </article>
    </>
  )
}

export default LandingComponent
