import { createSlice } from '@reduxjs/toolkit'
import { annualEstimateApi } from 'services/api'

const initialState = {
  annualEstimateList: [],
  annualEstimate: [],
  isListLoading: false,
  isEntityLoading: false,
  error: null
}

const annualEstimateSlice = createSlice({
  name: 'annualestimate',
  initialState,
  reducers: {
    getAnnualEstimateListStart (state) {
      state.error = null
      state.isListLoading = true
    },
    getAnnualEstimateListSuccess (state, { payload }) {
      state.annualEstimateList = payload
      state.error = null
      state.isListLoading = false
    },
    getAnnualEstimateListFailed (state, { payload }) {
      state.annualEstimateList = []
      state.error = payload.error
      state.isListLoading = false
    },
    getAnnualEstimateSuccess (state, { payload }) {
      state.annualEstimate = payload
      state.error = null
      state.isEntityLoading = false
    },
    getAnnualEstimateFailed (state, { payload }) {
      state.annualEstimate = []
      state.error = payload.error
      state.isEntityLoading = false
    },
    modifyAnnualEstimateCell (state, { payload }) {
      state.annualEstimate = payload
    },
    createAnnualEstimateSuccess (state) {
      state.annualEstimate = []
      state.error = null
      state.isEntityLoading = false
    },
    createAnnualEstimateFailed (state, { payload }) {
      state.error = payload.error
      state.isEntityLoading = false
    },
    setAnnualEstimateLoading (state) {
      state.isEntityLoading = true
    },
    clear (state) {
      state.annualEstimateList = []
      state.annualEstimate = []
      state.error = null
    }
  }
})

const {
  getAnnualEstimateListStart,
  getAnnualEstimateListSuccess,
  getAnnualEstimateListFailed,
  getAnnualEstimateSuccess,
  getAnnualEstimateFailed,
  modifyAnnualEstimateCell,
  createAnnualEstimateSuccess,
  createAnnualEstimateFailed,
  setAnnualEstimateLoading,
  clear
} = annualEstimateSlice.actions

const getAnnualEstimateList = (powerPlantId) => async dispatch => {
  try {
    dispatch(getAnnualEstimateListStart())
    const reportageList = await annualEstimateApi.getAllAnnualEstimate({ powerPlantId })
    dispatch(getAnnualEstimateListSuccess(reportageList))
  } catch (error) {
    dispatch(getAnnualEstimateListFailed({ error }))
  }
}

const getAnnualEstimate = (values) => async dispatch => {
  try {
    dispatch(setAnnualEstimateLoading())
    const annualEstimate = await annualEstimateApi.getAnnualEstimate(values)
    dispatch(getAnnualEstimateSuccess(annualEstimate))
  } catch (error) {
    dispatch(getAnnualEstimateFailed({ error }))
  }
}

const modifyAnnualEstimate = (id, modifiedValue) => async (dispatch, getState) => {
  const { annualEstimate } = getState().annualestimate
  const { key, value } = modifiedValue
  const modifiedAnnualEstimate = annualEstimate.map(item => ({
    ...item,
    ...(item.id === id && { [key]: value || 0 })
  }))
  dispatch(modifyAnnualEstimateCell(modifiedAnnualEstimate))
}

const createAnnualEstimate = (powerPlantId, createAnnualEstimateDto) => async dispatch => {
  try {
    dispatch(setAnnualEstimateLoading())
    await annualEstimateApi.createAnnualEstimate({ createAnnualEstimateDto })
    dispatch(createAnnualEstimateSuccess())
    dispatch(getAnnualEstimateList(powerPlantId))
    return { isSuccess: true }
  } catch (error) {
    dispatch(createAnnualEstimateFailed({ error }))
    return { isSuccess: false }
  }
}

const downloadScheduleMessageXml = (id) => async dispatch => {
  const response = await annualEstimateApi.scheduleMessageXml({ id }, { responseType: 'blob' })
  return response
}

export {
  getAnnualEstimateList,
  getAnnualEstimate,
  modifyAnnualEstimate,
  createAnnualEstimate,
  downloadScheduleMessageXml,
  clear
}

export default annualEstimateSlice.reducer
