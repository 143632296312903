import { createSlice } from '@reduxjs/toolkit'
import { powerPlantGroupGlobalApi } from 'services/api'

const initialState = {
  powerPlantGroupGlobalList: [],
  isListLoading: false,
  error: null
}

const powerPlantGroupGlobalSlice = createSlice({
  name: 'powerplantgroupglobal',
  initialState,
  reducers: {
    getPowerPlantGroupGlobalListStart (state) {
      state.error = null
      state.isListLoading = true
    },
    getPowerPlantGroupGlobalListSuccess (state, { payload }) {
      state.powerPlantGroupGlobalList = payload
      state.error = null
      state.isListLoading = false
    },
    getPowerPlantGroupGlobalListFailed (state, { payload }) {
      state.powerPlantGroupGlobalList = []
      state.error = payload.error
      state.isListLoading = false
    },
    clear (state) {
      state.powerPlantGroupGlobalList = []
      state.error = null
    }
  }
})

const {
  getPowerPlantGroupGlobalListStart,
  getPowerPlantGroupGlobalListSuccess,
  getPowerPlantGroupGlobalListFailed,
  clear
} = powerPlantGroupGlobalSlice.actions

const getPowerPlantGroupGlobalList = () => async dispatch => {
  try {
    dispatch(getPowerPlantGroupGlobalListStart())
    const powerPlantGroupGlobal = await powerPlantGroupGlobalApi.getAllPowerPlantGroupGlobal()
    dispatch(getPowerPlantGroupGlobalListSuccess(powerPlantGroupGlobal))
  } catch (error) {
    dispatch(getPowerPlantGroupGlobalListFailed({ error }))
  }
}

export {
  getPowerPlantGroupGlobalList,
  clear
}

export default powerPlantGroupGlobalSlice.reducer
