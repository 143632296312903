import React, { useEffect, useState } from 'react'
import { Menu } from 'antd'
import styles from './Menu.module.scss'
import { useSelector } from 'react-redux'
import { useResize } from 'hooks'
import { hasPermission } from 'services/permission'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { history } from 'router'
import { useLocation } from 'react-router-dom'
import StaticMenuComponent from './StaticMenuComponent'
import routes from 'router/routes'
import { isFunction } from 'lodash'

const MenuComponent = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const { width } = useResize()
  const isMobile = width < 975
  const { isLoggedIn, isUpdating } = useSelector(state => state.auth)
  const [selectedKeys, setSelectedkeys] = useState([])

  const checkMenuItemPermission = ({ requiredRole }) => {
    return hasPermission(requiredRole, true)
  }

  const renderTitle = (name) => (
    <>
      <span className={styles.subMenuTitle}>{t(`navigation.${name}.title`)}</span>
      {!isMobile ? <FontAwesomeIcon icon={faChevronDown} /> : null}
    </>
  )

  const renderSubMenu = (parent) => {
    const { name, children } = parent
    return !isUpdating && (
      <Menu.SubMenu
        key={`submenu-${name}`}
        title={renderTitle(name)}
        className={styles.subMenu}
        popupClassName={styles.menuPopup}
      >
        {children
          .filter(checkMenuItemPermission)
          .filter(({ standalone }) => !standalone)
          .map((child) => {
            const defaultParam = isFunction(child.defaultParam) ? child.defaultParam() : child.defaultParam
            return (
              <Menu.Item
                key={`${name}/${child.name}`}
                className={styles.menuItem}
                onClick={() => history.push(`/${[name, child.name, defaultParam].join('/')}`)}
              >
                {t(`navigation.${name}.${child.name}`)}
              </Menu.Item>
            )
          }
        )}
      </Menu.SubMenu>
    )
  }

  useEffect(() => {
    if (!location.hash) {
      const [parent, child] = location.pathname.split('/').filter(Boolean)
      setSelectedkeys([child ? `${parent}/${child}` : parent])
    }
  }, [location])

  return isLoggedIn ? (
    <Menu
      forceSubMenuRender
      className={styles.menu}
      selectedKeys={selectedKeys}
      mode={isMobile ? 'inline' : 'horizontal'}
      onClick={(e) => setSelectedkeys([e.key])}
      subMenuOpenDelay={0.12}
    >
      {routes
        .filter(checkMenuItemPermission)
        .filter(({ standalone }) => !standalone)
        .map((parent, index) => {
          const { requiredRole, ...restParentProps } = parent
          const defaultParam = parent.defaultParam instanceof Function
            ? parent.defaultParam()
            : parent.defaultParam

          const pathSeparator = parent.defaultParam?.startsWith('?') ? '' : '/'

          return parent.children ? renderSubMenu(restParentProps) : (
            <Menu.Item
              key={parent.name}
              className={styles.menuItem}
              onClick={() => history.push(`/${[parent.name, defaultParam].join(pathSeparator)}`)}
            >
              {t(`navigation.${parent.name}.title`)}
            </Menu.Item>
          )
      })}
    </Menu>
  ) : <StaticMenuComponent />
}

export default MenuComponent
