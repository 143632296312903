import { cloneElement } from 'react'
import PropTypes from 'prop-types'
import { hasPermission, requiredRoleValidator } from 'services/permission'

const PermissionWidget = (props) => {
  const { requiredRole, children, ...restProps } = props

  if (!Array.isArray(requiredRole)) {
    throw new Error('requiredRole prop is not an array.')
  }

  if (hasPermission(requiredRole)) {
    return cloneElement(children, restProps)
  }
  return null
}

export default PermissionWidget

PermissionWidget.propTypes = {
  requiredRole: requiredRoleValidator,
  children: PropTypes.node
}
