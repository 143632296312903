import { createSlice } from '@reduxjs/toolkit'
import { companyProfileApi } from 'services/api'
import { history } from 'router'

const initialState = {
  profile: {},
  isEntityLoading: false,
  hasDirtyFields: false,
  error: null
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    getProfileStart (state) {
      state.isEntityLoading = true
      state.error = null
    },
    getProfileSuccess (state, { payload }) {
      state.profile = payload
      state.error = null
      state.isEntityLoading = false
    },
    getProfileFailed (state, { payload }) {
      state.profile = {}
      state.error = payload.error
      state.isEntityLoading = false
    },
    editProfileStart (state) {
      state.isEntityLoading = true
      state.error = null
    },
    editProfileSuccess (state, { payload }) {
      state.hasDirtyFields = false
      state.error = null
      state.isEntityLoading = false
    },
    editProfileFailed (state, { payload }) {
      state.error = payload.error
      state.isEntityLoading = false
    },
    setDirtyFields (state, { payload }) {
      state.hasDirtyFields = payload
    },
    clear (state) {
      state.profile = {}
      state.hasDirtyFields = false
      state.error = null
    }
  }
})

const {
  getProfileStart,
  getProfileSuccess,
  getProfileFailed,
  editProfileStart,
  editProfileSuccess,
  editProfileFailed,
  setDirtyFields,
  clear
} = profileSlice.actions

const getProfile = (id) => async dispatch => {
  try {
    dispatch(getProfileStart())
    const profile = await companyProfileApi.getCompanyProfile({ id })
    dispatch(getProfileSuccess(profile))
  } catch (error) {
    dispatch(getProfileFailed({ error }))
    history.push('/notfound')
  }
}

const editProfile = (id, profile) => async dispatch => {
  try {
    dispatch(editProfileStart())
    const updateCompanyProfileDto = { ...profile, id }
    await companyProfileApi.editCompanyProfile({ id, updateCompanyProfileDto })
    dispatch(editProfileSuccess())
    dispatch(getProfile(id))
  } catch (error) {
    dispatch(editProfileFailed({ error }))
  }
}

const checkProfileNameUniqueness = (id, name) => async dispatch => {
  try {
    const { isValid } = await companyProfileApi.isProfileNameValid({ id, name })
    return isValid
  } catch (error) {
    return true
  }
}

export {
  getProfile,
  editProfile,
  checkProfileNameUniqueness,
  setDirtyFields,
  clear
}

export default profileSlice.reducer
