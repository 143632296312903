import { useEffect, useState } from 'react'
import { debounce, get } from 'lodash'
import { ResizeObserver } from '@juggle/resize-observer'

const useResize = (debounceTime = 30, el) => {
  const [width, setWidth] = useState(window.innerWidth)

  const resizeCallback = debounce(entries => {
    const clientWidth = get(entries, '[0].contentRect.width')
    setWidth(clientWidth)
  }, debounceTime)

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const resizeObserver = new ResizeObserver(resizeCallback)
    resizeObserver.observe(el || document.querySelector('body'))
    return () => resizeObserver.disconnect()
  }, [resizeCallback, el])

  return {
    width
  }
}

export default useResize
