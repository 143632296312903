import { createSlice } from '@reduxjs/toolkit'
import { analysisApi } from 'services/api'

const initialState = {
  productionAnalysis: {},
  chartData: null,
  isProductionAnalysisLoading: false,
  isChartLoading: false,
  error: null
}

const productionDiffSlice = createSlice({
  name: 'productiondiff',
  initialState,
  reducers: {
    getProductionAnalysisStart (state) {
      state.error = null
      state.isProductionAnalysisLoading = true
    },
    getProductionAnalysisSuccess (state, { payload }) {
      state.productionAnalysis = payload
      state.error = null
      state.isProductionAnalysisLoading = false
    },
    getProductionAnalysisFailed (state, { payload }) {
      state.productionAnalysis = {}
      state.error = payload.error
      state.isProductionAnalysisLoading = false
    },
    getProductionDataDiffAnalysisStart (state) {
      state.error = null
      state.isChartLoading = true
    },
    getProductionDataDiffAnalysisSuccess (state, { payload }) {
      state.chartData = payload
      state.error = null
      state.isChartLoading = false
    },
    getProductionDataDiffAnalysisFailed (state, { payload }) {
      state.chartData = null
      state.error = payload.error
      state.isChartLoading = false
    },
    downloadProductionDiffFileSuccess (state) {
      state.error = null
    },
    downloadProductionDiffFileFailed (state, { payload }) {
      state.error = payload.error
    },
    clearChartData (state) {
      state.chartData = null
    },
    clear (state) {
      state.productionAnalysis = {}
      state.chartData = null
    }
  }
})

const {
  getProductionAnalysisStart,
  getProductionAnalysisSuccess,
  getProductionAnalysisFailed,
  getProductionDataDiffAnalysisStart,
  getProductionDataDiffAnalysisSuccess,
  getProductionDataDiffAnalysisFailed,
  downloadProductionDiffFileSuccess,
  downloadProductionDiffFileFailed,
  clearChartData,
  clear
} = productionDiffSlice.actions

const getProductionAnalysis = (powerPlantId, deliveryDate) => async dispatch => {
  try {
    dispatch(getProductionAnalysisStart())
    const productionAnalysis = await analysisApi.getProductionAnalysis({ powerPlantId, deliveryDate })
    dispatch(getProductionAnalysisSuccess(productionAnalysis))
  } catch (error) {
    dispatch(getProductionAnalysisFailed({ error }))
  }
}

const getProductionDataDiffAnalysis = (values) => async dispatch => {
  try {
    dispatch(getProductionDataDiffAnalysisStart())
    const chartData = await analysisApi.getProductionDataDiffAnalysis(values)
    dispatch(getProductionDataDiffAnalysisSuccess(chartData))
  } catch (error) {
    dispatch(getProductionDataDiffAnalysisFailed({ error }))
  }
}

const downloadProductionDiffFile = (values) => async dispatch => {
  try {
    const productionFile = await analysisApi.downloadProductionDiff(values, { responseType: 'blob' })
    dispatch(downloadProductionDiffFileSuccess())
    return productionFile
  } catch (error) {
    dispatch(downloadProductionDiffFileFailed({ error }))
    return null
  }
}

export {
  getProductionAnalysis,
  getProductionDataDiffAnalysis,
  downloadProductionDiffFile,
  clearChartData,
  clear
}

export default productionDiffSlice.reducer
