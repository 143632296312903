import React, { useEffect } from 'react'
import { Router } from 'react-router-dom'
import { history } from 'router'
import RouterView from './router'
import setupAxios from 'services/axios'

const App = () => {
  useEffect(() => {
    setupAxios()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Router history={history}>
      <RouterView />
    </Router>
  )
}

export default App
