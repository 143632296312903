import { createSlice } from '@reduxjs/toolkit'
import { estimateApi } from 'services/api'

const initialState = {
  isLoading: false,
  error: null
}

const batchEstimateSlice = createSlice({
  name: 'batchestimate',
  initialState,
  reducers: {
    downloadBatchAnalysisDataZipFileStart (state) {
      state.error = null
      state.isLoading = true
    },
    downloadBatchAnalysisDataZipFileSuccess (state) {
      state.error = null
      state.isLoading = false
    },
    downloadBatchAnalysisDataZipFileFailed (state, { payload }) {
      state.error = payload.error
      state.isLoading = false
    },
    clear (state) {
      state.error = null
      state.isLoading = false
    }
  }
})

const {
  downloadBatchAnalysisDataZipFileStart,
  downloadBatchAnalysisDataZipFileSuccess,
  downloadBatchAnalysisDataZipFileFailed,
  clear
} = batchEstimateSlice.actions

const downloadBatchAnalysisDataZipFile = (powerPlantIds, from, to) => async dispatch => {
  try {
    dispatch(downloadBatchAnalysisDataZipFileStart())
    const batchAnalysisDataZipFile = await estimateApi.getBatchEstimateDataZipFile({
      powerPlantIds, from, to
    }, {
      responseType: 'blob'
    })
    dispatch(downloadBatchAnalysisDataZipFileSuccess())
    return batchAnalysisDataZipFile
  } catch (error) {
    dispatch(downloadBatchAnalysisDataZipFileFailed({ error }))
    return null
  }
}

export {
  downloadBatchAnalysisDataZipFile,
  clear
}

export default batchEstimateSlice.reducer
