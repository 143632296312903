import { createSlice } from '@reduxjs/toolkit'
import { estimateStatusApi } from 'services/api'

const initialState = {
  estimateErrors: {},
  isListLoading: false,
  error: null
}

const estimateErrorsSlice = createSlice({
  name: 'estimateerrors',
  initialState,
  reducers: {
    getEstimateErrorsStart (state) {
      state.error = null
      state.isListLoading = true
    },
    getEstimateErrorsSuccess (state, { payload }) {
      state.estimateErrors = payload
      state.error = null
      state.isListLoading = false
    },
    getEstimateErrorsFailed (state, { payload }) {
      state.estimateErrors = []
      state.error = payload.error
      state.isListLoading = false
    },
    clear (state) {
      state.estimateErrors = []
      state.error = null
    }
  }
})

const {
  getEstimateErrorsStart,
  getEstimateErrorsSuccess,
  getEstimateErrorsFailed,
  clear
} = estimateErrorsSlice.actions

const getEstimateErrors = (params) => async dispatch => {
  try {
    dispatch(getEstimateErrorsStart())
    const estimateErrors = await estimateStatusApi.getPaginatedEstimateErrors(params)
    dispatch(getEstimateErrorsSuccess(estimateErrors))
  } catch (error) {
    dispatch(getEstimateErrorsFailed({ error }))
  }
}

export {
  getEstimateErrors,
  clear
}

export default estimateErrorsSlice.reducer
