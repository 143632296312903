import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import SchemaContext from 'contexts/SchemaContext'
import { useTranslation } from 'react-i18next'
import SpinnerWidget from './Spinner/SpinnerWidget'
import ModalWidget from './Modal/ModalWidget'
import { ModalStatus } from 'models/enum'
import globalStyle from 'assets/scss/Global.module.scss'

const ModalFormWidget = (props) => {
  const { children, title, desc, hideTitle, modalStatus, isEntityLoading, form, ...restProps } = props
  const { t } = useTranslation()
  const { formNamespace, isDirty } = useContext(SchemaContext)

  const translatedTitle = useMemo(() => {
    if (hideTitle) {
      return null
    } else if (title) {
      return title
    }
    const getTitle = (status) => ({
      [ModalStatus.Create]: t(`${formNamespace}.create`),
      [ModalStatus.Edit]: t(`${formNamespace}.edit`),
      [ModalStatus.Duplicate]: t(`${formNamespace}.duplicate`)
    })[status]
    return (
      <>
        {getTitle(modalStatus)}
        {desc ? (
          <>
            <br />
            <h5 className={globalStyle.modalDesc}>{desc}</h5>
          </>
        ) : null}
      </>
    )
  }, [formNamespace, modalStatus, t, title, hideTitle, desc])

  return (
    <ModalWidget
      destroyOnClose
      keyboard={false}
      closable={false}
      maskClosable={false}
      title={isEntityLoading ? t('general.loading') : translatedTitle}
      okText={t('general.save')}
      cancelText={t('general.cancel')}
      afterClose={() => form?.resetFields()}
      okButtonProps={{ disabled: isEntityLoading || isDirty }}
      cancelButtonProps={{ disabled: isEntityLoading }}
      {...restProps}
    >
      <SpinnerWidget spinning={isEntityLoading}>
        {children}
      </SpinnerWidget>
    </ModalWidget>
  )
}

export default ModalFormWidget

ModalFormWidget.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  children: PropTypes.node.isRequired,
  modalStatus: PropTypes.oneOf(Object.keys(ModalStatus)).isRequired,
  form: PropTypes.object,
  isEntityLoading: PropTypes.bool,
  hideTitle: PropTypes.bool
}

ModalFormWidget.defaultProps = {
  isEntityLoading: false,
  modalStatus: ModalStatus.Hidden,
  hideTitle: false
}
