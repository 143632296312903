import { createSlice } from '@reduxjs/toolkit'
import { history } from 'router'
import { inverterApi, powerPlantApi, solarPanelApi } from 'services/api'

const initialState = {
  powerPlant: {},
  solarPanelList: [],
  inverterList: [],
  isListLoading: false,
  error: null
}

const overviewSlice = createSlice({
  name: 'overview',
  initialState,
  reducers: {
    getPowerPlantSuccess (state, { payload }) {
      state.powerPlant = payload
      state.error = null
    },
    getPowerPlantFailed (state, { payload }) {
      state.powerPlant = {}
      state.error = payload.error
    },
    getSolarPanelByPowerPlantListStart (state) {
      state.isListLoading = true
      state.error = null
    },
    getSolarPanelByPowerPlantListSuccess (state, { payload }) {
      state.isListLoading = false
      state.error = null
      state.solarPanelList = payload
    },
    getSolarPanelByPowerPlantListFailed (state, { payload }) {
      state.isListLoading = false
      state.error = payload.error
      state.solarPanelList = []
    },
    getInverterByPowerPlantListStart (state) {
      state.isListLoading = true
      state.error = null
    },
    getInverterByPowerPlantListSuccess (state, { payload }) {
      state.isListLoading = false
      state.error = null
      state.inverterList = payload
    },
    getInverterByPowerPlantListFailed (state, { payload }) {
      state.isListLoading = false
      state.error = payload.error
      state.inverterList = []
    },
    clear (state) {
      state.powerPlant = {}
      state.powerPlantList = []
      state.inverterList = []
      state.error = null
    }
  }
})

const {
  getSolarPanelByPowerPlantListStart,
  getSolarPanelByPowerPlantListSuccess,
  getSolarPanelByPowerPlantListFailed,
  getInverterByPowerPlantListStart,
  getInverterByPowerPlantListSuccess,
  getInverterByPowerPlantListFailed,
  getPowerPlantSuccess,
  getPowerPlantFailed,
  clear
} = overviewSlice.actions

const getPowerPlant = (id) => async dispatch => {
  try {
    const powerPlant = await powerPlantApi.getPowerPlant({ id })
    dispatch(getPowerPlantSuccess(powerPlant))
  } catch (error) {
    dispatch(getPowerPlantFailed({ error }))
    history.push('/data/masterdata/balance-responsible')
  }
}

const getSolarPanelByPowerPlantList = (powerPlantId) => async dispatch => {
  try {
    dispatch(getSolarPanelByPowerPlantListStart())
    const solarPanelList = await solarPanelApi.getAllSolarPanelByPowerPlantId({ powerPlantId })
    dispatch(getSolarPanelByPowerPlantListSuccess(solarPanelList))
  } catch (error) {
    dispatch(getSolarPanelByPowerPlantListFailed({ error }))
  }
}

const getInverterByPowerPlantList = (powerPlantId) => async dispatch => {
  try {
    dispatch(getInverterByPowerPlantListStart())
    const inverterList = await inverterApi.getAllInverterByPowerPlantId({ powerPlantId })
    dispatch(getInverterByPowerPlantListSuccess(inverterList))
  } catch (error) {
    dispatch(getInverterByPowerPlantListFailed({ error }))
  }
}

export {
  getSolarPanelByPowerPlantList,
  getInverterByPowerPlantList,
  getPowerPlant,
  clear
}

export default overviewSlice.reducer
