import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logout, setLoginModalVisible } from 'store/auth/auth.slice'
import { history } from 'router'
import { notification } from 'antd'
import { clearProfileList, getCompanyProfileList } from 'store/general/entity.slice'
import { updateSessionProfile } from 'store/profile/companyprofile.slice'
import { Role } from 'models/enum'
import classnames from 'classnames'
import styles from '../Header.module.scss'
import logoWhite from 'assets/img/logo.svg'
import logoYellow from 'assets/img/logo-yellow.svg'

const useHeader = () => {
  const dispatch = useDispatch()
  const {
    role,
    userId,
    username,
    isLoggedIn,
    isUpdating,
    sessionCompanyProfileId
  } = useSelector(state => state.auth)
  const { isCompanyProfileListLoading, companyProfileList } = useSelector(state => state.entity)
  const [isMenuOpen, setMenuOpen] = useState(false)
  const handleToggleMenu = () => setMenuOpen(!isMenuOpen)
  const handleLogout = (e) => {
    e.preventDefault()
    dispatch(logout())
  }

  const handleOnLoginClick = (e) => {
    notification.close('session-expired')
    e.preventDefault()
    dispatch(setLoginModalVisible(true))
  }

  const isSysAdminMode = useMemo(() => {
    return role === Role.SysAdmin && !sessionCompanyProfileId
  }, [role, sessionCompanyProfileId])

  const headerClasses = useMemo(() => classnames(styles.header, {
    [styles.open]: isMenuOpen,
    [styles.dark]: isLoggedIn && !isSysAdminMode
  }), [isLoggedIn, isMenuOpen, isSysAdminMode])

  const logoSource = useMemo(() => {
    return isLoggedIn && !isSysAdminMode ? logoYellow : logoWhite
  }, [isLoggedIn, isSysAdminMode])

  const handleClickChangePassword = () => {
    history.push('/change-password')
  }

  useEffect(() => {
    if (role === Role.SysAdmin && isLoggedIn) {
      dispatch(getCompanyProfileList())
    }
    return () => dispatch(clearProfileList())
  }, [dispatch, role, isLoggedIn])

  const handleChangeProfile = (profileId) => {
    dispatch(updateSessionProfile(userId, profileId))
  }

  const handleMenuItemClick = (event) => {
    if (event.target.getAttribute('role') === 'menuitem') {
      setMenuOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleMenuItemClick)
    return () => {
      document.removeEventListener('click', handleMenuItemClick)
    }
  }, [])

  return {
    role,
    username,
    isMenuOpen,
    isLoggedIn,
    isSysAdminMode,
    logoSource,
    isUpdating,
    headerClasses,
    companyProfileList,
    sessionCompanyProfileId,
    isCompanyProfileListLoading,
    handleLogout,
    handleToggleMenu,
    handleOnLoginClick,
    handleClickChangePassword,
    handleChangeProfile
  }
}

export default useHeader
