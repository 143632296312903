import { createSelector, createSlice } from '@reduxjs/toolkit'
import { forecastSyncApi } from 'services/api'

const initialState = {
  forecastSyncJobList: {},
  isListLoading: false,
  error: null
}

const forecastSyncJobSlice = createSlice({
  name: 'forecastsyncjob',
  initialState,
  reducers: {
    getForecastSyncJobStart (state) {
      state.error = null
      state.isListLoading = true
    },
    getForecastSyncJobSuccess (state, { payload }) {
      state.forecastSyncJobList = payload
      state.error = null
      state.isListLoading = false
    },
    getForecastSyncJobFailed (state, { payload }) {
      state.forecastSyncJobList = {}
      state.error = payload.error
      state.isListLoading = false
    },
    clear (state) {
      state.forecastSyncJobList = {}
      state.error = null
    }
  }
})

const {
  getForecastSyncJobStart,
  getForecastSyncJobSuccess,
  getForecastSyncJobFailed,
  clear
} = forecastSyncJobSlice.actions

const getForecastSyncJob = (params) => async dispatch => {
  try {
    dispatch(getForecastSyncJobStart())
    const forecastSyncList = await forecastSyncApi.getPaginatedForecastSyncJob(params)
    dispatch(getForecastSyncJobSuccess(forecastSyncList))
  } catch (error) {
    dispatch(getForecastSyncJobFailed({ error }))
  }
}

const filterForecastProviders = createSelector(state => state.entity.forecastProviderList, forecastProviders => {
  return forecastProviders
    .filter(({ code }) => !['Manual', 'GlobalManual', 'Fact'].includes(code))
    .sort((a, b) => a.name.localeCompare(b.name))
})

export {
  getForecastSyncJob,
  filterForecastProviders,
  clear
}

export default forecastSyncJobSlice.reducer
