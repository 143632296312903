import { createSlice } from '@reduxjs/toolkit'
import { scheduleMetadataGroupApi } from 'services/api'
import { ModalStatus, ScheduleState } from 'models/enum'

const initialState = {
  scheduleGroupManagementList: {},
  scheduleGroupData: {},
  isListLoading: false,
  isEntityLoading: false,
  scheduleGroupModalStatus: ModalStatus.Hidden,
  error: null
}

const scheduleGroupManagementSlice = createSlice({
  name: 'schedulegroupmanagement',
  initialState,
  reducers: {
    getScheduleGroupManagementListStart (state) {
      state.error = null
      state.isListLoading = true
    },
    getScheduleGroupManagementListSuccess (state, { payload }) {
      state.scheduleGroupManagementList = payload
      state.error = null
      state.isListLoading = false
    },
    getScheduleGroupManagementListFailed (state, { payload }) {
      state.scheduleGroupManagementList = {}
      state.error = payload.error
      state.isListLoading = false
    },
    getScheduleGroupDataStart (state) {
      state.isEntityLoading = true
    },
    getScheduleGroupDataSuccess (state, { payload }) {
      state.isEntityLoading = false
      state.scheduleGroupData = payload
    },
    getScheduleGroupDataFailed (state, { payload }) {
      state.isEntityLoading = false
      state.error = payload.error
    },
    createScheduleGroupStart (state) {
      state.isEntityLoading = true
    },
    createScheduleGroupSuccess (state) {
      state.isEntityLoading = false
      state.scheduleGroupModalStatus = ModalStatus.Hidden
      state.scheduleGroupData = {}
    },
    createScheduleGroupFailed (state, { payload }) {
      state.isEntityLoading = false
      state.error = payload.error
    },
    downloadScheduleManagementXMLSuccess (state) {
      state.error = null
    },
    downloadScheduleManagementXMLFailed (state, { payload }) {
      state.error = payload
    },
    downloadScheduleManagementZIPSuccess (state) {
      state.error = null
    },
    downloadScheduleManagementZIPFailed (state, { payload }) {
      state.error = payload
    },
    submitReportageToMEKSuccess (state, { payload }) {
      state.scheduleGroupManagementList.data = state.scheduleGroupManagementList.data.map(item => ({
        ...item,
        state: item.id === payload ? ScheduleState.Submitted : item.state
      }))
    },
    submitReportageToMEKFailed (state, { payload }) {
      state.error = payload
    },
    setScheduleGroupModalStatus (state, { payload }) {
      state.scheduleGroupModalStatus = payload
      if (payload === ModalStatus.Hidden) {
        state.scheduleGroupData = {}
      }
    },
    clear (state) {
      state.scheduleGroupManagementList = {}
      state.scheduleGroupModalStatus = ModalStatus.Hidden
      state.error = null
    }
  }
})

const {
  getScheduleGroupManagementListStart,
  getScheduleGroupManagementListSuccess,
  getScheduleGroupManagementListFailed,
  downloadScheduleManagementXMLSuccess,
  downloadScheduleManagementXMLFailed,
  downloadScheduleManagementZIPSuccess,
  downloadScheduleManagementZIPFailed,
  submitReportageToMEKSuccess,
  submitReportageToMEKFailed,
  getScheduleGroupDataStart,
  getScheduleGroupDataSuccess,
  getScheduleGroupDataFailed,
  createScheduleGroupStart,
  createScheduleGroupSuccess,
  createScheduleGroupFailed,
  setScheduleGroupModalStatus,
  clear
} = scheduleGroupManagementSlice.actions

const getScheduleGroupManagementList = (params) => async dispatch => {
  try {
    dispatch(getScheduleGroupManagementListStart())
    const scheduleGroupManagementList = await scheduleMetadataGroupApi.getPaginatedAllGroup(params)
    dispatch(getScheduleGroupManagementListSuccess(scheduleGroupManagementList))
  } catch (error) {
    dispatch(getScheduleGroupManagementListFailed({ error }))
  }
}

const getCalculatedScheduleGroupData = (scheduleMetadataGroupId, filter) => async dispatch => {
  try {
    dispatch(getScheduleGroupDataStart())
    const scheduleGroupData = await scheduleMetadataGroupApi.calculatedGroupScheduleData({
      scheduleMetadataGroupId,
      scheduleMetadataGroupCalculatorDto: { filter }
    })
    dispatch(getScheduleGroupDataSuccess(scheduleGroupData))
  } catch (error) {
    dispatch(getScheduleGroupDataFailed({ error }))
  }
}

const createUpdatedScheduleGroup = (createScheduleMetadataGroupScheduleDataDto, params) => async dispatch => {
  try {
    dispatch(createScheduleGroupStart())
    await scheduleMetadataGroupApi.createUpdatedScheduleGroup({ createScheduleMetadataGroupScheduleDataDto })
    await dispatch(getScheduleGroupManagementList(params))
    dispatch(createScheduleGroupSuccess())
  } catch (error) {
    dispatch(createScheduleGroupFailed({ error }))
  }
}

const downloadScheduleManagementXML = (id) => async dispatch => {
  try {
    const response = await scheduleMetadataGroupApi.scheduleMetadataGroupDownloadXml({ id }, { responseType: 'blob' })
    dispatch(downloadScheduleManagementXMLSuccess())
    return response
  } catch (error) {
    dispatch(downloadScheduleManagementXMLFailed({ error }))
    return null
  }
}

const downloadScheduleManagementZIP = (id) => async dispatch => {
  try {
    const response = await scheduleMetadataGroupApi.scheduleMetadataGroupDownloadXmlZip({ id }, { responseType: 'blob' })
    dispatch(downloadScheduleManagementZIPSuccess())
    return response
  } catch (error) {
    dispatch(downloadScheduleManagementZIPFailed({ error }))
    return null
  }
}

const submitReportageToMEK = (scheduleMetadataGroupId) => async dispatch => {
  try {
    await scheduleMetadataGroupApi.sendScheduleGroupToMEK({ scheduleMetadataGroupId })
    dispatch(submitReportageToMEKSuccess(scheduleMetadataGroupId))
  } catch (error) {
    dispatch(submitReportageToMEKFailed({ error }))
  }
}

export {
  getScheduleGroupManagementList,
  downloadScheduleManagementXML,
  downloadScheduleManagementZIP,
  submitReportageToMEK,
  getCalculatedScheduleGroupData,
  createUpdatedScheduleGroup,
  setScheduleGroupModalStatus,
  clear
}

export default scheduleGroupManagementSlice.reducer
