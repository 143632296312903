"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PowerPlantApi = exports.PowerPlantApiFactory = exports.PowerPlantApiFp = exports.PowerPlantApiAxiosParamCreator = void 0;
var globalImportUrl = require("url");
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var base_1 = require("../base");
/**
 * PowerPlantApi - axios parameter creator
 * @export
 */
exports.PowerPlantApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activatePowerPlant: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    if (id === null || id === undefined) {
                        throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling activatePowerPlant.');
                    }
                    localVarPath = "/api/PowerPlant/{id}/Activate"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activatePowerPlantRow: function (powerPlantId, id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'powerPlantId' is not null or undefined
                    if (powerPlantId === null || powerPlantId === undefined) {
                        throw new base_1.RequiredError('powerPlantId', 'Required parameter powerPlantId was null or undefined when calling activatePowerPlantRow.');
                    }
                    // verify required parameter 'id' is not null or undefined
                    if (id === null || id === undefined) {
                        throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling activatePowerPlantRow.');
                    }
                    localVarPath = "/api/PowerPlant/{powerPlantId}/row/{id}/Activate"
                        .replace("{" + "powerPlantId" + "}", encodeURIComponent(String(powerPlantId)))
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {CreatePowerPlantIntervalDto} [createPowerPlantIntervalDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIntervalConfig: function (powerPlantId, createPowerPlantIntervalDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions, needsSerialization;
                return __generator(this, function (_a) {
                    // verify required parameter 'powerPlantId' is not null or undefined
                    if (powerPlantId === null || powerPlantId === undefined) {
                        throw new base_1.RequiredError('powerPlantId', 'Required parameter powerPlantId was null or undefined when calling createIntervalConfig.');
                    }
                    localVarPath = "/api/PowerPlant/{powerPlantId}/intervalConfiguredRows"
                        .replace("{" + "powerPlantId" + "}", encodeURIComponent(String(powerPlantId)));
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    needsSerialization = (typeof createPowerPlantIntervalDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
                    localVarRequestOptions.data = needsSerialization ? JSON.stringify(createPowerPlantIntervalDto !== undefined ? createPowerPlantIntervalDto : {}) : (createPowerPlantIntervalDto || "");
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {CreatePowerPlantDto} [createPowerPlantDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPowerPlant: function (createPowerPlantDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions, needsSerialization;
                return __generator(this, function (_a) {
                    localVarPath = "/api/PowerPlant";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    needsSerialization = (typeof createPowerPlantDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
                    localVarRequestOptions.data = needsSerialization ? JSON.stringify(createPowerPlantDto !== undefined ? createPowerPlantDto : {}) : (createPowerPlantDto || "");
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {CreatePowerPlantConfigurationDto} [createPowerPlantConfigurationDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPowerPlantRow: function (powerPlantId, createPowerPlantConfigurationDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions, needsSerialization;
                return __generator(this, function (_a) {
                    // verify required parameter 'powerPlantId' is not null or undefined
                    if (powerPlantId === null || powerPlantId === undefined) {
                        throw new base_1.RequiredError('powerPlantId', 'Required parameter powerPlantId was null or undefined when calling createPowerPlantRow.');
                    }
                    localVarPath = "/api/PowerPlant/{powerPlantId}/row"
                        .replace("{" + "powerPlantId" + "}", encodeURIComponent(String(powerPlantId)));
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    needsSerialization = (typeof createPowerPlantConfigurationDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
                    localVarRequestOptions.data = needsSerialization ? JSON.stringify(createPowerPlantConfigurationDto !== undefined ? createPowerPlantConfigurationDto : {}) : (createPowerPlantConfigurationDto || "");
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {CreatePowerPlantDto} [createPowerPlantDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicatePowerPlant: function (id, createPowerPlantDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions, needsSerialization;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    if (id === null || id === undefined) {
                        throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling duplicatePowerPlant.');
                    }
                    localVarPath = "/api/PowerPlant/{id}/Duplicate"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    needsSerialization = (typeof createPowerPlantDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
                    localVarRequestOptions.data = needsSerialization ? JSON.stringify(createPowerPlantDto !== undefined ? createPowerPlantDto : {}) : (createPowerPlantDto || "");
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {number} id
         * @param {UpdatePowerPlantIntervalDto} [updatePowerPlantIntervalDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editIntervalConfig: function (powerPlantId, id, updatePowerPlantIntervalDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions, needsSerialization;
                return __generator(this, function (_a) {
                    // verify required parameter 'powerPlantId' is not null or undefined
                    if (powerPlantId === null || powerPlantId === undefined) {
                        throw new base_1.RequiredError('powerPlantId', 'Required parameter powerPlantId was null or undefined when calling editIntervalConfig.');
                    }
                    // verify required parameter 'id' is not null or undefined
                    if (id === null || id === undefined) {
                        throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling editIntervalConfig.');
                    }
                    localVarPath = "/api/PowerPlant/{powerPlantId}/intervalConfiguredRows/{id}"
                        .replace("{" + "powerPlantId" + "}", encodeURIComponent(String(powerPlantId)))
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    needsSerialization = (typeof updatePowerPlantIntervalDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
                    localVarRequestOptions.data = needsSerialization ? JSON.stringify(updatePowerPlantIntervalDto !== undefined ? updatePowerPlantIntervalDto : {}) : (updatePowerPlantIntervalDto || "");
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {UpdatePowerPlantDto} [updatePowerPlantDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPowerPlant: function (id, updatePowerPlantDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions, needsSerialization;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    if (id === null || id === undefined) {
                        throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling editPowerPlant.');
                    }
                    localVarPath = "/api/PowerPlant/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    needsSerialization = (typeof updatePowerPlantDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
                    localVarRequestOptions.data = needsSerialization ? JSON.stringify(updatePowerPlantDto !== undefined ? updatePowerPlantDto : {}) : (updatePowerPlantDto || "");
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {number} id
         * @param {UpdatePowerPlantConfigurationDto} [updatePowerPlantConfigurationDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPowerPlantRow: function (powerPlantId, id, updatePowerPlantConfigurationDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions, needsSerialization;
                return __generator(this, function (_a) {
                    // verify required parameter 'powerPlantId' is not null or undefined
                    if (powerPlantId === null || powerPlantId === undefined) {
                        throw new base_1.RequiredError('powerPlantId', 'Required parameter powerPlantId was null or undefined when calling editPowerPlantRow.');
                    }
                    // verify required parameter 'id' is not null or undefined
                    if (id === null || id === undefined) {
                        throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling editPowerPlantRow.');
                    }
                    localVarPath = "/api/PowerPlant/{powerPlantId}/row/{id}"
                        .replace("{" + "powerPlantId" + "}", encodeURIComponent(String(powerPlantId)))
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    needsSerialization = (typeof updatePowerPlantConfigurationDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
                    localVarRequestOptions.data = needsSerialization ? JSON.stringify(updatePowerPlantConfigurationDto !== undefined ? updatePowerPlantConfigurationDto : {}) : (updatePowerPlantConfigurationDto || "");
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllActivePowerPlants: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/api/PowerPlant/Active";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} [profileId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllActivePowerPlantsForAdmin: function (profileId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/api/PowerPlant/ActiveForAdmin";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    if (profileId !== undefined) {
                        localVarQueryParameter['profileId'] = profileId;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} [name]
         * @param {string} [eic]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPowerPlant: function (name, eic, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/api/PowerPlant";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    if (name !== undefined) {
                        localVarQueryParameter['name'] = name;
                    }
                    if (eic !== undefined) {
                        localVarQueryParameter['eic'] = eic;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPowerPlantsWeatherForecastInformation: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/api/PowerPlant/PowerPlantsWeatherForecastInformation";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSelectablePowerPlants: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/api/PowerPlant/Selectable";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntervalConfigurations: function (powerPlantId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'powerPlantId' is not null or undefined
                    if (powerPlantId === null || powerPlantId === undefined) {
                        throw new base_1.RequiredError('powerPlantId', 'Required parameter powerPlantId was null or undefined when calling getIntervalConfigurations.');
                    }
                    localVarPath = "/api/PowerPlant/{powerPlantId}/intervals"
                        .replace("{" + "powerPlantId" + "}", encodeURIComponent(String(powerPlantId)));
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {number} intervalConfigurationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntervalConfiguredPowerPlantRows: function (powerPlantId, intervalConfigurationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'powerPlantId' is not null or undefined
                    if (powerPlantId === null || powerPlantId === undefined) {
                        throw new base_1.RequiredError('powerPlantId', 'Required parameter powerPlantId was null or undefined when calling getIntervalConfiguredPowerPlantRows.');
                    }
                    // verify required parameter 'intervalConfigurationId' is not null or undefined
                    if (intervalConfigurationId === null || intervalConfigurationId === undefined) {
                        throw new base_1.RequiredError('intervalConfigurationId', 'Required parameter intervalConfigurationId was null or undefined when calling getIntervalConfiguredPowerPlantRows.');
                    }
                    localVarPath = "/api/PowerPlant/{powerPlantId}/intervalConfiguredRows/{intervalConfigurationId}"
                        .replace("{" + "powerPlantId" + "}", encodeURIComponent(String(powerPlantId)))
                        .replace("{" + "intervalConfigurationId" + "}", encodeURIComponent(String(intervalConfigurationId)));
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPowerPlant: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    if (id === null || id === undefined) {
                        throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling getPowerPlant.');
                    }
                    localVarPath = "/api/PowerPlant/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPowerPlantConfigurationPositionInfo: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/api/PowerPlant/PowerPlantConfiguration/PositionInfo";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPowerPlantRow: function (powerPlantId, id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'powerPlantId' is not null or undefined
                    if (powerPlantId === null || powerPlantId === undefined) {
                        throw new base_1.RequiredError('powerPlantId', 'Required parameter powerPlantId was null or undefined when calling getPowerPlantRow.');
                    }
                    // verify required parameter 'id' is not null or undefined
                    if (id === null || id === undefined) {
                        throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling getPowerPlantRow.');
                    }
                    localVarPath = "/api/PowerPlant/{powerPlantId}/row/{id}"
                        .replace("{" + "powerPlantId" + "}", encodeURIComponent(String(powerPlantId)))
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPowerPlantRows: function (powerPlantId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'powerPlantId' is not null or undefined
                    if (powerPlantId === null || powerPlantId === undefined) {
                        throw new base_1.RequiredError('powerPlantId', 'Required parameter powerPlantId was null or undefined when calling getPowerPlantRows.');
                    }
                    localVarPath = "/api/PowerPlant/{powerPlantId}/row"
                        .replace("{" + "powerPlantId" + "}", encodeURIComponent(String(powerPlantId)));
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inactivatePowerPlant: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    if (id === null || id === undefined) {
                        throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling inactivatePowerPlant.');
                    }
                    localVarPath = "/api/PowerPlant/{id}/Inactivate"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inactivatePowerPlantRow: function (powerPlantId, id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'powerPlantId' is not null or undefined
                    if (powerPlantId === null || powerPlantId === undefined) {
                        throw new base_1.RequiredError('powerPlantId', 'Required parameter powerPlantId was null or undefined when calling inactivatePowerPlantRow.');
                    }
                    // verify required parameter 'id' is not null or undefined
                    if (id === null || id === undefined) {
                        throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling inactivatePowerPlantRow.');
                    }
                    localVarPath = "/api/PowerPlant/{powerPlantId}/row/{id}/Inactivate"
                        .replace("{" + "powerPlantId" + "}", encodeURIComponent(String(powerPlantId)))
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {UpdatePowerPlantWeatherForecastProviderInformationDto} [updatePowerPlantWeatherForecastProviderInformationDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWeatherForecast: function (id, updatePowerPlantWeatherForecastProviderInformationDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions, needsSerialization;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    if (id === null || id === undefined) {
                        throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling updateWeatherForecast.');
                    }
                    localVarPath = "/api/PowerPlant/{id}/UpdateWeatherForecast"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    needsSerialization = (typeof updatePowerPlantWeatherForecastProviderInformationDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
                    localVarRequestOptions.data = needsSerialization ? JSON.stringify(updatePowerPlantWeatherForecastProviderInformationDto !== undefined ? updatePowerPlantWeatherForecastProviderInformationDto : {}) : (updatePowerPlantWeatherForecastProviderInformationDto || "");
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * PowerPlantApi - functional programming interface
 * @export
 */
exports.PowerPlantApiFp = function (configuration) {
    return {
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activatePowerPlant: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.PowerPlantApiAxiosParamCreator(configuration).activatePowerPlant(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activatePowerPlantRow: function (powerPlantId, id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.PowerPlantApiAxiosParamCreator(configuration).activatePowerPlantRow(powerPlantId, id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {CreatePowerPlantIntervalDto} [createPowerPlantIntervalDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIntervalConfig: function (powerPlantId, createPowerPlantIntervalDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.PowerPlantApiAxiosParamCreator(configuration).createIntervalConfig(powerPlantId, createPowerPlantIntervalDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CreatePowerPlantDto} [createPowerPlantDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPowerPlant: function (createPowerPlantDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.PowerPlantApiAxiosParamCreator(configuration).createPowerPlant(createPowerPlantDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {CreatePowerPlantConfigurationDto} [createPowerPlantConfigurationDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPowerPlantRow: function (powerPlantId, createPowerPlantConfigurationDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.PowerPlantApiAxiosParamCreator(configuration).createPowerPlantRow(powerPlantId, createPowerPlantConfigurationDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {CreatePowerPlantDto} [createPowerPlantDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicatePowerPlant: function (id, createPowerPlantDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.PowerPlantApiAxiosParamCreator(configuration).duplicatePowerPlant(id, createPowerPlantDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {number} id
         * @param {UpdatePowerPlantIntervalDto} [updatePowerPlantIntervalDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editIntervalConfig: function (powerPlantId, id, updatePowerPlantIntervalDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.PowerPlantApiAxiosParamCreator(configuration).editIntervalConfig(powerPlantId, id, updatePowerPlantIntervalDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {UpdatePowerPlantDto} [updatePowerPlantDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPowerPlant: function (id, updatePowerPlantDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.PowerPlantApiAxiosParamCreator(configuration).editPowerPlant(id, updatePowerPlantDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {number} id
         * @param {UpdatePowerPlantConfigurationDto} [updatePowerPlantConfigurationDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPowerPlantRow: function (powerPlantId, id, updatePowerPlantConfigurationDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.PowerPlantApiAxiosParamCreator(configuration).editPowerPlantRow(powerPlantId, id, updatePowerPlantConfigurationDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllActivePowerPlants: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.PowerPlantApiAxiosParamCreator(configuration).getAllActivePowerPlants(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [profileId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllActivePowerPlantsForAdmin: function (profileId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.PowerPlantApiAxiosParamCreator(configuration).getAllActivePowerPlantsForAdmin(profileId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [name]
         * @param {string} [eic]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPowerPlant: function (name, eic, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.PowerPlantApiAxiosParamCreator(configuration).getAllPowerPlant(name, eic, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPowerPlantsWeatherForecastInformation: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.PowerPlantApiAxiosParamCreator(configuration).getAllPowerPlantsWeatherForecastInformation(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSelectablePowerPlants: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.PowerPlantApiAxiosParamCreator(configuration).getAllSelectablePowerPlants(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntervalConfigurations: function (powerPlantId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.PowerPlantApiAxiosParamCreator(configuration).getIntervalConfigurations(powerPlantId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {number} intervalConfigurationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntervalConfiguredPowerPlantRows: function (powerPlantId, intervalConfigurationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.PowerPlantApiAxiosParamCreator(configuration).getIntervalConfiguredPowerPlantRows(powerPlantId, intervalConfigurationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPowerPlant: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.PowerPlantApiAxiosParamCreator(configuration).getPowerPlant(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPowerPlantConfigurationPositionInfo: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.PowerPlantApiAxiosParamCreator(configuration).getPowerPlantConfigurationPositionInfo(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPowerPlantRow: function (powerPlantId, id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.PowerPlantApiAxiosParamCreator(configuration).getPowerPlantRow(powerPlantId, id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPowerPlantRows: function (powerPlantId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.PowerPlantApiAxiosParamCreator(configuration).getPowerPlantRows(powerPlantId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inactivatePowerPlant: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.PowerPlantApiAxiosParamCreator(configuration).inactivatePowerPlant(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inactivatePowerPlantRow: function (powerPlantId, id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.PowerPlantApiAxiosParamCreator(configuration).inactivatePowerPlantRow(powerPlantId, id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {UpdatePowerPlantWeatherForecastProviderInformationDto} [updatePowerPlantWeatherForecastProviderInformationDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWeatherForecast: function (id, updatePowerPlantWeatherForecastProviderInformationDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.PowerPlantApiAxiosParamCreator(configuration).updateWeatherForecast(id, updatePowerPlantWeatherForecastProviderInformationDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
/**
 * PowerPlantApi - factory interface
 * @export
 */
exports.PowerPlantApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activatePowerPlant: function (id, options) {
            return exports.PowerPlantApiFp(configuration).activatePowerPlant(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activatePowerPlantRow: function (powerPlantId, id, options) {
            return exports.PowerPlantApiFp(configuration).activatePowerPlantRow(powerPlantId, id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {CreatePowerPlantIntervalDto} [createPowerPlantIntervalDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIntervalConfig: function (powerPlantId, createPowerPlantIntervalDto, options) {
            return exports.PowerPlantApiFp(configuration).createIntervalConfig(powerPlantId, createPowerPlantIntervalDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CreatePowerPlantDto} [createPowerPlantDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPowerPlant: function (createPowerPlantDto, options) {
            return exports.PowerPlantApiFp(configuration).createPowerPlant(createPowerPlantDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {CreatePowerPlantConfigurationDto} [createPowerPlantConfigurationDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPowerPlantRow: function (powerPlantId, createPowerPlantConfigurationDto, options) {
            return exports.PowerPlantApiFp(configuration).createPowerPlantRow(powerPlantId, createPowerPlantConfigurationDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} id
         * @param {CreatePowerPlantDto} [createPowerPlantDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicatePowerPlant: function (id, createPowerPlantDto, options) {
            return exports.PowerPlantApiFp(configuration).duplicatePowerPlant(id, createPowerPlantDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {number} id
         * @param {UpdatePowerPlantIntervalDto} [updatePowerPlantIntervalDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editIntervalConfig: function (powerPlantId, id, updatePowerPlantIntervalDto, options) {
            return exports.PowerPlantApiFp(configuration).editIntervalConfig(powerPlantId, id, updatePowerPlantIntervalDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} id
         * @param {UpdatePowerPlantDto} [updatePowerPlantDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPowerPlant: function (id, updatePowerPlantDto, options) {
            return exports.PowerPlantApiFp(configuration).editPowerPlant(id, updatePowerPlantDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {number} id
         * @param {UpdatePowerPlantConfigurationDto} [updatePowerPlantConfigurationDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPowerPlantRow: function (powerPlantId, id, updatePowerPlantConfigurationDto, options) {
            return exports.PowerPlantApiFp(configuration).editPowerPlantRow(powerPlantId, id, updatePowerPlantConfigurationDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllActivePowerPlants: function (options) {
            return exports.PowerPlantApiFp(configuration).getAllActivePowerPlants(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} [profileId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllActivePowerPlantsForAdmin: function (profileId, options) {
            return exports.PowerPlantApiFp(configuration).getAllActivePowerPlantsForAdmin(profileId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [name]
         * @param {string} [eic]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPowerPlant: function (name, eic, options) {
            return exports.PowerPlantApiFp(configuration).getAllPowerPlant(name, eic, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPowerPlantsWeatherForecastInformation: function (options) {
            return exports.PowerPlantApiFp(configuration).getAllPowerPlantsWeatherForecastInformation(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSelectablePowerPlants: function (options) {
            return exports.PowerPlantApiFp(configuration).getAllSelectablePowerPlants(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntervalConfigurations: function (powerPlantId, options) {
            return exports.PowerPlantApiFp(configuration).getIntervalConfigurations(powerPlantId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {number} intervalConfigurationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntervalConfiguredPowerPlantRows: function (powerPlantId, intervalConfigurationId, options) {
            return exports.PowerPlantApiFp(configuration).getIntervalConfiguredPowerPlantRows(powerPlantId, intervalConfigurationId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPowerPlant: function (id, options) {
            return exports.PowerPlantApiFp(configuration).getPowerPlant(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPowerPlantConfigurationPositionInfo: function (options) {
            return exports.PowerPlantApiFp(configuration).getPowerPlantConfigurationPositionInfo(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPowerPlantRow: function (powerPlantId, id, options) {
            return exports.PowerPlantApiFp(configuration).getPowerPlantRow(powerPlantId, id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPowerPlantRows: function (powerPlantId, options) {
            return exports.PowerPlantApiFp(configuration).getPowerPlantRows(powerPlantId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inactivatePowerPlant: function (id, options) {
            return exports.PowerPlantApiFp(configuration).inactivatePowerPlant(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inactivatePowerPlantRow: function (powerPlantId, id, options) {
            return exports.PowerPlantApiFp(configuration).inactivatePowerPlantRow(powerPlantId, id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} id
         * @param {UpdatePowerPlantWeatherForecastProviderInformationDto} [updatePowerPlantWeatherForecastProviderInformationDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWeatherForecast: function (id, updatePowerPlantWeatherForecastProviderInformationDto, options) {
            return exports.PowerPlantApiFp(configuration).updateWeatherForecast(id, updatePowerPlantWeatherForecastProviderInformationDto, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * PowerPlantApi - object-oriented interface
 * @export
 * @class PowerPlantApi
 * @extends {BaseAPI}
 */
var PowerPlantApi = /** @class */ (function (_super) {
    __extends(PowerPlantApi, _super);
    function PowerPlantApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {PowerPlantApiActivatePowerPlantRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PowerPlantApi
     */
    PowerPlantApi.prototype.activatePowerPlant = function (requestParameters, options) {
        var _this = this;
        return exports.PowerPlantApiFp(this.configuration).activatePowerPlant(requestParameters.id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {PowerPlantApiActivatePowerPlantRowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PowerPlantApi
     */
    PowerPlantApi.prototype.activatePowerPlantRow = function (requestParameters, options) {
        var _this = this;
        return exports.PowerPlantApiFp(this.configuration).activatePowerPlantRow(requestParameters.powerPlantId, requestParameters.id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {PowerPlantApiCreateIntervalConfigRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PowerPlantApi
     */
    PowerPlantApi.prototype.createIntervalConfig = function (requestParameters, options) {
        var _this = this;
        return exports.PowerPlantApiFp(this.configuration).createIntervalConfig(requestParameters.powerPlantId, requestParameters.createPowerPlantIntervalDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {PowerPlantApiCreatePowerPlantRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PowerPlantApi
     */
    PowerPlantApi.prototype.createPowerPlant = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return exports.PowerPlantApiFp(this.configuration).createPowerPlant(requestParameters.createPowerPlantDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {PowerPlantApiCreatePowerPlantRowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PowerPlantApi
     */
    PowerPlantApi.prototype.createPowerPlantRow = function (requestParameters, options) {
        var _this = this;
        return exports.PowerPlantApiFp(this.configuration).createPowerPlantRow(requestParameters.powerPlantId, requestParameters.createPowerPlantConfigurationDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {PowerPlantApiDuplicatePowerPlantRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PowerPlantApi
     */
    PowerPlantApi.prototype.duplicatePowerPlant = function (requestParameters, options) {
        var _this = this;
        return exports.PowerPlantApiFp(this.configuration).duplicatePowerPlant(requestParameters.id, requestParameters.createPowerPlantDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {PowerPlantApiEditIntervalConfigRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PowerPlantApi
     */
    PowerPlantApi.prototype.editIntervalConfig = function (requestParameters, options) {
        var _this = this;
        return exports.PowerPlantApiFp(this.configuration).editIntervalConfig(requestParameters.powerPlantId, requestParameters.id, requestParameters.updatePowerPlantIntervalDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {PowerPlantApiEditPowerPlantRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PowerPlantApi
     */
    PowerPlantApi.prototype.editPowerPlant = function (requestParameters, options) {
        var _this = this;
        return exports.PowerPlantApiFp(this.configuration).editPowerPlant(requestParameters.id, requestParameters.updatePowerPlantDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {PowerPlantApiEditPowerPlantRowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PowerPlantApi
     */
    PowerPlantApi.prototype.editPowerPlantRow = function (requestParameters, options) {
        var _this = this;
        return exports.PowerPlantApiFp(this.configuration).editPowerPlantRow(requestParameters.powerPlantId, requestParameters.id, requestParameters.updatePowerPlantConfigurationDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PowerPlantApi
     */
    PowerPlantApi.prototype.getAllActivePowerPlants = function (options) {
        var _this = this;
        return exports.PowerPlantApiFp(this.configuration).getAllActivePowerPlants(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {PowerPlantApiGetAllActivePowerPlantsForAdminRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PowerPlantApi
     */
    PowerPlantApi.prototype.getAllActivePowerPlantsForAdmin = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return exports.PowerPlantApiFp(this.configuration).getAllActivePowerPlantsForAdmin(requestParameters.profileId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {PowerPlantApiGetAllPowerPlantRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PowerPlantApi
     */
    PowerPlantApi.prototype.getAllPowerPlant = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return exports.PowerPlantApiFp(this.configuration).getAllPowerPlant(requestParameters.name, requestParameters.eic, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PowerPlantApi
     */
    PowerPlantApi.prototype.getAllPowerPlantsWeatherForecastInformation = function (options) {
        var _this = this;
        return exports.PowerPlantApiFp(this.configuration).getAllPowerPlantsWeatherForecastInformation(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PowerPlantApi
     */
    PowerPlantApi.prototype.getAllSelectablePowerPlants = function (options) {
        var _this = this;
        return exports.PowerPlantApiFp(this.configuration).getAllSelectablePowerPlants(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {PowerPlantApiGetIntervalConfigurationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PowerPlantApi
     */
    PowerPlantApi.prototype.getIntervalConfigurations = function (requestParameters, options) {
        var _this = this;
        return exports.PowerPlantApiFp(this.configuration).getIntervalConfigurations(requestParameters.powerPlantId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {PowerPlantApiGetIntervalConfiguredPowerPlantRowsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PowerPlantApi
     */
    PowerPlantApi.prototype.getIntervalConfiguredPowerPlantRows = function (requestParameters, options) {
        var _this = this;
        return exports.PowerPlantApiFp(this.configuration).getIntervalConfiguredPowerPlantRows(requestParameters.powerPlantId, requestParameters.intervalConfigurationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {PowerPlantApiGetPowerPlantRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PowerPlantApi
     */
    PowerPlantApi.prototype.getPowerPlant = function (requestParameters, options) {
        var _this = this;
        return exports.PowerPlantApiFp(this.configuration).getPowerPlant(requestParameters.id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PowerPlantApi
     */
    PowerPlantApi.prototype.getPowerPlantConfigurationPositionInfo = function (options) {
        var _this = this;
        return exports.PowerPlantApiFp(this.configuration).getPowerPlantConfigurationPositionInfo(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {PowerPlantApiGetPowerPlantRowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PowerPlantApi
     */
    PowerPlantApi.prototype.getPowerPlantRow = function (requestParameters, options) {
        var _this = this;
        return exports.PowerPlantApiFp(this.configuration).getPowerPlantRow(requestParameters.powerPlantId, requestParameters.id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {PowerPlantApiGetPowerPlantRowsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PowerPlantApi
     */
    PowerPlantApi.prototype.getPowerPlantRows = function (requestParameters, options) {
        var _this = this;
        return exports.PowerPlantApiFp(this.configuration).getPowerPlantRows(requestParameters.powerPlantId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {PowerPlantApiInactivatePowerPlantRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PowerPlantApi
     */
    PowerPlantApi.prototype.inactivatePowerPlant = function (requestParameters, options) {
        var _this = this;
        return exports.PowerPlantApiFp(this.configuration).inactivatePowerPlant(requestParameters.id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {PowerPlantApiInactivatePowerPlantRowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PowerPlantApi
     */
    PowerPlantApi.prototype.inactivatePowerPlantRow = function (requestParameters, options) {
        var _this = this;
        return exports.PowerPlantApiFp(this.configuration).inactivatePowerPlantRow(requestParameters.powerPlantId, requestParameters.id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {PowerPlantApiUpdateWeatherForecastRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PowerPlantApi
     */
    PowerPlantApi.prototype.updateWeatherForecast = function (requestParameters, options) {
        var _this = this;
        return exports.PowerPlantApiFp(this.configuration).updateWeatherForecast(requestParameters.id, requestParameters.updatePowerPlantWeatherForecastProviderInformationDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PowerPlantApi;
}(base_1.BaseAPI));
exports.PowerPlantApi = PowerPlantApi;
