import store from 'store'
import { Role } from 'models/enum'

export const ROLES = Object.values(Role)

export const hasPermission = (requiredRoles, checkSysAdmin = false) => {
  if (!requiredRoles || !Array.isArray(requiredRoles)) {
    return false
  }
  const { role, sessionCompanyProfileId } = store.getState()?.auth

  // special role checking case: If a company profile is selected, permission limited to admin
  if (checkSysAdmin && role === Role.SysAdmin && sessionCompanyProfileId) {
    return requiredRoles?.includes(Role.Admin)
  }
  return requiredRoles.includes(role)
}

export const isInvalidRoleName = (requiredRoles) => {
  if (!requiredRoles) {
    return false
  }
  return requiredRoles.find(role => !ROLES.includes(role))
}

export const requiredRoleValidator = (props, propName) => {
  if (isInvalidRoleName(props[propName])) {
    throw new Error('Invalid role name(s).')
  }
}
