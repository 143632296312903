import { Menu } from 'antd'
import React, { useEffect, useState } from 'react'
import styles from './Menu.module.scss'
import { history } from 'router'
import { useResize } from 'hooks'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const staticRouteConfig = ['services', 'about', 'references', 'contact']

const StaticMenuComponent = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const [selectedKeys, setSelectedkeys] = useState([])
  const { width } = useResize()
  const isMobile = width < 992

  useEffect(() => {
    if (location.hash) {
      setSelectedkeys([location.hash.replace('#', '')])
    }
  }, [location])

  return (
    <Menu
      onClick={(e) => setSelectedkeys([e.key])}
      selectedKeys={selectedKeys}
      forceSubMenuRender
      className={styles.menu}
      mode={isMobile ? 'inline' : 'horizontal'}
    >
      {staticRouteConfig.map((name) => (
        <Menu.Item
          key={name}
          className={styles.menuItem}
          onClick={() => history.push(`/#${name}`)}
        >
          {t(`navigation.${name}`)}
        </Menu.Item>
      ))}
    </Menu>
  )
}

export default StaticMenuComponent
