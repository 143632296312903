export const findParentRowById = (dataSections, id) => {
  return dataSections
    .reduce((acc, { dataRows }) => [...acc, ...dataRows], [])
    .find(row => row?.children?.some(({ technicalId }) => technicalId === id))
}

export const findRowById = (dataSections, id) => {
  return dataSections
    .reduce((acc, { dataRows }) => [...acc, ...dataRows], [])
    .reduce((acc, row) => [...acc, row, ...(row.children ?? [])], [])
    .find(({ technicalId }) => technicalId === id)
}
