import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import { useMeta } from 'hooks'

const PublicRoute = ({ component: Component, restricted, titleKey, ...rest }) => {
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn)

  useMeta(titleKey)

  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn && restricted ? (
          <Redirect to="/" />
        ) : (
          <Component {...props} />
      )}
    />
  )
}

PublicRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  restricted: PropTypes.bool,
  titleKey: PropTypes.string.isRequired
}

export default PublicRoute
