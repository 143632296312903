import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import { useMeta } from 'hooks'
import PrivateLayout from 'components/layout/PrivateLayout/PrivateLayoutComponent'
import { hasPermission, requiredRoleValidator } from 'services/permission'

const PrivateRoute = ({ component, titleKey, defaultParams, requiredRole, ...attrs }) => {
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn)

  useMeta(titleKey)

  if ((isLoggedIn && hasPermission(requiredRole, true))) {
    return (
      <PrivateLayout>
        <Route {...attrs} component={component} />
      </PrivateLayout>
    )
  }

  return <Redirect to={{ pathname: '/' }} />
}

export default PrivateRoute

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  titleKey: PropTypes.string.isRequired,
  defaultParams: PropTypes.object,
  requiredRole: requiredRoleValidator
}
