import { get } from 'lodash'
import store from 'store'
import i18n from 'i18n'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'

dayjs.extend(weekday)
dayjs.extend(localeData)

export const monthStringFormat = 'YYYY. MMMM'
export const dateStringFormat = 'YYYY.MM.DD.'
export const dateTimeStringFormat = 'YYYY.MM.DD. HH:mm'
export const dateTimeSecStringFormat = 'YYYY.MM.DD. HH:mm:ss'
export const chartDateTimeStringFormat = 'yyyy MMM. dd. HH:mm'
export const shortDateStringFormat = 'YYYY. MMM. DD'

export const utcRegexFormat = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}Z$/

const getLanguageCode = () => {
  const language = store.getState().language.currentLang
  return language === 'hu' ? 'hu-HU' : 'en-GB'
}

export const numberFormat = (value) => new Intl.NumberFormat(getLanguageCode(), {
  style: 'decimal',
  useGrouping: false,
  maximumFractionDigits: 10
}).format(value)

export const dateFormat = (value) => new Intl.DateTimeFormat(getLanguageCode(), {
  year: 'numeric',
  month: 'short',
  day: 'numeric'
}).format(value)

export const monthFormat = (value) => new Intl.DateTimeFormat(getLanguageCode(), {
  year: 'numeric',
  month: 'long'
}).format(value)

export const dateTimeFormat = (value) => new Intl.DateTimeFormat(getLanguageCode(), {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  hour12: false
}).format(value)

export const dateTimeSecFormat = (value) => new Intl.DateTimeFormat(getLanguageCode(), {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: false
}).format(value)

export const timeFormat = (value) => new Intl.DateTimeFormat(getLanguageCode(), {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false
}).format(value)

export const boolFormat = (value) => value ? i18n.t('general.yes') : i18n.t('general.no')

export const autoFormat = (value, fieldOption) => {
  if (value === undefined || value === null) {
    return null
  }

  switch (fieldOption.type) {
  case 'string':
    return value
  case 'number':
    return numberFormat(value)
  case 'date':
    return dateFormat(value)
  case 'month':
    return monthFormat(value)
  case 'datetime':
    return dateTimeFormat(value)
  case 'datetimesec':
    return value ? dateTimeSecFormat(value) : value
  case 'time':
    return timeFormat(value)
  case 'bool': {
    return boolFormat(fieldOption.negate ? !value : value)
  }
  case 'array': {
    const objectProp = fieldOption.objectProp
    if (!objectProp) {
      return '<property objectProp is required>'
    }
    if (!Array.isArray(value)) {
      return '<value is not an array>'
    }
    return value.map(item => get(item, objectProp)).join(', ')
  }
  default:
    return value
  }
}

export const formatUTCDateForUpload = (date) => {
  if (!date || !dayjs(date).isValid()) {
    return
  }
  const formattedDate = dayjs(date)?.hour(0).minute(0).second(0).format('YYYY-MM-DDT00:00:00')
  return `${formattedDate}Z`
}

export const formatUTCDate = (date) => {
  if (!date || !dayjs(date).isValid()) {
    return
  }
  dayjs.extend(utc)

  return dayjs(date)?.utcOffset(0, true).utc().hour(0).minute(0).second(0).format()
}

export const formatUTCTime = (date) => {
  if (!date || !dayjs(date).isValid()) {
    return
  }
  dayjs.extend(utc)
  return dayjs(date)?.utc().format()
}
