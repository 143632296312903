import React from 'react'
import styles from './Footer.module.scss'
import { history } from 'router'
import { useTranslation } from 'react-i18next'
import { staticRouteConfig } from '../Menu/StaticMenuComponent'
import LanguageSelectorComponent from '../LanguageSelector/LanguageSelectorComponent'

const FooterComponent = () => {
  const { t } = useTranslation()

  return (
    <footer className={styles.footer}>
      <h4 className={styles.brand}>&#169; METEO</h4>
      <nav>
        <ul className={styles.menu}>
          {staticRouteConfig.map((name) => (
            <li key={`footer-${name}`} className={styles.menuItem}>
              <a
                href={`#${name}`}
                className={styles.footerLink}
                onClick={() => history.push(`/#${name}`)}
              >
                {t(`navigation.${name}`)}
              </a>
            </li>
          ))}
          <li>
            <LanguageSelectorComponent />
          </li>
        </ul>
      </nav>
    </footer>
  )
}

export default FooterComponent
