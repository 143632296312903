import { useMemo } from 'react'

const useWindowOpen = (title, options = { width: 600, height: 400 }) => {
  const windowOptions = useMemo(() => {
    const { outerWidth, outerHeight, screenX, screenY } = window
    const left = outerWidth / 2 + screenX - (options.width / 2) // center x position
    const top = outerHeight / 2 + screenY - (options.height / 2) // center y position

    return Object.entries({ ...options, left, top })
      .map(entry => entry.join('='))
      .join(', ')
  }, [options])

  const openWindowWithUrl = (url) => {
    const browserWindow = window.open(url, '', windowOptions)
    browserWindow.document.title = title
  }

  const openWindowWithHtml = (html) => {
    const browserWindow = window.open('', '', windowOptions)
    browserWindow.document.body.innerHTML = html
    browserWindow.document.title = title
  }

  return {
    openWindowWithUrl,
    openWindowWithHtml
  }
}

export default useWindowOpen
