import { createSlice } from '@reduxjs/toolkit'
import { analysisApi } from 'services/api'

const initialState = {
  productionData: null,
  isLoading: false,
  error: null
}

const productionDataSlice = createSlice({
  name: 'productiondata',
  initialState,
  reducers: {
    getProductionDataStart (state) {
      state.error = null
      state.isLoading = true
    },
    getProductionDataSuccess (state, { payload }) {
      state.productionData = payload.productionData
      state.error = null
      state.isLoading = false
    },
    getProductionDataFailed (state, { payload }) {
      state.productionData = null
      state.error = payload.error
      state.isLoading = false
    },
    downloadProductionDataFileSuccess (state) {
      state.error = null
    },
    downloadProductionDataFileFailed (state, { payload }) {
      state.error = payload.error
    },
    clear (state) {
      state.productionData = null
    }
  }
})

const {
  getProductionDataStart,
  getProductionDataSuccess,
  getProductionDataFailed,
  downloadProductionDataFileSuccess,
  downloadProductionDataFileFailed,
  clear
} = productionDataSlice.actions

const getProductionData = (powerPlantId, targetDate, provider) => async dispatch => {
  try {
    dispatch(getProductionDataStart())
    const productionData = await analysisApi.getProductionData({ powerPlantId, targetDate, provider })
    dispatch(getProductionDataSuccess(productionData))
  } catch (error) {
    dispatch(getProductionDataFailed({ error }))
  }
}

const downloadProductionDataFile = (powerPlantId, targetDate, provider) => async dispatch => {
  try {
    const productionDataFile = await analysisApi.exportProductionDataCsv({
      powerPlantId, targetDate, provider
    }, {
      responseType: 'blob'
    })
    dispatch(downloadProductionDataFileSuccess())
    return productionDataFile
  } catch (error) {
    dispatch(downloadProductionDataFileFailed({ error }))
    return null
  }
}

export {
  getProductionData,
  downloadProductionDataFile,
  clear
}

export default productionDataSlice.reducer
