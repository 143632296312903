import { createSelector, createSlice } from '@reduxjs/toolkit'
import { estimateApi } from 'services/api'
import { getComparedStatuses } from './estimate.util'
import { PrognosisStatus } from 'models/enum'

const initialState = {
  estimateList: [],
  isListLoading: false,
  runningEstimates: [],
  error: null
}

const estimateSlice = createSlice({
  name: 'estimate',
  initialState,
  reducers: {
    getEstimateListStart (state) {
      state.error = null
      state.isListLoading = true
    },
    getEstimateListSuccess (state, { payload }) {
      state.estimateList = payload
      state.error = null
      state.isListLoading = false
    },
    getEstimateListFailed (state, { payload }) {
      state.estimateList = []
      state.error = payload.error
      state.isListLoading = false
    },
    runOneEstimateStart (state, { payload }) {
      state.runningEstimates = [payload.powerPlantName]
      state.estimateList = state.estimateList.map(item => {
        if (item.powerPlantId === payload.powerPlantId) {
          return {
            ...item,
            prognosisStatus: PrognosisStatus.Run
          }
        }
        return item
      })
    },
    runOneEstimateSuccess (state) {
      state.error = null
      state.runningEstimates = []
    },
    runOneEstimateFailed (state, { payload }) {
      state.error = payload.error
      state.runningEstimates = []
      state.estimateList = state.estimateList.map(item => {
        if (payload.powerPlant.powerPlantId === item.powerPlantId &&
          item.prognosisStatus === PrognosisStatus.Run) {
          return {
            ...item,
            prognosisStatus: PrognosisStatus.NewData
          }
        }
        return item
      })
    },
    runAllEstimateStart (state, { payload }) {
      state.runningEstimates = payload
      state.estimateList = state.estimateList.map(item => {
        if (item.prognosisStatus === PrognosisStatus.NewData) {
          return {
            ...item,
            prognosisStatus: PrognosisStatus.Run
          }
        }
        return item
      })
    },
    runAllEstimateSuccess (state) {
      state.error = null
      state.runningEstimates = []
    },
    runAllEstimateFailed (state, { payload }) {
      state.error = payload.error
      state.runningEstimates = []
      const { objectDetail } = payload.customError
      const failedPowerPlantIds = objectDetail?.map(({ id }) => id)
      state.estimateList = state.estimateList.map(item => {
        if (failedPowerPlantIds.includes(item.powerPlantId) &&
          item.prognosisStatus === PrognosisStatus.Run) {
          return {
            ...item,
            prognosisStatus: PrognosisStatus.NewData
          }
        }
        return item
      })
    },
    clearFinishedEstimates (state) {
      state.estimateList = state.estimateList.map(item => {
        if (item.prognosisStatus === PrognosisStatus.Ready) {
          return {
            ...item,
            messageShowed: true
          }
        }
        return item
      })
    },
    clear (state) {
      state.estimateList = []
      state.runningEstimates = []
      state.error = null
    }
  }
})

const {
  getEstimateListStart,
  getEstimateListSuccess,
  getEstimateListFailed,
  runOneEstimateStart,
  runOneEstimateSuccess,
  runOneEstimateFailed,
  runAllEstimateStart,
  runAllEstimateSuccess,
  runAllEstimateFailed,
  clearFinishedEstimates,
  clear
} = estimateSlice.actions

const getEstimateList = (isDayAhead) => async (dispatch, getState) => {
  try {
    dispatch(getEstimateListStart())
    const estimateList = await estimateApi.getColoredPowerPlantsEstimateInfo({ isDayAhead })
    const comparedEstimateList = getComparedStatuses(getState().estimate.estimateList, estimateList)
    dispatch(getEstimateListSuccess(comparedEstimateList))
  } catch (error) {
    dispatch(getEstimateListFailed({ error }))
  }
}

const runOneEstimate = ({ powerPlant, isDayAhead }) => async dispatch => {
  const { powerPlantId } = powerPlant
  const automaticRunEstimateDto = { powerPlantId, isDayAhead }
  try {
    dispatch(runOneEstimateStart(powerPlant))
    await estimateApi.runEstimateAutomatically({ automaticRunEstimateDto })
    dispatch(runOneEstimateSuccess(powerPlant))
    return true
  } catch (error) {
    dispatch(getEstimateList(isDayAhead))
    dispatch(runOneEstimateFailed({ error, powerPlant }))
    return false
  }
}

const runAllEstimate = (estimateDayAheadDto) => async (dispatch, getState) => {
  const { isDayAhead } = estimateDayAheadDto
  try {
    const powerPlantNameList = getState().estimate.estimateList
      .filter(({ prognosisStatus }) => prognosisStatus === PrognosisStatus.NewData)
      .map(({ powerPlantName }) => powerPlantName)

    dispatch(runAllEstimateStart(powerPlantNameList))
    await estimateApi.runAllEstimateAutomatically({ estimateDayAheadDto })
    dispatch(runAllEstimateSuccess())
    return
  } catch (error) {
    dispatch(getEstimateList(isDayAhead))
    dispatch(runAllEstimateFailed({ customError: error }))
    return error.objectDetail
  }
}

const selectRunningEstimates = createSelector(
  store => store.estimate.estimateList,
  estimates => estimates.some(estimate => estimate.prognosisStatus === PrognosisStatus.Run)
)

const selectNewDataList = createSelector(
  store => store.estimate.estimateList,
  estimates => estimates.some(estimate => estimate.prognosisStatus === PrognosisStatus.NewData)
)

const selectFinishedEstimates = createSelector(
  store => store.estimate.estimateList,
  estimates => estimates
    .filter(({ prognosisStatus, messageShowed }) => (
      prognosisStatus === PrognosisStatus.Ready && messageShowed === undefined)
    )
    .map(({ powerPlantName }) => powerPlantName)
)

export {
  getEstimateList,
  runOneEstimate,
  runAllEstimate,
  selectRunningEstimates,
  selectNewDataList,
  selectFinishedEstimates,
  clearFinishedEstimates,
  clear
}

export default estimateSlice.reducer
