import { createSelector, createSlice } from '@reduxjs/toolkit'
import { powerPlantGroupEstimateApi, predictionReportageApi } from 'services/api'
import { getComparedStatuses } from './estimate.util'
import { PrognosisStatus } from 'models/enum'

const initialState = {
  estimateList: [],
  isListLoading: false,
  runningEstimates: [],
  prognosisChartData: [],
  isChartLoading: false,
  error: null
}

const estimateGroupedSlice = createSlice({
  name: 'estimateGrouped',
  initialState,
  reducers: {
    getEstimateListStart (state) {
      state.error = null
      state.isListLoading = true
    },
    getEstimateListSuccess (state, { payload }) {
      state.estimateList = payload
      state.error = null
      state.isListLoading = false
    },
    getEstimateListFailed (state, { payload }) {
      state.estimateList = []
      state.error = payload.error
      state.isListLoading = false
    },
    runOneEstimateStart (state, { payload }) {
      state.runningEstimates = [payload.powerPlantGroupName]
      state.estimateList = state.estimateList.map((item) => {
        if (item.powerPlantGroupId === payload.powerPlantGroupId) {
          return {
            ...item,
            prognosisStatus: PrognosisStatus.Run
          }
        }
        return item
      })
    },
    runOneEstimateSuccess (state) {
      state.error = null
      state.runningEstimates = []
    },
    runOneEstimateFailed (state, { payload }) {
      state.error = payload.error
      state.runningEstimates = []
      state.estimateList = state.estimateList.map((item) => {
        if (
          payload.powerPlant.powerPlantGroupId === item.powerPlantGroupId &&
          item.prognosisStatus === PrognosisStatus.Run
        ) {
          return {
            ...item,
            prognosisStatus: PrognosisStatus.NewData
          }
        }
        return item
      })
    },
    getPrognosisGroupedChartDataStart (state, { payload }) {
      state.prognosisChartData = []
      state.error = null
      state.isChartLoading = true
    },
    getPrognosisGroupedChartDataSuccess (state, { payload }) {
      state.prognosisChartData = payload
      state.error = null
      state.isChartLoading = false
    },
    getPrognosisGroupedChartDataFailed (state, { payload }) {
      state.prognosisChartData = []
      state.error = payload.error
      state.isChartLoading = false
    },
    clearFinishedEstimates (state) {
      state.estimateList = state.estimateList.map((item) => {
        if (item.prognosisStatus === PrognosisStatus.Ready) {
          return {
            ...item,
            messageShowed: true
          }
        }
        return item
      })
    },
    clear (state) {
      return {
        ...initialState
      }
    }
  }
})

const {
  getEstimateListStart,
  getEstimateListSuccess,
  getEstimateListFailed,
  runOneEstimateStart,
  runOneEstimateSuccess,
  runOneEstimateFailed,
  getPrognosisGroupedChartDataStart,
  getPrognosisGroupedChartDataSuccess,
  getPrognosisGroupedChartDataFailed,
  clearFinishedEstimates,
  clear
} = estimateGroupedSlice.actions

const getEstimateList = (isDayAhead) => async (dispatch, getState) => {
  try {
    dispatch(getEstimateListStart())
    const estimateList = await powerPlantGroupEstimateApi.getColoredPowerPlantGroupsEstimateInfo(
      { isDayAhead }
    )
    const comparedEstimateList = getComparedStatuses(
      getState().estimateGrouped.estimateList,
      estimateList,
      'powerPlantGroupId'
    )
    dispatch(getEstimateListSuccess(comparedEstimateList))
  } catch (error) {
    dispatch(getEstimateListFailed({ error }))
  }
}

const runOneEstimate = ({ powerPlant, isDayAhead }) => async (dispatch) => {
  const { powerPlantGroupId } = powerPlant
  const automaticRunPowerPlantGroupEstimateDto = {
    powerPlantGroupId,
    isDayAhead
  }
  try {
    dispatch(runOneEstimateStart(powerPlant))
    await powerPlantGroupEstimateApi.runPowerPlantGroupEstimateAutomatically({
      automaticRunPowerPlantGroupEstimateDto
    })
    dispatch(runOneEstimateSuccess(powerPlant))
    return true
  } catch (error) {
    dispatch(getEstimateList(isDayAhead))
    dispatch(runOneEstimateFailed({ error, powerPlant }))
    return false
  }
}

const getPrognosisGroupedChartData = (powerPlantGroupEstimateId) => async dispatch => {
  try {
    dispatch(getPrognosisGroupedChartDataStart())
    const chartData = await predictionReportageApi.getPowerPlantGroupEstimateGraphReportage({ powerPlantGroupEstimateId })
    dispatch(getPrognosisGroupedChartDataSuccess(chartData))
  } catch (error) {
    dispatch(getPrognosisGroupedChartDataFailed({ error }))
  }
}

const selectRunningEstimates = createSelector(
  store => store.estimateGrouped.estimateList,
  estimates => estimates.some((estimate) => estimate.prognosisStatus === PrognosisStatus.Run)
)

const selectFinishedEstimates = createSelector(
  (store) => store.estimateGrouped.estimateList,
  (estimates) =>
    estimates
      .filter(
        ({ prognosisStatus, messageShowed }) =>
          prognosisStatus === PrognosisStatus.Ready &&
          messageShowed === undefined
      )
      .map(({ powerPlantGroupName }) => powerPlantGroupName)
)

export {
  getEstimateList,
  runOneEstimate,
  selectRunningEstimates,
  selectFinishedEstimates,
  clearFinishedEstimates,
  getPrognosisGroupedChartData,
  clear
}

export default estimateGroupedSlice.reducer
