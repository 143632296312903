import { createSlice } from '@reduxjs/toolkit'
import { userApi } from 'services/api'
import { ModalStatus } from 'models/enum'

const initialState = {
  userList: [],
  user: {},
  userModalStatus: ModalStatus.Hidden,
  isListLoading: false,
  isEntityLoading: false,
  error: null
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUserListStart (state) {
      state.isListLoading = true
      state.error = null
    },
    getUserListSuccess (state, { payload }) {
      state.isListLoading = false
      state.error = null
      state.userList = payload
    },
    getUserListFailed (state, { payload }) {
      state.isListLoading = false
      state.error = payload.error
      state.userList = []
    },
    getUserSuccess (state, { payload }) {
      state.user = payload
      state.error = null
      state.isEntityLoading = false
    },
    getUserFailed (state, { payload }) {
      state.user = {}
      state.error = payload.error
      state.isEntityLoading = false
    },
    createUserSuccess (state) {
      state.user = {}
      state.error = null
      state.userModalStatus = ModalStatus.Hidden
      state.isEntityLoading = false
    },
    createUserFailed (state, { payload }) {
      state.error = payload.error
      state.isEntityLoading = false
    },
    editUserSuccess (state) {
      state.user = {}
      state.error = null
      state.userModalStatus = ModalStatus.Hidden
      state.isEntityLoading = false
    },
    editUserFailed (state, { payload }) {
      state.error = payload.error
      state.isEntityLoading = false
    },
    inactivateUserSuccess (state) {
      state.error = null
    },
    inactivateUserFailed (state, { payload }) {
      state.error = payload.error
    },
    activateUserSuccess (state) {
      state.error = null
    },
    activateUserFailed (state, { payload }) {
      state.error = payload.error
    },
    setUserModalStatus (state, { payload }) {
      state.userModalStatus = payload
      if (payload === ModalStatus.Hidden) {
        state.user = {}
      }
    },
    setUserLoading (state) {
      state.isEntityLoading = true
    },
    clear (state) {
      state.userList = []
      state.user = {}
      state.userModalStatus = ModalStatus.Hidden
      state.error = null
    }
  }
})

const {
  getUserListStart,
  getUserListSuccess,
  getUserListFailed,
  getUserSuccess,
  getUserFailed,
  createUserSuccess,
  createUserFailed,
  editUserSuccess,
  editUserFailed,
  inactivateUserSuccess,
  inactivateUserFailed,
  activateUserSuccess,
  activateUserFailed,
  setUserModalStatus,
  setUserLoading,
  clear
} = userSlice.actions

const getUserList = (profileId) => async dispatch => {
  try {
    dispatch(getUserListStart())
    const userList = await userApi.getAllUser({ profileId })
    dispatch(getUserListSuccess(userList))
  } catch (error) {
    dispatch(getUserListFailed({ error }))
  }
}

const getUser = (id) => async dispatch => {
  try {
    dispatch(setUserLoading())
    const user = await userApi.getUser({ id })
    dispatch(getUserSuccess(user))
  } catch (error) {
    dispatch(getUserFailed({ error }))
  }
}

const createUser = (profileId, createUserDto) => async dispatch => {
  try {
    dispatch(setUserLoading())
    await userApi.createUser({ createUserDto })
    dispatch(createUserSuccess())
    dispatch(getUserList(profileId))
  } catch (error) {
    dispatch(createUserFailed({ error }))
  }
}

const editUser = (id, profileId, updateUserDto) => async dispatch => {
  try {
    dispatch(setUserLoading())
    await userApi.editUser({ id, updateUserDto })
    dispatch(editUserSuccess())
    dispatch(getUserList(profileId))
  } catch (error) {
    dispatch(editUserFailed({ error }))
  }
}

const inactivateUser = (id, profileId) => async dispatch => {
  try {
    await userApi.inactivateUser({ id })
    dispatch(inactivateUserSuccess())
    dispatch(getUserList(profileId))
  } catch (error) {
    dispatch(inactivateUserFailed({ error }))
  }
}

const activateUser = (id, profileId) => async dispatch => {
  try {
    await userApi.activateUser({ id })
    dispatch(activateUserSuccess())
    dispatch(getUserList(profileId))
  } catch (error) {
    dispatch(activateUserFailed({ error }))
  }
}

const checkUserNameUniqueness = (id, userName) => async dispatch => {
  try {
    const { isValid } = await userApi.isUserNameValid({ id, userName })
    return isValid
  } catch (error) {
    return true
  }
}

export {
  getUserList,
  getUser,
  createUser,
  editUser,
  inactivateUser,
  activateUser,
  setUserModalStatus,
  checkUserNameUniqueness,
  clear
}

export default userSlice.reducer
