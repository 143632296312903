import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { predictionReportageApi, scheduleApi } from 'services/api'

const initialState = {
  scheduleList: {},
  deliveryDateList: [],
  isListLoading: false,
  isDeliveryDateListLoading: false,
  error: null
}

const generateXmlFile = createAsyncThunk(
  'schedule/generateXmlFile',
  async (estimateId, { rejectWithValue }) => {
    try {
      const generate = await Promise.all([
        predictionReportageApi.generateAndSaveXmlReportage({ estimateId }),
        new Promise((resolve) => setTimeout(() => resolve(), 3000)) // min 3sec
      ])
      return generate
    } catch (error) {
      return rejectWithValue({ error })
    }
  }
)

const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    getScheduleListStart (state) {
      state.error = null
      state.isListLoading = true
    },
    getScheduleListSuccess (state, { payload }) {
      state.scheduleList = payload
      state.error = null
      state.isListLoading = false
    },
    getScheduleListFailed (state, { payload }) {
      state.scheduleList = {}
      state.error = payload.error
      state.isListLoading = false
    },
    getDeliveryDateListStart (state) {
      state.error = null
      state.isDeliveryDateListLoading = true
    },
    getDeliveryDateListSuccess (state, { payload }) {
      state.deliveryDateList = payload
      state.error = null
      state.isDeliveryDateListLoading = false
    },
    getDeliveryDateListFailed (state, { payload }) {
      state.deliveryDateList = []
      state.error = payload.error
      state.isDeliveryDateListLoading = false
    },
    clear (state) {
      state.scheduleList = {}
    }
  },
  extraReducers: {
    [generateXmlFile.rejected]: (state, { payload }) => {
      state.error = payload.error
    }
  }
})

const {
  getScheduleListStart,
  getScheduleListSuccess,
  getScheduleListFailed,
  getDeliveryDateListStart,
  getDeliveryDateListSuccess,
  getDeliveryDateListFailed,
  clear
} = scheduleSlice.actions

const getScheduleList = (params) => async dispatch => {
  try {
    dispatch(getScheduleListStart())
    const scheduleList = await scheduleApi.getPaginatedSchedule(params)
    dispatch(getScheduleListSuccess(scheduleList))
  } catch (error) {
    dispatch(getScheduleListFailed({ error }))
  }
}

const getDeliveryDateList = (powerPlantId) => async dispatch => {
  try {
    dispatch(getDeliveryDateListStart())
    const deliveryDateList = await scheduleApi.getAllDeliveryDate({ powerPlantId })
    dispatch(getDeliveryDateListSuccess(deliveryDateList))
  } catch (error) {
    dispatch(getDeliveryDateListFailed({ error }))
  }
}

export {
  getScheduleList,
  getDeliveryDateList,
  generateXmlFile,
  clear
}

export default scheduleSlice.reducer
