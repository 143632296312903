import { createSlice } from '@reduxjs/toolkit'
import { inverterApi } from 'services/api'
import { omit } from 'lodash'
import { ModalStatus } from 'models/enum'

const initialState = {
  inverterList: [],
  inverter: {},
  datasheet: {},
  isDatasheetLoading: false,
  inverterModalStatus: ModalStatus.Hidden,
  isListLoading: false,
  isEntityLoading: false,
  error: null
}

const inverterSlice = createSlice({
  name: 'inverter',
  initialState,
  reducers: {
    getInverterListStart (state) {
      state.isListLoading = true
      state.error = null
    },
    getInverterListSuccess (state, { payload }) {
      state.isListLoading = false
      state.error = null
      state.inverterList = payload
    },
    getInverterListFailed (state, { payload }) {
      state.isListLoading = false
      state.error = payload.error
      state.inverterList = []
    },
    getInverterSuccess (state, { payload }) {
      state.inverter = payload
      state.error = null
      state.isEntityLoading = false
    },
    getInverterFailed (state, { payload }) {
      state.inverter = {}
      state.error = payload.error
      state.isEntityLoading = false
    },
    duplicateInverterSuccess (state, { payload }) {
      state.inverter = omit(payload, ['id', 'name'])
      state.error = null
      state.isEntityLoading = false
    },
    duplicateInverterFailed (state, { payload }) {
      state.inverter = {}
      state.error = payload.error
      state.isEntityLoading = false
    },
    createInverterSuccess (state) {
      state.inverter = {}
      state.error = null
      state.inverterModalStatus = ModalStatus.Hidden
      state.isEntityLoading = false
    },
    createInverterFailed (state, { payload }) {
      state.error = payload.error
      state.isEntityLoading = false
    },
    editInverterSuccess (state) {
      state.inverter = {}
      state.error = null
      state.inverterModalStatus = ModalStatus.Hidden
      state.isEntityLoading = false
    },
    editInverterFailed (state, { payload }) {
      state.error = payload.error
      state.isEntityLoading = false
    },
    inactivateInverterSuccess (state) {
      state.error = null
    },
    inactivateInverterFailed (state, { payload }) {
      state.error = payload.error
    },
    activateInverterSuccess (state) {
      state.error = null
    },
    activateInverterFailed (state, { payload }) {
      state.error = payload.error
    },
    getDatasheetStarted (state) {
      state.isDatasheetLoading = true
    },
    getDatasheetSuccess (state, { payload }) {
      state.datasheet = payload
      state.isDatasheetLoading = false
      state.error = null
    },
    getDatasheetFailed (state, { payload }) {
      state.datasheet = {}
      state.isDatasheetLoading = false
      state.error = payload.error
    },
    uploadDatasheetStarted (state) {
      state.isDatasheetLoading = true
    },
    uploadDatasheetSuccess (state) {
      state.isDatasheetLoading = false
    },
    uploadDatasheetFailed (state, { payload }) {
      state.isDatasheetLoading = false
      state.error = payload.error
    },
    deleteDatasheetStarted (state) {
      state.isDatasheetLoading = true
    },
    deleteDatasheetSuccess (state) {
      state.isDatasheetLoading = false
    },
    deleteDatasheetFailed (state, { payload }) {
      state.isDatasheetLoading = false
      state.error = payload.error
    },
    setInverterModalStatus (state, { payload }) {
      state.inverterModalStatus = payload
      if (payload === ModalStatus.Hidden) {
        state.inverter = {}
      }
    },
    setInverterLoading (state) {
      state.isEntityLoading = true
    },
    clear (state) {
      state.inverterList = []
      state.inverter = {}
      state.inverterModalStatus = ModalStatus.Hidden
      state.error = null
    }
  }
})

const {
  getInverterListStart,
  getInverterListSuccess,
  getInverterListFailed,
  getInverterSuccess,
  getInverterFailed,
  duplicateInverterSuccess,
  duplicateInverterFailed,
  createInverterSuccess,
  createInverterFailed,
  editInverterSuccess,
  editInverterFailed,
  inactivateInverterSuccess,
  inactivateInverterFailed,
  activateInverterSuccess,
  activateInverterFailed,
  setInverterModalStatus,
  setInverterLoading,
  getDatasheetStarted,
  getDatasheetSuccess,
  getDatasheetFailed,
  uploadDatasheetStarted,
  uploadDatasheetSuccess,
  uploadDatasheetFailed,
  deleteDatasheetStarted,
  deleteDatasheetSuccess,
  deleteDatasheetFailed,
  clear
} = inverterSlice.actions

const getInverterList = () => async dispatch => {
  try {
    dispatch(getInverterListStart())
    const inverterList = await inverterApi.getAllInverter()
    dispatch(getInverterListSuccess(inverterList))
  } catch (error) {
    dispatch(getInverterListFailed({ error }))
  }
}

const getInverter = (id) => async dispatch => {
  try {
    dispatch(setInverterLoading())
    const inverter = await inverterApi.getInverter({ id })
    dispatch(getInverterSuccess(inverter))
  } catch (error) {
    dispatch(getInverterFailed({ error }))
  }
}

const duplicateInverter = (id) => async dispatch => {
  try {
    dispatch(setInverterLoading())
    const inverter = await inverterApi.getInverter({ id })
    dispatch(duplicateInverterSuccess(inverter))
  } catch (error) {
    dispatch(duplicateInverterFailed({ error }))
  }
}

const createInverter = (createInverterDto) => async dispatch => {
  try {
    dispatch(setInverterLoading())
    await inverterApi.createInverter({ createInverterDto })
    dispatch(createInverterSuccess())
    dispatch(getInverterList())
  } catch (error) {
    dispatch(createInverterFailed({ error }))
  }
}

const editInverter = (id, updateInverterDto) => async dispatch => {
  try {
    dispatch(setInverterLoading())
    await inverterApi.editInverter({ id, updateInverterDto })
    dispatch(editInverterSuccess())
    dispatch(getInverterList())
  } catch (error) {
    dispatch(editInverterFailed({ error }))
  }
}

const inactivateInverter = (id) => async dispatch => {
  try {
    await inverterApi.inactivateInverter({ id })
    dispatch(inactivateInverterSuccess())
    dispatch(getInverterList())
  } catch (error) {
    dispatch(inactivateInverterFailed({ error }))
  }
}

const activateInverter = (id) => async dispatch => {
  try {
    await inverterApi.activateInverter({ id })
    dispatch(activateInverterSuccess())
    dispatch(getInverterList())
  } catch (error) {
    dispatch(activateInverterFailed({ error }))
  }
}

const checkInverterNameUniqueness = (name) => async dispatch => {
  try {
    const inverterList = await inverterApi.getAllInverter({ name })
    return inverterList.length
  } catch (error) {
    return 0
  }
}

const getInverterDatasheet = (id) => async dispatch => {
  try {
    dispatch(getDatasheetStarted())
    const datasheet = await inverterApi.getInverterDataSheet({ id })
    dispatch(getDatasheetSuccess(datasheet))
  } catch (error) {
    dispatch(getDatasheetFailed({ error }))
  }
}

const uploadInverterDatasheet = (id, document) => async dispatch => {
  try {
    dispatch(uploadDatasheetStarted())
    await inverterApi.uploadInverterDataSheet({ id, document })
    dispatch(uploadDatasheetSuccess())
    dispatch(getInverterDatasheet(id))
  } catch (error) {
    dispatch(uploadDatasheetFailed({ error }))
  }
}

const downloadInverterDatasheet = (id) => async dispatch => {
  const response = await inverterApi.downloadInverterDataSheet({ id }, { responseType: 'blob' })
  return response
}

const deleteInverterDatasheet = (id) => async dispatch => {
  try {
    dispatch(deleteDatasheetStarted())
    await inverterApi.deleteInverterDataSheet({ id })
    dispatch(deleteDatasheetSuccess())
    dispatch(getInverterDatasheet(id))
    return true
  } catch (error) {
    dispatch(deleteDatasheetFailed({ error }))
    return false
  }
}

export {
  getInverterList,
  getInverter,
  duplicateInverter,
  createInverter,
  editInverter,
  inactivateInverter,
  activateInverter,
  setInverterModalStatus,
  checkInverterNameUniqueness,
  getInverterDatasheet,
  uploadInverterDatasheet,
  downloadInverterDatasheet,
  deleteInverterDatasheet,
  uploadDatasheetStarted,
  clear
}

export default inverterSlice.reducer
