import { createSlice } from '@reduxjs/toolkit'
import { scheduleLogApi } from 'services/api'

const initialState = {
  scheduleMessageList: {},
  isListLoading: false,
  error: null
}

const scheduleMessageSlice = createSlice({
  name: 'schedulemessage',
  initialState,
  reducers: {
    getScheduleMessageListStart (state) {
      state.error = null
      state.isListLoading = true
    },
    getScheduleMessageListSuccess (state, { payload }) {
      state.scheduleMessageList = payload
      state.error = null
      state.isListLoading = false
    },
    getScheduleMessageListFailed (state, { payload }) {
      state.scheduleMessageList = {}
      state.error = payload.error
      state.isListLoading = false
    },
    downloadACKResponseXMLSuccess (state) {
      state.error = null
    },
    downloadACKResponseXMLFailed (state, { payload }) {
      state.error = payload
    },
    downloadCNFResponseXMLSuccess (state) {
      state.error = null
    },
    downloadCNFResponseXMLFailed (state, { payload }) {
      state.error = payload
    },
    clear (state) {
      state.scheduleMessageList = {}
    }
  }
})

const {
  getScheduleMessageListStart,
  getScheduleMessageListSuccess,
  getScheduleMessageListFailed,
  downloadACKResponseXMLSuccess,
  downloadACKResponseXMLFailed,
  downloadCNFResponseXMLSuccess,
  downloadCNFResponseXMLFailed,
  clear
} = scheduleMessageSlice.actions

const getScheduleMessageList = (params) => async dispatch => {
  try {
    dispatch(getScheduleMessageListStart())
    const scheduleMessageList = await scheduleLogApi.getPaginatedScheduleLogs(params)
    dispatch(getScheduleMessageListSuccess(scheduleMessageList))
  } catch (error) {
    dispatch(getScheduleMessageListFailed({ error }))
  }
}

const downloadACKResponseXML = (id) => async dispatch => {
  try {
    const response = await scheduleLogApi.ackResponseXml({ id }, { responseType: 'blob' })
    dispatch(downloadACKResponseXMLSuccess())
    return response
  } catch (error) {
    dispatch(downloadACKResponseXMLFailed({ error }))
    return null
  }
}

const downloadCNFResponseXML = (id) => async dispatch => {
  try {
    const response = await scheduleLogApi.cnfResponseXml({ id }, { responseType: 'blob' })
    dispatch(downloadCNFResponseXMLSuccess())
    return response
  } catch (error) {
    dispatch(downloadCNFResponseXMLFailed({ error }))
    return null
  }
}

export {
  getScheduleMessageList,
  downloadACKResponseXML,
  downloadCNFResponseXML,
  clear
}

export default scheduleMessageSlice.reducer
