import React from 'react'
import ReactDOM from 'react-dom'
import store from 'store'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ConfigProvider } from 'antd'
import { getValidationMessages } from 'utils/form'
import huHU from 'antd/es/locale/hu_HU'
import enGB from 'antd/es/locale/en_GB'
import App from './App'
import dayjs from 'dayjs'
import 'dayjs/locale/hu'
import 'dayjs/locale/en'
import './i18n'

const currentLang = store.getState().language.currentLang
const locale = currentLang === 'hu' ? huHU : enGB

dayjs.locale(currentLang)

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <ConfigProvider
        locale={locale}
        form={{ validateMessages: getValidationMessages() }}
      >
        <App />
      </ConfigProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
)
