import { createSlice } from '@reduxjs/toolkit'
import { auditLogApi } from 'services/api'

const initialState = {
  functionGroupList: [],
  logList: {},
  isListLoading: false,
  isValueChangeColumnsVisible: false,
  error: null
}

const logSlice = createSlice({
  name: 'log',
  initialState,
  reducers: {
    getFunctionGroupListSuccess (state, { payload }) {
      state.functionGroupList = payload
      state.error = null
    },
    getFunctionGroupListFailed (state, { payload }) {
      state.functionGroupList = []
      state.error = payload.error
    },
    getLogListStart (state) {
      state.error = null
      state.isListLoading = true
    },
    getLogListSuccess (state, { payload }) {
      state.logList = payload
      state.error = null
      state.isListLoading = false
    },
    getLogListFailed (state, { payload }) {
      state.logList = {}
      state.error = payload.customError
      state.isListLoading = false
    },
    setValueChangeColumnVisible (state, { payload }) {
      state.isValueChangeColumnsVisible = payload
    },
    clear (state) {
      state.logList = {}
      state.error = null
      state.isValueChangeColumnsVisible = false
    }
  }
})

const {
  getFunctionGroupListSuccess,
  getFunctionGroupListFailed,
  getLogListStart,
  getLogListSuccess,
  getLogListFailed,
  setValueChangeColumnVisible,
  clear
} = logSlice.actions

const getFunctionGroupList = () => async dispatch => {
  try {
    const functionGroupList = await auditLogApi.fixedFunctionGroups()
    dispatch(getFunctionGroupListSuccess(functionGroupList))
  } catch (error) {
    dispatch(getFunctionGroupListFailed({ error }))
  }
}

const getLogList = (params) => async dispatch => {
  try {
    dispatch(getLogListStart())
    const logList = await auditLogApi.getPaginatedAuditLogs(params)
    dispatch(getLogListSuccess(logList))
  } catch (customError) {
    dispatch(getLogListFailed({ customError }))
  }
}

export {
  getFunctionGroupList,
  getLogList,
  setValueChangeColumnVisible,
  clear
}

export default logSlice.reducer
