import { createSlice } from '@reduxjs/toolkit'
import { getLanguage, setLanguage } from 'utils/storage'

const initialState = {
  currentLang: getLanguage()
}

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setCurrentLanguage (state, { payload }) {
      state.currentLang = payload.language
      setLanguage(payload.language)
    }
  }
})

export const { setCurrentLanguage } = languageSlice.actions

export default languageSlice.reducer
