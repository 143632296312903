import i18n from 'i18next'
import store from 'store'
import { initReactI18next } from 'react-i18next'
import translationEn from './locales/en.json'
import translationHu from './locales/hu.json'

const lng = (store && store.getState().language.currentLang) || 'hu'
const resources = {
  en: {
    translation: translationEn
  },
  hu: {
    translation: translationHu
  }
}

i18n.use(initReactI18next).init({
  resources,
  lng,
  fallbackLng: 'hu',
  interpolation: {
    escapeValue: false
  }
})

export default i18n
