import i18n from 'i18next'
import { get, isDate } from 'lodash'
import dayjs from 'dayjs'

export const getFields = (entity) => {
  if (!entity) {
    return []
  }
  const fields = []
  const entityArray = Object.entries(entity)
  for (const [name, value] of entityArray) {
    let preparedValue = value

    if (Array.isArray(value)) {
      preparedValue = value.map(({ id }) => id)
    } else if (isDate(value)) {
      preparedValue = dayjs(value)
    }

    fields.push({ name, value: preparedValue })
  }
  return fields
}

export const getValidationMessages = (translateKey = 'validation.form-messages') => {
  const translation = i18n.getDataByLanguage(i18n.language).translation
  return get(translation, translateKey)
}
