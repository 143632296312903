import { createSlice } from '@reduxjs/toolkit'
import { powerPlantApi } from 'services/api'
import { omit } from 'lodash'
import { ModalStatus } from 'models/enum'
import { getInverterList, getPositionList, getSolarPanelList } from 'store/general/entity.slice'
import { getIntervalSunCollectorList } from 'store/powerplant/intervalconfig.slice'
import { refreshPowerPlant } from './powerplant.slice'

const initialState = {
  sunCollectorList: [],
  sunCollector: {},
  sunCollectorModalStatus: ModalStatus.Hidden,
  error: null,
  isEntityLoading: false,
  isListLoading: false
}

const sunCollectorSlice = createSlice({
  name: 'suncollector',
  initialState,
  reducers: {
    getSunCollectorListStarted (state) {
      state.isListLoading = true
    },
    getSunCollectorListSuccess (state, { payload }) {
      state.sunCollectorList = payload
      state.isListLoading = false
      state.error = null
    },
    getSunCollectorListFailed (state, { payload }) {
      state.sunCollectorList = []
      state.isListLoading = false
      state.error = payload.error
    },
    getSunCollectorSuccess (state, { payload }) {
      state.sunCollector = payload
      state.error = null
      state.isEntityLoading = false
    },
    getSunCollectorFailed (state, { payload }) {
      state.sunCollector = {}
      state.error = payload.error
      state.isEntityLoading = false
    },
    duplicateSunCollectorSuccess (state, { payload }) {
      state.sunCollector = omit(payload, ['id'])
      state.error = null
      state.isEntityLoading = false
    },
    duplicateSunCollectorFailed (state, { payload }) {
      state.sunCollector = {}
      state.error = payload.error
      state.isEntityLoading = false
    },
    createSunCollectorSuccess (state) {
      state.sunCollector = {}
      state.error = null
      state.sunCollectorModalStatus = ModalStatus.Hidden
      state.isEntityLoading = false
    },
    createSunCollectorFailed (state, { payload }) {
      state.error = payload.error
      state.isEntityLoading = false
    },
    editSunCollectorSuccess (state) {
      state.sunCollector = {}
      state.error = null
      state.sunCollectorModalStatus = ModalStatus.Hidden
      state.isEntityLoading = false
    },
    editSunCollectorFailed (state, { payload }) {
      state.error = payload.error
      state.isEntityLoading = false
    },
    setSunCollectorModalStatus (state, { payload }) {
      state.sunCollectorModalStatus = payload
      if (payload === ModalStatus.Hidden) {
        state.sunCollector = {}
      }
    },
    setSelectedPowerPlant (state, { payload }) {
      state.sunCollector.powerPlantId = payload.powerPlantId
      state.sunCollector.intervalConfigurationId = payload.intervalConfigurationId
    },
    setSunCollectorLoading (state) {
      state.isEntityLoading = true
    },
    setActiveSunCollectorList (state, { payload }) {
      state.sunCollectorList = state.sunCollectorList.map(sunCollector => ({
        ...sunCollector,
        isActive: payload
      }))
    },
    inactivateSunCollectorSuccess (state, { payload }) {
      state.error = null
      state.sunCollectorList = state.sunCollectorList.map(sunCollector => ({
        ...sunCollector,
        isActive: sunCollector.id === payload ? false : sunCollector.isActive
      }))
    },
    inactivateSunCollectorFailed (state, { payload }) {
      state.error = payload.error
    },
    activateSunCollectorSuccess (state, { payload }) {
      state.error = null
      state.sunCollectorList = state.sunCollectorList.map(sunCollector => ({
        ...sunCollector,
        isActive: sunCollector.id === payload ? true : sunCollector.isActive
      }))
    },
    activateSunCollectorFailed (state, { payload }) {
      state.error = payload.customError
    },
    clear (state) {
      state.sunCollectorList = []
      state.sunCollector = {}
      state.sunCollectorModalStatus = ModalStatus.Hidden
      state.error = null
    }
  }
})

const {
  getSunCollectorListSuccess,
  getSunCollectorListFailed,
  getSunCollectorSuccess,
  getSunCollectorFailed,
  duplicateSunCollectorSuccess,
  duplicateSunCollectorFailed,
  createSunCollectorSuccess,
  createSunCollectorFailed,
  editSunCollectorSuccess,
  editSunCollectorFailed,
  setSunCollectorModalStatus,
  setSelectedPowerPlant,
  setSunCollectorLoading,
  getSunCollectorListStarted,
  setActiveSunCollectorList,
  inactivateSunCollectorSuccess,
  inactivateSunCollectorFailed,
  activateSunCollectorSuccess,
  activateSunCollectorFailed,
  clear
} = sunCollectorSlice.actions

const getSunCollectorList = (powerPlantId) => async dispatch => {
  try {
    dispatch(getSunCollectorListStarted())
    const sunCollectorList = await powerPlantApi.getPowerPlantRows({ powerPlantId })
    dispatch(getSunCollectorListSuccess(sunCollectorList))
  } catch (error) {
    dispatch(getSunCollectorListFailed({ error }))
  }
}

const getSunCollector = (powerPlantId, id) => async dispatch => {
  try {
    dispatch(setSunCollectorLoading())
    const sunCollector = await powerPlantApi.getPowerPlantRow({ powerPlantId, id })
    dispatch(getSunCollectorSuccess(sunCollector))
  } catch (error) {
    dispatch(getSunCollectorFailed({ error }))
  }
}

const duplicateSunCollector = (powerPlantId, id) => async dispatch => {
  try {
    dispatch(setSunCollectorLoading())
    const sunCollector = await powerPlantApi.getPowerPlantRow({ powerPlantId, id })
    dispatch(duplicateSunCollectorSuccess(sunCollector))
  } catch (error) {
    dispatch(duplicateSunCollectorFailed({ error }))
  }
}

const createSunCollector = (powerPlantId, data) => async (dispatch, getState) => {
  try {
    dispatch(setSunCollectorLoading())
    const { sunCollector } = getState().suncollector
    const { intervalConfigurationId } = sunCollector
    const createPowerPlantConfigurationDto = { ...data, intervalConfigurationId }
    await powerPlantApi.createPowerPlantRow({ powerPlantId, createPowerPlantConfigurationDto })
    if (intervalConfigurationId) {
      dispatch(getIntervalSunCollectorList(powerPlantId, intervalConfigurationId))
    } else {
      dispatch(getSunCollectorList(powerPlantId))
    }
    dispatch(createSunCollectorSuccess())
    dispatch(getSunCollectorList(powerPlantId))
    dispatch(refreshPowerPlant(powerPlantId))
  } catch (error) {
    dispatch(createSunCollectorFailed({ error }))
  }
}

const editSunCollector = (powerPlantId, id, data) => async (dispatch, getState) => {
  try {
    dispatch(setSunCollectorLoading())
    const { sunCollector } = getState().suncollector
    const { intervalConfigurationId } = sunCollector
    const updatePowerPlantConfigurationDto = { ...data, intervalConfigurationId }
    await powerPlantApi.editPowerPlantRow({ powerPlantId, id, updatePowerPlantConfigurationDto })
    if (intervalConfigurationId) {
      dispatch(getIntervalSunCollectorList(powerPlantId, intervalConfigurationId))
    } else {
      dispatch(getSunCollectorList(powerPlantId))
    }
    dispatch(editSunCollectorSuccess())
    dispatch(refreshPowerPlant(powerPlantId))
  } catch (error) {
    dispatch(editSunCollectorFailed({ error }))
  }
}

const prepareCreateModal = (powerPlantId, intervalConfigurationId) => async dispatch => {
  dispatch(getPositionList())
  dispatch(getInverterList())
  dispatch(getSolarPanelList())
  dispatch(setSelectedPowerPlant({ powerPlantId, intervalConfigurationId }))
  dispatch(setSunCollectorModalStatus(ModalStatus.Create))
}

const prepareEditModal = ({ powerPlantId, id }) => async dispatch => {
  dispatch(getPositionList())
  dispatch(getInverterList(id))
  dispatch(getSolarPanelList(id))
  dispatch(getSunCollector(powerPlantId, id))
  dispatch(setSunCollectorModalStatus(ModalStatus.Edit))
}

const prepareDuplicateModal = ({ powerPlantId, id }) => async dispatch => {
  dispatch(getPositionList())
  dispatch(getInverterList(id))
  dispatch(getSolarPanelList(id))
  dispatch(duplicateSunCollector(powerPlantId, id))
  dispatch(setSunCollectorModalStatus(ModalStatus.Duplicate))
}

const inactivateSunCollector = (id, powerPlantId) => async dispatch => {
  try {
    await powerPlantApi.inactivatePowerPlantRow({ powerPlantId, id })
    dispatch(inactivateSunCollectorSuccess(id))
    dispatch(refreshPowerPlant(powerPlantId))
  } catch (error) {
    dispatch(inactivateSunCollectorFailed({ error }))
  }
}

const activateSunCollector = (id, powerPlantId) => async dispatch => {
  try {
    await powerPlantApi.activatePowerPlantRow({ powerPlantId, id })
    dispatch(activateSunCollectorSuccess(id))
    dispatch(refreshPowerPlant(powerPlantId))
  } catch (error) {
    dispatch(activateSunCollectorFailed({ error }))
  }
}

export {
  getSunCollectorList,
  getSunCollector,
  duplicateSunCollector,
  createSunCollector,
  editSunCollector,
  setSunCollectorModalStatus,
  setSelectedPowerPlant,
  setSunCollectorLoading,
  setActiveSunCollectorList,
  prepareCreateModal,
  prepareEditModal,
  prepareDuplicateModal,
  inactivateSunCollector,
  activateSunCollector,
  clear
}

export default sunCollectorSlice.reducer
