import { createSelector, createSlice } from '@reduxjs/toolkit'
import { operatorImportApi } from 'services/api'

const initialState = {
  isWeatherLoading: false,
  error: null
}

const operatorImportSlice = createSlice({
  name: 'operatorimport',
  initialState,
  reducers: {
    // Időjárás előrejelzési adatok
    uploadWeatherStarted (state) {
      state.isWeatherLoading = true
    },
    uploadWeatherSuccess (state) {
      state.isWeatherLoading = false
    },
    uploadWeatherFailed (state, { payload }) {
      state.isWeatherLoading = false
      state.error = payload.error
    },
    getForecastSourcesStarted (state) {
      state.isForecastSourcesLoading = true
    },
    getForecastSourcesSuccess (state, { payload }) {
      state.isForecastSourcesLoading = false
      state.forecastSources = payload
    },
    getForecastSourcesFailed (state, { payload }) {
      state.isForecastSourcesLoading = false
      state.error = payload.error
    },
    clear (state) {
      state.forecastSources = []
    }
  }
})

const {
  clear,
  uploadWeatherStarted,
  uploadWeatherSuccess,
  uploadWeatherFailed
} = operatorImportSlice.actions

const uploadWeather = (data) => async dispatch => {
  try {
    dispatch(uploadWeatherStarted())
    await operatorImportApi.operatorForecastUpload(data)
    dispatch(uploadWeatherSuccess())
    return { isSuccess: true }
  } catch (customError) {
    dispatch(uploadWeatherFailed({ customError }))
    return { isSuccess: false, customError }
  }
}

const downloadWeatherSample = () => async () => {
  try {
    return await operatorImportApi.operatorDownloadForecastSample({ responseType: 'blob' })
  } catch {
    return null
  }
}

const filterForecastProviders = createSelector(
  state => state.entity.forecastProviderList,
  forecastProviders => forecastProviders.filter(provider => ['OMSZ', 'GlobalManual', 'IBM'].includes(provider.code))
)

export {
  clear,
  filterForecastProviders,
  uploadWeather,
  downloadWeatherSample
}

export default operatorImportSlice.reducer
