import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

const useAsyncValidator = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const getAsyncValidator = (asyncMethod, originalValue) => {
    return () => ({
      validator: async (rule, value) => {
        if (!value || value === originalValue) {
          return Promise.resolve()
        }
        const responseLength = await dispatch(asyncMethod(value))
        if (responseLength > 0) {
          return Promise.reject(t('validation.entity-exists'))
        } else {
          return Promise.resolve()
        }
      }
    })
  }

  const getAsyncValidatorById = (asyncMethod, id) => {
    return () => ({
      validator: async (rule, value) => {
        if (!value) {
          return Promise.resolve()
        }
        const response = await dispatch(asyncMethod(id, value))
        if (response === false) {
          return Promise.reject(t('validation.entity-exists'))
        } else {
          return Promise.resolve()
        }
      }
    })
  }

  return {
    getAsyncValidator,
    getAsyncValidatorById
  }
}

export default useAsyncValidator
