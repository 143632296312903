import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const useMeta = (titleKey) => {
  const { t } = useTranslation()
  const prefix = t('general.title')

  useEffect(() => {
    document.title = titleKey ? [prefix, t(titleKey)].join(' | ') : prefix
  }, [t, prefix, titleKey])
}

export default useMeta
