import { createSlice } from '@reduxjs/toolkit'
import { estimateApi } from 'services/api'
import { PrognosisStatus } from 'models/enum'
import { translateCodeList } from 'utils/translate'

const initialState = {
  estimateList: [],
  isEstimateListLoading: false,
  forecastSourceList: [],
  isForecastSourceListLoading: false,
  error: null
}

const estimateSlice = createSlice({
  name: 'estimatetest',
  initialState,
  reducers: {
    getEstimateListStart (state) {
      state.error = null
      state.isEstimateListLoading = true
    },
    getEstimateListSuccess (state, { payload }) {
      state.estimateList = payload
      state.error = null
      state.isEstimateListLoading = false
    },
    getEstimateListFailed (state, { payload }) {
      state.estimateList = []
      state.error = payload.error
      state.isEstimateListLoading = false
    },
    runOneEstimateStart (state, { payload }) {
      state.isEstimateListLoading = true
      state.estimateList = state.estimateList.map(item => {
        if (item.powerPlantId === payload.powerPlantId) {
          return {
            ...item,
            prognosisStatus: PrognosisStatus.Run
          }
        }
        return item
      })
    },
    runOneEstimateSuccess (state, { payload }) {
      state.error = null
      state.isEstimateListLoading = false
      state.estimateList = state.estimateList.map(item => {
        if (item.powerPlantId === payload.powerPlantId) {
          return {
            ...item,
            prognosisStatus: PrognosisStatus.Ready,
            ...payload
          }
        }
        return item
      })
    },
    runOneEstimateFailed (state, { payload }) {
      state.error = payload.error
      state.isEstimateListLoading = false
      state.estimateList = state.estimateList.map(item => {
        if (payload.powerPlant.powerPlantId === item.powerPlantId &&
          item.prognosisStatus === PrognosisStatus.Run) {
          return {
            ...item,
            prognosisStatus: PrognosisStatus.NewData
          }
        }
        return item
      })
    },
    getForecastSourceListStart (state) {
      state.error = null
      state.isForecastSourceListLoading = true
    },
    getForecastSourceListSuccess (state, { payload }) {
      state.forecastSourceList = payload
      state.error = null
      state.isForecastSourceListLoading = false
    },
    getForecastSourceListFailed (state, { payload }) {
      state.error = payload.error
      state.isForecastSourceListLoading = false
    },
    clear (state) {
      state.estimateList = []
      state.forecastSourceList = []
      state.error = null
    }
  }
})

const {
  getEstimateListStart,
  getEstimateListSuccess,
  getEstimateListFailed,
  runOneEstimateStart,
  runOneEstimateSuccess,
  runOneEstimateFailed,
  getForecastSourceListStart,
  getForecastSourceListSuccess,
  getForecastSourceListFailed,
  clear
} = estimateSlice.actions

const getEstimateList = (values) => async dispatch => {
  try {
    dispatch(getEstimateListStart())

    const estimateList = await estimateApi.getCustomColoredPowerPlantsEstimateInfo(values)
    dispatch(getEstimateListSuccess(estimateList))
  } catch (error) {
    dispatch(getEstimateListFailed({ error }))
  }
}

const runOneEstimate = ({ powerPlant, targetDate, isFactWeather, forecastSourceCode }) => async dispatch => {
  const { powerPlantId } = powerPlant
  const customRunEstimateDto = { powerPlantId, targetDate, isFactWeather, forecastSourceCode }
  try {
    dispatch(runOneEstimateStart(powerPlant))
    const response = await estimateApi.runCustomEstimate({ customRunEstimateDto })
    dispatch(runOneEstimateSuccess({ powerPlantId, ...response }))
    return powerPlant
  } catch (error) {
    dispatch(runOneEstimateFailed({ error, powerPlant }))
    return null
  }
}

const getForecastSourceList = () => async dispatch => {
  try {
    dispatch(getForecastSourceListStart())
    const forecastSourceList = await estimateApi.getForecastSources()
    dispatch(getForecastSourceListSuccess(translateCodeList(forecastSourceList, 'forecastSource')))
  } catch (error) {
    dispatch(getForecastSourceListFailed({ error }))
  }
}

export {
  getEstimateList,
  runOneEstimate,
  getForecastSourceList,
  clear
}

export default estimateSlice.reducer
