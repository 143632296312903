import React from 'react'
import EnLogo from 'assets/img/flags/en.svg'
import HuLogo from 'assets/img/flags/hu.svg'
import { Dropdown, Menu } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentLanguage } from 'store/general/language.slice'
import styles from './LanguageSelector.module.scss'
import classnames from 'classnames'
import dayjs from 'dayjs'

const LanguageSelectorComponent = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const currentLang = useSelector(state => state.language.currentLang)

  const changeLanguage = language => {
    i18n.changeLanguage(language)
    dayjs.locale(language)
    dispatch(setCurrentLanguage({ language }))
  }

  const availableLanguages = [
    {
      key: 'en',
      image: EnLogo,
      label: t('navigation.languages.en')
    },
    {
      key: 'hu',
      image: HuLogo,
      label: t('navigation.languages.hu')
    }
  ]

  const languageOptions = (
    <Menu key="language" className={styles.languageSelector}>
      {availableLanguages.map(({ image, key, label }) => (
        <Menu.Item
          key={key}
          onClick={() => changeLanguage(key)}
        >
          <img
            src={image}
            alt={label}
            title={label}
            className={styles.languageSelectorImage}
          />
          <span children={styles.languageSelectorText}>
            {label}
          </span>
        </Menu.Item>
      ))}
    </Menu>
  )

  const CurrentLanguageImg = () => {
    const currentLanguage = availableLanguages.find(
      ({ key }) => key === currentLang
    )

    if (!currentLanguage) {
      return ''
    }

    return (
      <img
        src={currentLanguage.image}
        alt={currentLanguage.label}
        title={currentLanguage.label}
        className={styles.languageSelectorImage}
      />
    )
  }

  return (
    <Dropdown
      className={classnames(styles.languageSelector, styles.languageSelectorDropdown)}
      overlay={languageOptions}
      trigger={['click']}
    >
      <div>
        <CurrentLanguageImg className={styles.languageSelectorImage} />
        <span className={styles.languageSelectorText}>
          {t('navigation.languages.language')}
        </span>
        <FontAwesomeIcon icon={faChevronDown} />
      </div>
    </Dropdown>
  )
}

export default LanguageSelectorComponent
