import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

const ImageWidget = (props) => {
  const { image, ext, ...imageProps } = props

  const sources = useMemo(() => {
    try {
      return {
        small: require(`assets/img/${image}_small.${ext}`),
        medium: require(`assets/img/${image}_medium.${ext}`),
        large: require(`assets/img/${image}_large.${ext}`)
      }
    } catch {
      console.warn(`Cannot find image: assets/img/${image}_small.${ext}`)
      const fallbackImage = require('assets/img/logo.svg')
      return {
        small: fallbackImage,
        medium: fallbackImage,
        large: fallbackImage
      }
    }
  }, [ext, image])

  return (
    <img
      srcSet={`${sources.small} 600w, ${sources.medium} 900w, ${sources.large} 1376w`}
      sizes="(max-width: 700px) 600px, (max-width: 1000px) 900px, 1376px"
      alt="METEO"
      {...imageProps}
    />
  )
}

export default ImageWidget

ImageWidget.propTypes = {
  image: PropTypes.string,
  ext: PropTypes.oneOf(['jpg', 'png', 'gif'])
}

ImageWidget.defaultProps = {
  ext: 'jpg'
}
