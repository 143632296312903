import React, { forwardRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'
import styles from './SelectWidget.module.scss'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

const SelectWidget = forwardRef((props, ref) => {
  const { t } = useTranslation()
  const { children, className, ...restProps } = props

  const classes = useMemo(() => {
    return classnames(className, styles.selectWidget)
  }, [className])

  const handleFilter = (input, option) => {
    return option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
  }

  return (
    <Select
      ref={ref}
      showSearch
      placeholder={t('general.select-choose')}
      filterOption={handleFilter}
      {...restProps}
      className={classes}
    >
      {children}
    </Select>
  )
})

export default SelectWidget

SelectWidget.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}
