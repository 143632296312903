import { createSlice } from '@reduxjs/toolkit'
import { activityListApi } from 'services/api'

const initialState = {
  activityData: null,
  isListLoading: false,
  isFileDownloading: false,
  error: null
}

const activityListSlice = createSlice({
  name: 'activityList',
  initialState,
  reducers: {
    getActivityListDataStart (state) {
      state.error = null
      state.isListLoading = true
    },
    getActivityListDataSuccess (state, { payload }) {
      state.activityData = payload
      state.error = null
      state.isListLoading = false
    },
    getActivityListDataFailed (state, { payload }) {
      state.activityData = null
      state.error = payload.error
      state.isListLoading = false
    },
    downloadActivityListStart (state) {
      state.isFileDownloading = true
    },
    downloadActivityListSuccess (state) {
      state.error = null
      state.isFileDownloading = false
    },
    downloadActivityListFailed (state, { payload }) {
      state.error = payload.error
      state.isFileDownloading = false
    },
    clear (state) {
      return {
        ...initialState
      }
    }
  }
})

const {
  getActivityListDataStart,
  getActivityListDataSuccess,
  getActivityListDataFailed,
  downloadActivityListStart,
  downloadActivityListSuccess,
  downloadActivityListFailed,
  clear
} = activityListSlice.actions

const getActivityListData = (startDate) => async dispatch => {
  try {
    dispatch(getActivityListDataStart())
    const activityList = await activityListApi.getActivityList({ startDate })
    dispatch(getActivityListDataSuccess(activityList))
  } catch (error) {
    dispatch(getActivityListDataFailed({ error }))
  }
}

const downloadActivityList = (startDate) => async dispatch => {
  try {
    dispatch(downloadActivityListStart())
    const file = await activityListApi.downloadActivityList({ startDate }, { responseType: 'blob' })
    dispatch(downloadActivityListSuccess())
    return file
  } catch (error) {
    dispatch(downloadActivityListFailed({ error }))
    return null
  }
}

export {
  getActivityListData,
  downloadActivityList,
  clear
}

export default activityListSlice.reducer
