import { createSlice } from '@reduxjs/toolkit'
import { inverterApi } from 'services/api'
import { emptyEfficiencyMatrix, prepareEfficiencyMatrix } from './efficiencymatrix.util'

const initialState = {
  error: null,
  loading: false
}

const efficiencymatrixSlice = createSlice({
  name: 'efficiencymatrix',
  initialState,
  reducers: {
    getEfficiencyMatrixStart (state) {
      state.loading = true
    },
    getEfficiencyMatrixSuccess (state) {
      state.error = null
      state.loading = false
    },
    getEfficiencyMatrixFailed (state, { payload }) {
      state.error = payload.error
      state.loading = false
    },
    createEfficiencyMatrixSuccess (state) {
      state.error = null
    },
    createEfficiencyMatrixFailed (state, { payload }) {
      state.error = payload.error
    },
    editEfficiencyMatrixSuccess (state) {
      state.error = null
    },
    editEfficiencyMatrixFailed (state, { payload }) {
      state.error = payload.error
    },
    deleteEfficiencyMatrixSuccess (state) {
      state.error = null
    },
    deleteEfficiencyMatrixFailed (state, { payload }) {
      state.error = payload.error
    },
    clear (state) {
      state.efficiencyMatrixEntity = {}
      state.error = null
    }
  }
})

const {
  getEfficiencyMatrixStart,
  getEfficiencyMatrixSuccess,
  getEfficiencyMatrixFailed,
  createEfficiencyMatrixSuccess,
  createEfficiencyMatrixFailed,
  editEfficiencyMatrixSuccess,
  editEfficiencyMatrixFailed,
  deleteEfficiencyMatrixSuccess,
  deleteEfficiencyMatrixFailed,
  inlineModify,
  clear
} = efficiencymatrixSlice.actions

const getEfficiencyMatrix = (inverterId) => async dispatch => {
  try {
    dispatch(getEfficiencyMatrixStart())
    const efficiencyMatrix = await inverterApi.getEfficiencyCurveByInverterId({ inverterId })
    dispatch(getEfficiencyMatrixSuccess())
    return prepareEfficiencyMatrix(efficiencyMatrix)
  } catch (error) {
    dispatch(getEfficiencyMatrixFailed({ error }))
    return emptyEfficiencyMatrix
  }
}

const createEfficiencyMatrix = (inverterId, createEfficiencyCurveDto) => async dispatch => {
  try {
    await inverterApi.createEfficiencyCurve({ inverterId, createEfficiencyCurveDto })
    dispatch(createEfficiencyMatrixSuccess())
    return dispatch(getEfficiencyMatrix(inverterId))
  } catch (error) {
    dispatch(createEfficiencyMatrixFailed({ error }))
    return null
  }
}

const editEfficiencyMatrix = (inverterId, id, updateEfficiencyCurveDto) => async dispatch => {
  try {
    await inverterApi.editEfficiencyCurve({ inverterId, id, updateEfficiencyCurveDto })
    dispatch(editEfficiencyMatrixSuccess())
    return dispatch(getEfficiencyMatrix(inverterId))
  } catch (error) {
    dispatch(editEfficiencyMatrixFailed({ error }))
    return null
  }
}

const deleteEfficiencyMatrix = (inverterId, id) => async dispatch => {
  try {
    await inverterApi.deleteDeleteEfficiencyCurve({ inverterId, id })
    dispatch(deleteEfficiencyMatrixSuccess())
    return emptyEfficiencyMatrix
  } catch (error) {
    dispatch(deleteEfficiencyMatrixFailed({ error }))
    return null
  }
}

export {
  getEfficiencyMatrix,
  inlineModify,
  createEfficiencyMatrix,
  editEfficiencyMatrix,
  deleteEfficiencyMatrix,
  clear
}

export default efficiencymatrixSlice.reducer
