import { parse, stringify } from 'qs'

export const parseParams = (query) => {
  return parse(query, { ignoreQueryPrefix: true, comma: true })
}

export const defaultParamWithQuery = (param, obj) => {
  if (!obj) {
    return `?${stringify(param, { encode: false, arrayFormat: 'comma' })}`
  }

  return `${param}?${stringify(obj, { encode: false, arrayFormat: 'comma' })}`
}

export const urlWithQuery = (url, obj) => `${url}?${stringify(obj, { encode: false })}`
