import { useDispatch, useSelector } from 'react-redux'
import { login, setLoginModalVisible } from 'store/auth/auth.slice'

const useLogin = () => {
  const dispatch = useDispatch()
  const { error, isLoading, isLoginModalVisible } = useSelector(state => state.auth)

  const handleOnFinish = values => {
    dispatch(login(values))
  }

  const handleOnFinishFailed = errorInfo => {
    console.warn(errorInfo)
  }

  const handleHideLoginModal = () => {
    dispatch(setLoginModalVisible(false))
  }

  return {
    error,
    isLoading,
    isLoginModalVisible,
    handleOnFinish,
    handleOnFinishFailed,
    handleHideLoginModal
  }
}

export default useLogin
