import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'antd'
import classnames from 'classnames'
import styles from './FormWidget.module.scss'
import { getFields } from 'utils/form'

const FormWidget = (props) => {
  const { className, entity, ...restProps } = props

  const fields = useMemo(() => getFields(entity), [entity])

  const classes = useMemo(() => {
    return classnames(className, styles.formWidget)
  }, [className])

  return (
    <Form
      fields={fields}
      className={classes}
      {...restProps}
    />
  )
}

export default FormWidget

FormWidget.propTypes = {
  className: PropTypes.string,
  entity: PropTypes.object
}
