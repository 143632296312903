import { createSlice } from '@reduxjs/toolkit'
import { inverterMonitoringApi } from 'services/api'

const initialState = {
  powerPlantStateList: [],
  powerPlantSiteInfo: {},
  isListLoading: false,
  isEntityLoading: false,
  error: null
}

const inverterMonitoringSlice = createSlice({
  name: 'invertermonitoring',
  initialState,
  reducers: {
    getPowerPlantStatesStart (state) {
      state.isListLoading = true
      state.error = null
    },
    getPowerPlantStatesSuccess (state, { payload }) {
      state.isListLoading = false
      state.error = null
      state.powerPlantStateList = payload || []
    },
    getPowerPlantStatesFailed (state, { payload }) {
      state.isListLoading = false
      state.error = payload.error
    },
    getPowerPlantSiteInfoStart (state) {
      state.isEntityLoading = true
    },
    getPowerPlantSiteInfoSuccess (state, { payload }) {
      state.powerPlantSiteInfo = payload
      state.error = null
      state.isEntityLoading = false
    },
    getPowerPlantSiteInfoFailed (state, { payload }) {
      state.powerPlantSiteInfo = {}
      state.error = payload.error
      state.isEntityLoading = false
    },
    clear (state) {
      state.powerPlantStateList = []
      state.powerPlantSiteInfo = {}
      state.error = null
    }
  }
})

const {
  getPowerPlantStatesStart,
  getPowerPlantStatesSuccess,
  getPowerPlantStatesFailed,
  getPowerPlantSiteInfoStart,
  getPowerPlantSiteInfoSuccess,
  getPowerPlantSiteInfoFailed,
  clear
} = inverterMonitoringSlice.actions

const getPowerPlantStates = (params) => async dispatch => {
  try {
    dispatch(getPowerPlantStatesStart())
    const powerPlantStates = await inverterMonitoringApi.getPowerPlantStates(params)
    dispatch(getPowerPlantStatesSuccess(powerPlantStates))
  } catch (error) {
    dispatch(getPowerPlantStatesFailed({ error }))
  }
}

const getPowerPlantSiteInfo = (powerPlantId) => async dispatch => {
  try {
    dispatch(getPowerPlantSiteInfoStart())
    const powerPlantSiteInfo = await inverterMonitoringApi.getPowerPlantSiteInfo({ powerPlantId })
    dispatch(getPowerPlantSiteInfoSuccess(powerPlantSiteInfo))
  } catch (error) {
    dispatch(getPowerPlantSiteInfoFailed({ error }))
  }
}

export {
  getPowerPlantStates,
  getPowerPlantSiteInfo,
  clear
}

export default inverterMonitoringSlice.reducer
