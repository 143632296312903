import React from 'react'
import i18n from 'i18n'
import { get } from 'lodash'
import { message, notification } from 'antd'
import styles from 'components/widgets/Notification/NotificationWidget.module.scss'

export const messageTimeout = 5

export const errorHandler = function (error) {
  // Custom error
  if (['ERR0043', 'ERR0044'].includes(error.errorCode)) {
    notification.close('session-expired')
    showWarningNotification({
      key: 'session-expired',
      message: i18n.t('http.token-expired-title'),
      description: i18n.t('http.token-expired-desc'),
      duration: 0
    })
    return
  }

  switch (error.status) {
  // Bad request
  case 400: {
    const errorMessages = getTranslatedErrors(error)
    showErrorNotification(errorMessages)
    break
  }
  // File not found
  case 404: {
    if (error.isBlob) {
      showErrorNotification(i18n.t('http.file-not-found'))
    }
    break
  }
  // Handle error in API call
  case 409: {
    const errorMessage = getTranslatedGlobalErrors(error)
    errorMessage && showErrorNotification(errorMessage)
    break
  }
  // Handle too large file size
  case 413: {
    showErrorNotification(i18n.t('http.file-too-large'))
    break
  }
  // Handle server error
  case 500: {
    const errorMessage = getTranslatedGlobalErrors(error)
    showErrorNotification(errorMessage)
    break
  }
  default:
    console.error(error)
  }
}

export const successHandler = (foundSuccess) => {
  message.success(i18n.t(`general.${foundSuccess.action}-success`))
}

export const getTranslatedErrors = (errorResponse) => {
  const entityName = get(errorResponse, 'objectDetail.entity', 'entity')
  const errors = get(errorResponse, 'objectDetail.propertyValidations', [])
  if (errors.length) {
    const errorCodes = errors.map(({ errorCode, field }) => {
      const translateKey = `${entityName}.entity.${field}`
      return i18n.t(errorCode, { field: i18n.t(translateKey) })
    })
    return errorCodes.join('\r\n')
  }
  return null
}

export const getTranslatedGlobalErrors = (error) => {
  if (error.errorCode) {
    return i18n.t(`global-errors.${error.errorCode}`)
  }
  return null
}

export const showErrorNotification = (description) => {
  notification.error({
    className: styles.notificationWidget,
    message: i18n.t('general.error'),
    description,
    duration: messageTimeout,
    top: 75
  })
}

export const showWarningNotification = ({ message, description, duration = 5, ...restProps }) => {
  notification.warning({
    className: styles.notificationWidget,
    message,
    description,
    duration,
    top: 75,
    ...restProps
  })
}

export const ExcelValidationErrors = 'excel.validation.errors'

export const customErrorHandler = (customError) => {
  const cellErrors = customError?.objectDetail?.propertyValidations

  // cell errors
  if (cellErrors) {
    const description = cellErrors
      .map(({ errorCode, cell }) => [
        i18n.t(errorCode),
        (cell ? ` (${cell} ${i18n.t('general.cell')})` : '')
      ].join(' '))
      .map((line, index) => <p style={{ margin: 0 }} key={`line-${index}`}>{line}</p>)

    notification.error({ message: i18n.t(customError.errorCode), description })
  } else {
    errorHandler(customError)
  }
}
