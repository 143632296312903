import { createSlice } from '@reduxjs/toolkit'
import { estimateOptionApi } from 'services/api'
import { ModalStatus } from 'models/enum'

const initialState = {
  estimateOptionList: [],
  estimateOption: {},
  estimateOptionModalStatus: ModalStatus.Hidden,
  isListLoading: false,
  isEntityLoading: false,
  error: null
}

const estimateOptionSlice = createSlice({
  name: 'estimateoption',
  initialState,
  reducers: {
    // LIST
    getEstimateOptionListStart (state) {
      state.error = null
      state.isListLoading = true
    },
    getEstimateOptionListSuccess (state, { payload }) {
      state.estimateOptionList = payload
      state.error = null
      state.isListLoading = false
    },
    getEstimateOptionListFailed (state, { payload }) {
      state.estimateOptionList = []
      state.error = payload.error
      state.isListLoading = false
    },
    // GET
    getEstimateOptionStart (state) {
      state.error = null
      state.isEntityLoading = true
    },
    getEstimateOptionSuccess (state, { payload }) {
      state.estimateOption = payload
      state.error = null
      state.isEntityLoading = false
    },
    getEstimateOptionFailed (state, { payload }) {
      state.estimateOption = {}
      state.error = payload.error
      state.isEntityLoading = false
    },
    // CREATE
    createEstimateOptionStart (state) {
      state.error = null
      state.isEntityLoading = true
    },
    createEstimateOptionSuccess (state) {
      state.estimateOption = {}
      state.error = null
      state.estimateOptionModalStatus = ModalStatus.Hidden
      state.isEntityLoading = false
    },
    createEstimateOptionFailed (state, { payload }) {
      state.error = payload.error
      state.isEntityLoading = false
    },
    // EDIT
    editEstimateOptionStart (state) {
      state.error = null
      state.isEntityLoading = true
    },
    editEstimateOptionSuccess (state) {
      state.estimateOption = {}
      state.error = null
      state.estimateOptionModalStatus = ModalStatus.Hidden
      state.isEntityLoading = false
    },
    editEstimateOptionFailed (state, { payload }) {
      state.error = payload.error
      state.isEntityLoading = false
    },
    // DELETE
    deleteEstimateOptionSuccess (state) {
      state.error = null
    },
    deleteEstimateOptionFailed (state, { payload }) {
      state.error = payload.error
    },
    setEstimateOptionModalStatus (state, { payload }) {
      state.estimateOptionModalStatus = payload
      if (payload === ModalStatus.Hidden) {
        state.estimateOption = {}
      }
    },
    clear (state) {
      state.estimateOptionList = []
      state.estimateOption = {}
      state.estimateOptionModalStatus = ModalStatus.Hidden
      state.error = null
    }
  }
})

const {
  getEstimateOptionListStart,
  getEstimateOptionListSuccess,
  getEstimateOptionListFailed,
  getEstimateOptionStart,
  getEstimateOptionSuccess,
  getEstimateOptionFailed,
  createEstimateOptionStart,
  createEstimateOptionSuccess,
  createEstimateOptionFailed,
  editEstimateOptionStart,
  editEstimateOptionSuccess,
  editEstimateOptionFailed,
  deleteEstimateOptionSuccess,
  deleteEstimateOptionFailed,
  setEstimateOptionModalStatus,
  clear
} = estimateOptionSlice.actions

const getEstimateOptionList = () => async dispatch => {
  try {
    dispatch(getEstimateOptionListStart())
    const estimateOptionList = await estimateOptionApi.getAllEstimateOption()
    dispatch(getEstimateOptionListSuccess(estimateOptionList))
  } catch (error) {
    dispatch(getEstimateOptionListFailed({ error }))
  }
}

const getEstimateOption = (id) => async dispatch => {
  try {
    dispatch(getEstimateOptionStart())
    const estimateOption = await estimateOptionApi.getEstimateOption({ id })
    dispatch(getEstimateOptionSuccess(estimateOption))
  } catch (error) {
    dispatch(getEstimateOptionFailed({ error }))
  }
}

const createEstimateOption = (createEstimateOptionDto) => async dispatch => {
  try {
    dispatch(createEstimateOptionStart())
    await estimateOptionApi.createEstimateOption({ createEstimateOptionDto })
    dispatch(createEstimateOptionSuccess())
    dispatch(getEstimateOptionList())
  } catch (error) {
    dispatch(createEstimateOptionFailed({ error }))
  }
}

const editEstimateOption = (id, updateEstimateOptionDto) => async dispatch => {
  try {
    dispatch(editEstimateOptionStart())
    await estimateOptionApi.editEstimateOption({ id, updateEstimateOptionDto })
    dispatch(editEstimateOptionSuccess())
    dispatch(getEstimateOptionList())
  } catch (error) {
    dispatch(editEstimateOptionFailed({ error }))
  }
}

const deleteEstimateOption = (id) => async dispatch => {
  try {
    await estimateOptionApi.deleteEstimateOption({ id })
    dispatch(deleteEstimateOptionSuccess())
    dispatch(getEstimateOptionList())
  } catch (error) {
    dispatch(deleteEstimateOptionFailed({ error }))
  }
}

export {
  getEstimateOptionList,
  getEstimateOption,
  createEstimateOption,
  editEstimateOption,
  deleteEstimateOption,
  setEstimateOptionModalStatus,
  clear
}

export default estimateOptionSlice.reducer
