import { createSlice } from '@reduxjs/toolkit'
import { predictionReportageApi, scheduleLogApi, scheduleMetadataApi } from 'services/api'

const initialState = {
  waitingScheduleList: [],
  isDownloadReportageFileLoading: false,
  isUploadAckOrCnfLoading: false,
  isWaitingScheduleListLoading: false,
  error: null
}

const reportageSlice = createSlice({
  name: 'schedulereportage',
  initialState,
  reducers: {
    downloadReportageFileStart (state) {
      state.isDownloadReportageFileLoading = true
    },
    downloadReportageFileSuccess (state) {
      state.isDownloadReportageFileLoading = false
      state.error = null
    },
    downloadReportageFileFailed (state, { payload }) {
      state.isDownloadReportageFileLoading = false
      state.error = payload.error
    },
    uploadAckOrCnfStart (state) {
      state.isUploadAckOrCnfLoading = true
    },
    uploadAckOrCnfSuccess (state) {
      state.isUploadAckOrCnfLoading = false
      state.error = null
    },
    uploadAckOrCnfFailed (state, { payload }) {
      state.isUploadAckOrCnfLoading = false
      state.error = payload.error
    },
    getWaitingScheduleListStart (state) {
      state.isWaitingScheduleListLoading = true
    },
    getWaitingScheduleListSuccess (state, { payload }) {
      state.isWaitingScheduleListLoading = false
      state.waitingScheduleList = payload
      state.error = null
    },
    getWaitingScheduleListFailed (state, { payload }) {
      state.isWaitingScheduleListLoading = false
      state.waitingScheduleList = []
      state.error = payload.error
    },
    clear (state) {
      state.reportageList = []
      state.waitingScheduleList = []
    }
  }
})

const {
  downloadReportageFileStart,
  downloadReportageFileSuccess,
  downloadReportageFileFailed,
  uploadAckOrCnfStart,
  uploadAckOrCnfSuccess,
  uploadAckOrCnfFailed,
  getWaitingScheduleListStart,
  getWaitingScheduleListSuccess,
  getWaitingScheduleListFailed,
  clear
} = reportageSlice.actions

const downloadReportageToMek = () => async dispatch => {
  try {
    dispatch(downloadReportageFileStart())
    const reportageFile = await predictionReportageApi.downloadReportageToMek({ responseType: 'blob' })
    dispatch(downloadReportageFileSuccess())
    dispatch(getWaitingScheduleList())
    return reportageFile
  } catch (error) {
    dispatch(downloadReportageFileFailed({ error }))
    return null
  }
}

const uploadAckOrCnf = (file) => async dispatch => {
  try {
    dispatch(uploadAckOrCnfStart())
    await scheduleLogApi.uploadAckOrCnf({ file })
    dispatch(uploadAckOrCnfSuccess())
    dispatch(getWaitingScheduleList())
    return true
  } catch (error) {
    dispatch(uploadAckOrCnfFailed({ error }))
  }
}

const getWaitingScheduleList = (params) => async dispatch => {
  try {
    dispatch(getWaitingScheduleListStart())
    const waitingScheduleList = await scheduleMetadataApi.waitingForUploadScheduleMetadata(params)
    dispatch(getWaitingScheduleListSuccess(waitingScheduleList))
  } catch (error) {
    dispatch(getWaitingScheduleListFailed({ error }))
  }
}

export {
  downloadReportageToMek,
  uploadAckOrCnf,
  getWaitingScheduleList,
  clear
}

export default reportageSlice.reducer
