/* eslint-disable quotes */
import React, { Suspense, lazy, useEffect } from 'react'
import { createBrowserHistory } from 'history'
import { Route, Switch, useLocation, withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PublicRoute from './PublicRoute'
import PrivateRoute from './PrivateRoute'
import LandingComponent from 'components/views/Landing/LandingComponent'
import Header from 'components/layout/Header/HeaderComponent'
import Footer from 'components/layout/Footer/FooterComponent'
import SpinnerWidget from 'components/widgets/Spinner/SpinnerWidget'
import routes from './routes'
import 'assets/scss/main.scss'
import { update } from 'store/auth/auth.slice'

const NotFound = lazy(() => import('./NotFound'))
const ServerError = lazy(() => import('./ServerError'))

const Routes = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { isLoggedIn, isUpdating } = useSelector(state => state.auth)

  useEffect(() => {
    dispatch(update())
  }, [dispatch])

  const FallbackSpinner = (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <SpinnerWidget spinning />
    </div>
  )

  const renderPrivateRoutes = (routes, parentName) => {
    return routes.map((route) => {
      const { children, ...routeProps } = route
      if (children?.length) {
        return renderPrivateRoutes(children, routeProps.name)
      } else {
        const titleKey = parentName
          ? `navigation.${parentName}.${route.name}` : `navigation.${route.name}.title`
        return (
          <PrivateRoute
            exact
            key={route.name}
            titleKey={titleKey}
            {...routeProps}
          />
        )
      }
    })
  }

  return (
    <>
      <Header />
      <main className="layout">
        <Suspense fallback={FallbackSpinner}>
          {!isUpdating && (
            <Switch location={location}>
              <PublicRoute
                exact
                path="/"
                component={LandingComponent}
                titleKey="navigation.landing.title"
              />
              {isLoggedIn && renderPrivateRoutes(routes)}
              <ServerError exact path="/error" />
              <Route render={() => <NotFound />} />
            </Switch>
          )}
        </Suspense>
      </main>
      <Footer />
    </>
  )
}

export default withRouter(Routes)

const history = createBrowserHistory()

history.listen(({ hash, pathname }) => {
  if (pathname === '/' && !hash) {
    window.scrollTo(0, 0)
  }

  if (hash) {
    const element = document.getElementById(hash)
    if (element) {
      window.scrollTo(0, element.offsetTop - 50)
    }
  }
})

export {
  history
}
