import { createSlice } from '@reduxjs/toolkit'
import { powerPlantApi } from 'services/api'
import { ModalStatus } from 'models/enum'
import { refreshPowerPlant } from './powerplant.slice'

const initialState = {
  intervalSunCollectorList: [],
  intervalConfigList: [],
  intervalConfig: {},
  intervalConfigModalStatus: ModalStatus.Hidden,
  activeTabKey: 'default',
  error: null,
  isIntervalConfigLoading: false,
  isIntervalSunCollectorListLoading: false
}

const intervalSunCollectorSlice = createSlice({
  name: 'intervalconfig',
  initialState,
  reducers: {
    getIntervalSunCollectorListStarted (state) {
      state.isIntervalSunCollectorListLoading = true
    },
    getIntervalSunCollectorListSuccess (state, { payload }) {
      state.intervalSunCollectorList = payload
      state.isIntervalSunCollectorListLoading = false
      state.error = null
    },
    getIntervalSunCollectorListFailed (state, { payload }) {
      state.intervalSunCollectorList = []
      state.isIntervalSunCollectorListLoading = false
      state.error = payload.error
    },
    getIntervalConfigListStarted (state) {
      state.isIntervalConfigLoading = true
    },
    getIntervalConfigListSuccess (state, { payload }) {
      state.intervalConfigList = payload
      state.isIntervalConfigLoading = false
      state.error = null
    },
    getIntervalConfigListFailed (state, { payload }) {
      state.intervalConfigList = []
      state.isIntervalConfigLoading = false
      state.error = payload.error
    },
    createIntervalConfigSuccess (state) {
      state.intervalConfig = {}
      state.error = null
      state.intervalConfigModalStatus = ModalStatus.Hidden
      state.isEntityLoading = false
    },
    createIntervalConfigFailed (state, { payload }) {
      state.error = payload.error
      state.isEntityLoading = false
    },
    editIntervalConfigSuccess (state) {
      state.intervalConfig = {}
      state.error = null
      state.intervalConfigModalStatus = ModalStatus.Hidden
      state.isEntityLoading = false
    },
    editIntervalConfigFailed (state, { payload }) {
      state.error = payload.error
      state.isEntityLoading = false
    },
    deleteIntervalConfigSuccess (state) {
      state.intervalConfig = {}
      state.error = null
      state.intervalConfigModalStatus = ModalStatus.Hidden
      state.isEntityLoading = false
      state.activeTabKey = 'default'
    },
    deleteIntervalConfigFailed (state, { payload }) {
      state.error = payload.error
      state.isEntityLoading = false
    },
    setIntervalConfigPowerPlantId (state, { payload }) {
      state.intervalConfig.powerPlantId = payload
    },
    setIntervalConfigModalStatus (state, { payload }) {
      state.intervalConfigModalStatus = payload
      if (payload === ModalStatus.Hidden) {
        state.intervalConfig = {}
      }
    },
    setIntervalConfigLoading (state) {
      state.isEntityLoading = true
    },
    setIntervalConfig (state, { payload }) {
      state.intervalConfig = payload
    },
    setActiveTabKey (state, { payload }) {
      state.activeTabKey = payload
    },
    setActiveIntervalSunCollectorList (state, { payload }) {
      state.intervalSunCollectorList = state.intervalSunCollectorList.map(intervalSunCollector => ({
        ...intervalSunCollector,
        isActive: payload
      }))
    },
    inactivateIntervalConfigSuccess (state, { payload }) {
      state.error = null
      state.intervalSunCollectorList = state.intervalSunCollectorList.map(intervalSunCollector => ({
        ...intervalSunCollector,
        isActive: intervalSunCollector.id === payload ? false : intervalSunCollector.isActive
      }))
    },
    inactivateIntervalConfigFailed (state, { payload }) {
      state.error = payload.error
    },
    activateIntervalConfigSuccess (state, { payload }) {
      state.error = null
      state.intervalSunCollectorList = state.intervalSunCollectorList.map(intervalSunCollector => ({
        ...intervalSunCollector,
        isActive: intervalSunCollector.id === payload ? true : intervalSunCollector.isActive
      }))
    },
    activateIntervalConfigFailed (state, { payload }) {
      state.error = payload.customError
    },
    clear (state) {
      state.intervalSunCollectorList = []
      state.intervalConfigList = []
      state.intervalConfig = {}
      state.activeTabKey = 'default'
      state.intervalConfigModalStatus = ModalStatus.Hidden
      state.error = null
    }
  }
})

const {
  getIntervalConfigListStarted,
  getIntervalConfigListSuccess,
  getIntervalConfigListFailed,
  getIntervalSunCollectorListStarted,
  getIntervalSunCollectorListSuccess,
  getIntervalSunCollectorListFailed,
  createIntervalConfigSuccess,
  createIntervalConfigFailed,
  editIntervalConfigSuccess,
  editIntervalConfigFailed,
  deleteIntervalConfigSuccess,
  deleteIntervalConfigFailed,
  setIntervalConfigModalStatus,
  setIntervalConfigLoading,
  setIntervalConfigPowerPlantId,
  setIntervalConfig,
  setActiveTabKey,
  setActiveIntervalSunCollectorList,
  inactivateIntervalConfigSuccess,
  inactivateIntervalConfigFailed,
  activateIntervalConfigSuccess,
  activateIntervalConfigFailed,
  clear
} = intervalSunCollectorSlice.actions

const getIntervalConfigList = (powerPlantId) => async dispatch => {
  try {
    dispatch(getIntervalConfigListStarted())
    const intervalConfigList = await powerPlantApi.getIntervalConfigurations({ powerPlantId })
    dispatch(getIntervalConfigListSuccess(intervalConfigList))
  } catch (error) {
    dispatch(getIntervalConfigListFailed({ error }))
  }
}

const getIntervalSunCollectorList = (powerPlantId, intervalConfigurationId) => async dispatch => {
  try {
    dispatch(getIntervalSunCollectorListStarted())
    const intervalSunCollectorList = await powerPlantApi.getIntervalConfiguredPowerPlantRows({
      powerPlantId,
      intervalConfigurationId
    })
    dispatch(getIntervalSunCollectorListSuccess(intervalSunCollectorList))
  } catch (error) {
    dispatch(getIntervalSunCollectorListFailed({ error }))
  }
}

const createIntervalConfig = (powerPlantId, createPowerPlantIntervalDto) => async dispatch => {
  try {
    dispatch(setIntervalConfigLoading())
    const response = await powerPlantApi.createIntervalConfig({
      powerPlantId,
      createPowerPlantIntervalDto
    })
    dispatch(createIntervalConfigSuccess())
    dispatch(getIntervalConfigList(powerPlantId))
    dispatch(setActiveTabKey(`interval-${response.id}`))
  } catch (error) {
    dispatch(createIntervalConfigFailed({ error }))
  }
}

const editIntervalConfig = (powerPlantId, id, updatePowerPlantIntervalDto) =>
  async dispatch => {
    try {
      dispatch(setIntervalConfigLoading())
      await powerPlantApi.editIntervalConfig({
        id,
        powerPlantId,
        updatePowerPlantIntervalDto
      })
      dispatch(editIntervalConfigSuccess())
      dispatch(getIntervalConfigList(powerPlantId))
    } catch (error) {
      dispatch(editIntervalConfigFailed({ error }))
    }
  }

const deleteIntervalConfig = (powerPlantId, id, updatePowerPlantIntervalDto) =>
  async dispatch => {
    try {
      dispatch(setIntervalConfigLoading())
      await powerPlantApi.editIntervalConfig({
        id,
        powerPlantId,
        updatePowerPlantIntervalDto
      })
      dispatch(deleteIntervalConfigSuccess())
      dispatch(getIntervalConfigList(powerPlantId))
    } catch (error) {
      dispatch(deleteIntervalConfigFailed({ error }))
    }
  }

const prepareCreateModal = (powerPlantId) => async dispatch => {
  dispatch(setIntervalConfigModalStatus(ModalStatus.Create))
  dispatch(setIntervalConfigPowerPlantId(powerPlantId))
}

const prepareEditModal = (powerPlantId, intervalConfig) => async dispatch => {
  dispatch(setIntervalConfigModalStatus(ModalStatus.Edit))
  dispatch(setIntervalConfig({ ...intervalConfig, powerPlantId }))
}

const inactivateIntervalConfig = (id, powerPlantId) => async dispatch => {
  try {
    await powerPlantApi.inactivatePowerPlantRow({ powerPlantId, id })
    dispatch(inactivateIntervalConfigSuccess(id))
    dispatch(refreshPowerPlant(powerPlantId))
  } catch (error) {
    dispatch(inactivateIntervalConfigFailed({ error }))
  }
}

const activateIntervalConfig = (id, powerPlantId) => async dispatch => {
  try {
    await powerPlantApi.activatePowerPlantRow({ powerPlantId, id })
    dispatch(activateIntervalConfigSuccess(id))
    dispatch(refreshPowerPlant(powerPlantId))
  } catch (error) {
    dispatch(activateIntervalConfigFailed({ error }))
  }
}

export {
  getIntervalConfigList,
  getIntervalSunCollectorList,
  createIntervalConfig,
  editIntervalConfig,
  deleteIntervalConfig,
  setIntervalConfigModalStatus,
  prepareCreateModal,
  prepareEditModal,
  setActiveTabKey,
  setActiveIntervalSunCollectorList,
  inactivateIntervalConfig,
  activateIntervalConfig,
  clear
}

export default intervalSunCollectorSlice.reducer
