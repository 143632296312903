export const COOKIE_PREFIX = 'fiek-met'

export const parseToken = (token) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace('-', '+').replace('_', '/')
  var base64Json = decodeURIComponent(atob(base64).split('').map((c) => {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(base64Json)
}

export const getInfoFromToken = (accessToken) => {
  const token = parseToken(accessToken)
  const keys = Object.keys(token)
  const userKey = keys.find(key => key.endsWith('name'))
  const roleKey = keys.find(key => key.endsWith('role'))
  const profileId = keys.find(key => key.endsWith('groupsid'))

  return {
    userId: Number(token.sub),
    username: token[userKey],
    role: token[roleKey],
    expires: token.exp,
    profileId: Number(token[profileId])
  }
}
