import { cloneDeep } from 'lodash'

const fillWithKey = (key) => [...new Array(6)].map(() => ({ [key]: undefined }))

const emptyEfficiencyMatrix = () => cloneDeep({
  loads: [fillWithKey('load')],
  efficiencySource: [
    {
      type: 'effMinValues',
      voltage: undefined,
      efficiencyValues: fillWithKey('efficiency')
    },
    {
      type: 'effNomValues',
      voltage: undefined,
      efficiencyValues: fillWithKey('efficiency')
    },
    {
      type: 'effMaxValues',
      voltage: undefined,
      efficiencyValues: fillWithKey('efficiency')
    }
  ]
})

const prepareEfficiencyMatrix = (payload) => {
  const { id, inverterId, loads, effMinValues, effNomValues, effMaxValues } = payload
  return {
    id,
    inverterId,
    loads: [loads],
    efficiencySource: [
      {
        ...effMinValues,
        type: 'effMinValues'
      },
      {
        ...effNomValues,
        type: 'effNomValues'
      },
      {
        ...effMaxValues,
        type: 'effMaxValues'
      }
    ]
  }
}

export {
  emptyEfficiencyMatrix,
  prepareEfficiencyMatrix
}
