import { createSlice } from '@reduxjs/toolkit'
import { set } from 'lodash'
import { MekhCategory, ModalStatus } from 'models/enum'
import { mekhDataApi } from 'services/api'

const initialState = {
  mekhDataV300List: {},
  isMekhDataV300ListLoading: false,
  isEntityLoading: false,
  createV300DataStatus: null,
  mekhDataV300: {},
  mekhDataV300ModalStatus: ModalStatus.Hidden,
  error: null
}

const mekhDataV300 = createSlice({
  name: 'mekhdatav300',
  initialState,
  reducers: {
    getMekhDataV300ListStarted (state) {
      state.isMekhDataV300ListLoading = true
    },
    getMekhDataV300ListSuccess (state, { payload }) {
      state.mekhDataV300List = payload
      state.isMekhDataV300ListLoading = false
      state.error = null
    },
    getMekhDataV300ListFailed (state, { payload }) {
      state.mekhDataV300List = {}
      state.isMekhDataV300ListLoading = false
      state.error = payload.error
    },
    getMekhDataV300Success (state, { payload }) {
      state.mekhDataV300 = payload
      state.error = null
      state.isEntityLoading = false
    },
    getMekhDataV300Failed (state, { payload }) {
      state.mekhDataV300 = {}
      state.error = payload.error
      state.isEntityLoading = false
    },
    createMekhDataV300Success (state) {
      state.mekhDataV300 = {}
      state.error = null
      state.mekhDataV300ModalStatus = ModalStatus.Hidden
      state.isEntityLoading = false
    },
    createMekhDataV300Failed (state, { payload }) {
      state.error = payload.error
      state.isEntityLoading = false
    },
    editMekhDataV300Success (state) {
      state.mekhDataV300 = {}
      state.error = null
      state.mekhDataV300ModalStatus = ModalStatus.Hidden
      state.isEntityLoading = false
    },
    editMekhDataV300Failed (state, { payload }) {
      state.error = payload.error
      state.isEntityLoading = false
    },
    modifyMekhDataV300Cell (state, { payload }) {
      const { tableIndex, dto, modifiedValue } = payload
      const { key, value } = modifiedValue
      const dataRows = state.mekhDataV300.dataSections[tableIndex].dataRows
      const foundRow = dataRows.find(row => row.technicalId === dto.technicalId)
      set(foundRow, key, value)
    },
    downloadMekhV300FileSuccess (state) {
      state.error = null
    },
    downloadMekhV300FileFailed (state, { payload }) {
      state.error = payload.error
    },
    setMekhDataV300ModalStatus (state, { payload }) {
      state.mekhDataV300ModalStatus = payload
      if (payload === ModalStatus.Hidden) {
        state.mekhDataV300 = {}
      }
    },
    setMekhDataV300Loading (state) {
      state.isEntityLoading = true
    },
    setCreateV300DataStatusSuccess (state, { payload }) {
      state.createV300DataStatus = payload
      state.error = null
    },
    setCreateV300DataStatusFailed (state, { payload }) {
      state.createV300DataStatus = null
      state.error = payload.error
    },
    clear (state) {
      state.mekhDataV300List = {}
      state.mekhDataV300ModalStatus = ModalStatus.Hidden
      state.error = null
    }
  }
})

const {
  getMekhDataV300ListStarted,
  getMekhDataV300ListSuccess,
  getMekhDataV300ListFailed,
  getMekhDataV300Success,
  getMekhDataV300Failed,
  createMekhDataV300Success,
  createMekhDataV300Failed,
  editMekhDataV300Success,
  editMekhDataV300Failed,
  modifyMekhDataV300Cell,
  downloadMekhV300FileSuccess,
  downloadMekhV300FileFailed,
  setMekhDataV300ModalStatus,
  setMekhDataV300Loading,
  setCreateV300DataStatusSuccess,
  setCreateV300DataStatusFailed,
  clear
} = mekhDataV300.actions

const getMekhDataV300List = (params) => async dispatch => {
  try {
    dispatch(getMekhDataV300ListStarted())
    const mekhDataV300List = await mekhDataApi.getPaginatedMekhV300Data(params)
    dispatch(getMekhDataV300ListSuccess(mekhDataV300List))
  } catch (error) {
    dispatch(getMekhDataV300ListFailed({ error }))
  }
}

const getMekhDataV300 = (id) => async dispatch => {
  try {
    dispatch(setMekhDataV300Loading())
    const mekhDataV300 = await mekhDataApi.getMekhV300Data({ id })
    dispatch(getMekhDataV300Success(mekhDataV300))
  } catch (error) {
    dispatch(getMekhDataV300Failed({ error }))
  }
}

const getLatestMekhDataV300 = (params) => async dispatch => {
  try {
    dispatch(setMekhDataV300Loading())
    const mekhDataV300 = await mekhDataApi.getLatestMekhV300Data(params)
    dispatch(getMekhDataV300Success(mekhDataV300))
  } catch (error) {
    dispatch(getMekhDataV300Failed({ error }))
  }
}

const createMekhDataV300 = (createMekhV300Dto, params) => async dispatch => {
  try {
    dispatch(setMekhDataV300Loading())
    await mekhDataApi.createMekhV300Data({ createMekhV300Dto })
    dispatch(createMekhDataV300Success())
    dispatch(getMekhDataV300List(params))
    dispatch(getCreateV300DataStatus(params))
  } catch (error) {
    dispatch(createMekhDataV300Failed({ error }))
  }
}

const editMekhDataV300 = (updateMekhV300Dto, params) => async (dispatch) => {
  try {
    dispatch(setMekhDataV300Loading())
    await mekhDataApi.updateMekhV300Data({ updateMekhV300Dto })
    dispatch(editMekhDataV300Success())
    dispatch(getMekhDataV300List(params))
  } catch (error) {
    dispatch(editMekhDataV300Failed({ error }))
  }
}

const downloadMekhV300File = (id, params) => async dispatch => {
  try {
    const mekhV300File = await mekhDataApi.downloadMekhV300({ id }, { responseType: 'blob' })
    dispatch(downloadMekhV300FileSuccess())
    dispatch(getMekhDataV300List(params))
    return mekhV300File
  } catch (error) {
    dispatch(downloadMekhV300FileFailed({ error }))
    return null
  }
}

const getCreateV300DataStatus = (params) => async dispatch => {
  try {
    const payload = { ...params, category: MekhCategory.V300 }
    const { createMekhDataStatus } = await mekhDataApi.isCreateMekhDataEnabled(payload)
    dispatch(setCreateV300DataStatusSuccess(createMekhDataStatus))
  } catch (error) {
    dispatch(setCreateV300DataStatusFailed({ error }))
    return null
  }
}

export {
  getMekhDataV300List,
  getMekhDataV300,
  getLatestMekhDataV300,
  createMekhDataV300,
  editMekhDataV300,
  modifyMekhDataV300Cell,
  getCreateV300DataStatus,
  downloadMekhV300File,
  setMekhDataV300ModalStatus,
  clear
}

export default mekhDataV300.reducer
