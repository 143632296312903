import { lazy } from 'react'
import store from 'store'
import { AnalyzesTab, EstimateType, MekhCategory, Role } from 'models/enum'
import { getToday, getTodayUTC, getTomorrowUTC, getUTC } from 'utils/date'
import { defaultParamWithQuery } from 'utils/url'

const OverviewScreenComponent = lazy(() =>
  import('components/views/OverviewScreen/OverviewScreenComponent')
)
const MasterdataComponent = lazy(() =>
  import('components/views/Masterdata/MasterdataComponent')
)
const ReportageComponent = lazy(() =>
  import('components/views/Reportage/ReportageComponent')
)
const PrognosisChartComponent = lazy(() =>
  import('components/views/PrognosisChart/PrognosisChartComponent')
)
const EstimateTestComponent = lazy(() =>
  import('components/views/EstimateTest/EstimateComponent')
)
const ProfileComponent = lazy(() =>
  import('components/views/Profile/ProfileComponent')
)
const DataImportComponent = lazy(() =>
  import('components/views/DataImport/DataImportComponent')
)
const LogComponent = lazy(() => import('components/views/Log/LogComponent'))
const EstimateScheduleComponent = lazy(() =>
  import('components/views/EstimateSchedule/EstimateScheduleComponent')
)
const ScheduleMessageComponent = lazy(() =>
  import('components/views/ScheduleMessage/ScheduleMessageComponent')
)
const ScheduleMessageCategoryComponent = lazy(() =>
  import(
    'components/views/ScheduleMessageCategory/ScheduleMessageCategoryComponent'
  )
)
const ReportageToMekComponent = lazy(() =>
  import('components/views/ReportageToMek/ReportageToMekComponent')
)
const AnalyzesTabsComponent = lazy(() =>
  import('components/views/Analyzes/AnalyzesTabsComponent')
)
const SystemLogComponent = lazy(() =>
  import('components/views/SystemLog/SystemLogComponent')
)
const AnnualEstimateComponent = lazy(() =>
  import('components/views/AnnualEstimate/AnnualEstimateComponent')
)
const CompanyProfileComponent = lazy(() =>
  import('components/views/CompanyProfile/CompanyProfileComponent')
)
const ProductionDiffComponent = lazy(() =>
  import('components/views/ProductionDiff/ProductionDiffComponent')
)
const PrognosisGroupedChartComponent = lazy(() =>
  import(
    'components/views/PrognosisGroupedChart/PrognosisGroupedChartComponent'
  )
)
const ProductionDataComponent = lazy(() =>
  import('components/views/ProductionData/ProductionDataComponent')
)
const ActivityListComponent = lazy(() =>
  import('components/views/ActivityList/ActivityListComponent')
)
const PrognosisGenerateComponent = lazy(() =>
  import('components/views/PrognosisGenerate/PrognosisGenerateComponent')
)
const ChangePasswordComponent = lazy(() =>
  import('components/views/ChangePassword/ChangePasswordComponent')
)
const ScheduleTabsComponent = lazy(() =>
  import('components/views/Schedule/ScheduleTabsComponent')
)
const ScheduleManagementTabsComponent = lazy(() =>
  import('components/views/ScheduleManagement/ScheduleManagementTabsComponent')
)
const OperatorImportComponent = lazy(() =>
  import('components/views/OperatorImport/OperatorImportComponent')
)
const PrognosisSettingsTabComponent = lazy(() =>
  import('components/views/PrognosisSettings/PrognosisSettingsTabsComponent')
)
const PrognosisValidateComponent = lazy(() =>
  import('components/views/PrognosisValidate/PrognosisValidateComponent')
)
const NeuralLearningTabComponent = lazy(() =>
  import('components/views/NeuralLearning/NeuralLearningTabComponent')
)
const CompareResultsComponent = lazy(() =>
  import(
    'components/views/NeuralLearning/CompareResults/CompareResultsComponent'
  )
)
const MekhDataTabsComponent = lazy(() =>
  import('components/views/MekhData/MekhDataTabsComponent')
)
// const InverterMonitoringComponent = lazy(() => import('components/views/InverterMonitoring/InverterMonitoringComponent'))
const InverterMonitoringDetailComponent = lazy(() =>
  import(
    'components/views/InverterMonitoring/InverterMonitoringDetailComponent'
  )
)
const EstimateErrorsComponent = lazy(() =>
  import('components/views/EstimateErrors/EstimateErrorsComponent')
)
const ForecastSyncComponent = lazy(() =>
  import('components/views/ForecastSync/ForecastSyncComponent')
)
const NotFound = lazy(() => import('./NotFound'))

export default [
  {
    name: 'notfound',
    path: '/notfound',
    component: NotFound,
    standalone: true
  },
  {
    name: 'data',
    requiredRole: [Role.Admin, Role.Operator, Role.SysAdmin],
    children: [
      {
        name: 'masterdata',
        path: '/data/masterdata/:tabKey',
        defaultParam: 'balance-responsible',
        requiredRole: [Role.Admin, Role.Operator],
        component: MasterdataComponent
      },
      {
        name: 'import',
        path: '/data/import',
        requiredRole: [Role.Admin, Role.Operator],
        component: DataImportComponent
      },
      {
        name: 'operator-import',
        path: '/data/operator-import',
        requiredRole: [Role.SysAdmin],
        component: OperatorImportComponent
      },
      {
        name: 'mekh',
        path: '/data/mekh/:tabKey',
        defaultParam: defaultParamWithQuery(MekhCategory.V300, {
          targetDateMonth: getUTC(getToday().date(1))
        }),
        requiredRole: [Role.Admin, Role.Operator, Role.Office],
        component: MekhDataTabsComponent
      }
    ]
  },
  {
    name: 'weather',
    requiredRole: [Role.SysAdmin],
    children: [
      {
        name: 'forecast-sync',
        path: '/weather/forecast-sync',
        requiredRole: [Role.SysAdmin],
        component: ForecastSyncComponent
      }
    ]
  },
  // MVMFIEKMET-3041 - Inverter eltüntetése
  // {
  //   name: 'inverter-monitoring',
  //   requiredRole: [Role.SysAdmin],
  //   path: '/inverter-monitoring',
  //   defaultParam: defaultParamWithQuery({
  //     status: ['isError']
  //   }),
  //   component: InverterMonitoringComponent
  // },
  {
    name: 'inverter-monitoring-detail',
    requiredRole: [Role.SysAdmin],
    path: '/inverter-monitoring-detail/:powerPlantId(\\d+)',
    component: InverterMonitoringDetailComponent,
    standalone: true
  },
  {
    name: 'prognosis',
    requiredRole: [Role.SysAdmin, Role.Admin, Role.Operator],
    children: [
      {
        name: 'generate',
        path: '/prognosis/generate/:tabKey',
        defaultParam: 'manual',
        requiredRole: [Role.Admin, Role.Operator],
        component: PrognosisGenerateComponent
      },
      {
        name: 'schedule',
        path: '/prognosis/schedule/:tabKey',
        defaultParam: 'manual',
        requiredRole: [Role.Admin, Role.Operator],
        component: EstimateScheduleComponent
      },
      {
        name: 'test',
        path:
          '/prognosis/test/:targetDate(\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}Z)?/:isFactWeather(fact|estimate)?/:forecastSourceCode?',
        defaultParam: 'fact',
        requiredRole: [Role.Admin, Role.Operator],
        component: EstimateTestComponent
      },
      {
        name: 'grouped-chart',
        path: '/prognosis/grouped/:groupId(\\d+)/chart',
        requiredRole: [Role.Admin, Role.Operator],
        component: PrognosisGroupedChartComponent,
        standalone: true
      },
      {
        name: 'chart',
        path: '/prognosis/chart/:estimateLogId(\\d+)',
        requiredRole: [Role.Admin, Role.Operator, Role.Office],
        component: PrognosisChartComponent,
        standalone: true
      },
      {
        name: 'analyzes',
        path: '/prognosis/analyzes/:tabKey',
        defaultParam: defaultParamWithQuery(AnalyzesTab.Custom, {
          deliveryDate: getTomorrowUTC()
        }),
        requiredRole: [Role.Admin, Role.Operator],
        component: AnalyzesTabsComponent
      },
      {
        name: 'settings',
        path: '/prognosis/settings/:tabKey',
        defaultParam: 'provider',
        requiredRole: [Role.Admin, Role.Operator],
        component: PrognosisSettingsTabComponent
      },
      {
        name: 'compare',
        path: '/prognosis/learning/compare',
        requiredRole: [Role.SysAdmin, Role.Admin],
        component: CompareResultsComponent,
        standalone: true
      },
      {
        name: 'learning',
        path: '/prognosis/learning/:tabKey',
        defaultParam: 'learning',
        requiredRole: [Role.SysAdmin],
        component: NeuralLearningTabComponent
      },
      {
        name: 'validate',
        path: '/prognosis/validate',
        requiredRole: [Role.SysAdmin],
        component: PrognosisValidateComponent
      }
    ]
  },
  {
    name: 'schedule',
    requiredRole: [Role.Admin, Role.Operator, Role.SysAdmin],
    children: [
      {
        name: 'generate',
        path: '/schedule/generate/:tabKey',
        defaultParam: defaultParamWithQuery('powerplant', {
          deliveryDate: getTomorrowUTC()
        }),
        requiredRole: [Role.Admin, Role.Operator],
        component: ScheduleTabsComponent
      },
      {
        name: 'management',
        path: '/schedule/management/:tabKey',
        defaultParam: defaultParamWithQuery('powerplant', {
          deliveryDate: getTomorrowUTC()
        }),
        requiredRole: [Role.Admin, Role.Operator],
        component: ScheduleManagementTabsComponent
      },
      {
        name: 'messages',
        path: '/schedule/messages',
        requiredRole: [Role.Admin, Role.Operator],
        component: ScheduleMessageComponent
      },
      {
        name: 'messages-category',
        path: '/schedule/messages-category',
        defaultParam: defaultParamWithQuery({
          deliveryDate: getTomorrowUTC()
        }),
        requiredRole: [Role.SysAdmin],
        component: ScheduleMessageCategoryComponent
      },
      {
        name: 'reportage-to-mek',
        path: '/schedule/reportage-to-mek',
        requiredRole: [Role.SysAdmin],
        component: ReportageToMekComponent
      }
    ]
  },
  {
    name: 'reportage',
    requiredRole: [Role.Admin, Role.Operator, Role.Office],
    children: [
      {
        name: 'analisys',
        path: '/reportage/analisys',
        defaultParam: defaultParamWithQuery({
          reportDate: getTomorrowUTC()
        }),
        requiredRole: [Role.Admin, Role.Operator, Role.Office],
        component: ReportageComponent
      },
      {
        name: 'annualestimate',
        path: '/reportage/annualestimate/:powerPlantId(\\d+)?',
        requiredRole: [Role.Admin, Role.Operator],
        component: AnnualEstimateComponent
      },
      {
        name: 'production-diff',
        path: '/reportage/production-diff',
        defaultParam: defaultParamWithQuery({
          deliveryDate: getTodayUTC()
        }),
        requiredRole: [Role.Admin, Role.Operator],
        component: ProductionDiffComponent
      },
      {
        name: 'production-data',
        path: '/reportage/production-data',
        defaultParam: defaultParamWithQuery({
          targetDate: getTodayUTC()
        }),
        requiredRole: [Role.Admin, Role.Operator],
        component: ProductionDataComponent
      },
      {
        name: 'activity',
        path: '/reportage/activity',
        requiredRole: [Role.Admin, Role.Office],
        component: ActivityListComponent
      }
    ]
  },
  {
    name: 'profile',
    requiredRole: [Role.SysAdmin, Role.Admin],
    children: [
      {
        name: 'edit',
        path: '/profile/edit/:profileId(\\d+)',
        defaultParam: () => store.getState().auth?.profileId,
        requiredRole: [Role.SysAdmin, Role.Admin],
        component: ProfileComponent
      },
      {
        name: 'companies',
        path: '/profile/companies',
        requiredRole: [Role.SysAdmin],
        component: CompanyProfileComponent
      }
    ]
  },
  {
    name: 'log',
    requiredRole: [Role.SysAdmin, Role.Admin, Role.Operator],
    children: [
      {
        name: 'audit',
        path: '/log/audit',
        defaultParam: defaultParamWithQuery({
          functionName: 'Login',
          functionGroup: 'LogIn',
          startDate: getTodayUTC(),
          endDate: getTodayUTC()
        }),
        requiredRole: [Role.SysAdmin, Role.Admin, Role.Operator],
        component: LogComponent
      },
      {
        name: 'system',
        path: '/log/system',
        requiredRole: [Role.SysAdmin],
        component: SystemLogComponent
      },
      {
        name: 'estimate-errors',
        path: '/log/estimate-errors',
        defaultParam: defaultParamWithQuery({
          deliveryDate: getUTC(getToday()),
          estimateType: EstimateType.Intraday
        }),
        requiredRole: [Role.SysAdmin],
        component: EstimateErrorsComponent
      }
    ]
  },
  {
    name: 'overview-screen',
    path: '/overview-screen/:powerPlantId(\\d+)',
    requiredRole: [Role.Admin, Role.Operator],
    component: OverviewScreenComponent,
    standalone: true
  },
  {
    name: 'change-password',
    path: '/change-password',
    requiredRole: [Role.Admin, Role.Operator, Role.Office, Role.SysAdmin],
    component: ChangePasswordComponent,
    standalone: true
  }
]
