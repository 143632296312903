import { createSlice } from '@reduxjs/toolkit'
import { balanceResponsibleApi } from 'services/api'

const initialState = {
  balanceResponsibleList: [],
  isListLoading: false,
  error: null
}

const balanceResponsibleSlice = createSlice({
  name: 'balanceresponsible',
  initialState,
  reducers: {
    getBalanceResponsibleListStart (state) {
      state.isListLoading = true
      state.error = null
    },
    getBalanceResponsibleListSuccess (state, { payload }) {
      state.isListLoading = false
      state.error = null
      state.balanceResponsibleList = payload
    },
    getBalanceResponsibleListFailed (state, { payload }) {
      state.isListLoading = false
      state.error = payload.error
      state.balanceResponsibleList = []
    },
    clear (state) {
      state.balanceResponsibleList = []
      state.error = null
    }
  }
})

const {
  getBalanceResponsibleListStart,
  getBalanceResponsibleListSuccess,
  getBalanceResponsibleListFailed,
  clear
} = balanceResponsibleSlice.actions

const getBalanceResponsibleList = () => async dispatch => {
  try {
    dispatch(getBalanceResponsibleListStart())
    const balanceResponsible = await balanceResponsibleApi.getAllBalanceResponsibleParty()
    dispatch(getBalanceResponsibleListSuccess(balanceResponsible))
  } catch (error) {
    dispatch(getBalanceResponsibleListFailed({ error }))
  }
}

export {
  getBalanceResponsibleList,
  clear
}

export default balanceResponsibleSlice.reducer
