import { createSelector, createSlice } from '@reduxjs/toolkit'
import { analysisApi } from 'services/api'

const initialState = {
  estimateList: [],
  secondaryEstimateList: [],
  longTermDataAnalysis: null,
  analyzedData: null,
  estimateDataSum: null,
  isListLoading: false,
  isAnalyzing: false,
  isEstimateDataSumLoading: false,
  isSecondaryEstimateVisible: false,
  isLongTermDataAnalysisLoading: false,
  isLongTermDataAnalysisExcelResultLoading: false,
  error: null
}

const analyzesSlice = createSlice({
  name: 'analyzes',
  initialState,
  reducers: {
    getEstimateListStart (state) {
      state.error = null
      state.isListLoading = true
    },
    getEstimateListSuccess (state, { payload }) {
      state.estimateList = payload
      state.isListLoading = false
    },
    getEstimateListFailed (state, { payload }) {
      state.estimateList = []
      state.error = payload.error
      state.isListLoading = false
    },
    getSecondaryEstimateListStart (state) {
      state.error = null
      state.isListLoading = true
    },
    getSecondaryEstimateListSuccess (state, { payload }) {
      state.secondaryEstimateList = payload
      state.isListLoading = false
    },
    getSecondaryEstimateListFailed (state, { payload }) {
      state.secondaryEstimateList = []
      state.error = payload.error
      state.isListLoading = false
    },
    getDataAnalysisStart (state) {
      state.error = null
      state.isAnalyzing = true
    },
    getDataAnalysisSuccess (state, { payload }) {
      state.analyzedData = payload
      state.isAnalyzing = false
    },
    getDataAnalysisFailed (state, { payload }) {
      state.analyzedData = null
      state.error = payload.error
      state.isAnalyzing = false
    },
    getEstimateDataSumStart (state) {
      state.error = null
      state.isEstimateDataSumLoading = true
    },
    getEstimateDataSumSuccess (state, { payload }) {
      state.estimateDataSum = payload
      state.isEstimateDataSumLoading = false
    },
    getEstimateDataSumFailed (state, { payload }) {
      state.estimateDataSum = null
      state.error = payload.error
      state.isEstimateDataSumLoading = false
    },
    showSecondaryEstimate (state, { payload }) {
      state.isSecondaryEstimateVisible = payload
    },
    getLongTermDataAnalysisStart (state) {
      state.error = null
      state.isLongTermDataAnalysisLoading = true
    },
    getLongTermDataAnalysisSuccess (state, { payload }) {
      state.isLongTermDataAnalysisLoading = false
      state.longTermDataAnalysis = payload
    },
    getLongTermDataAnalysisFailed (state, { payload }) {
      state.isLongTermDataAnalysisLoading = false
      state.longTermDataAnalysis = null
      state.error = payload.error
    },
    getLongTermDataAnalysisExcelResultStart (state) {
      state.isLongTermDataAnalysisExcelResultLoading = true
    },
    getLongTermDataAnalysisExcelResultSuccess (state) {
      state.isLongTermDataAnalysisExcelResultLoading = false
    },
    getLongTermDataAnalysisExcelResultFailed (state) {
      state.isLongTermDataAnalysisExcelResultLoading = false
    },
    clear (state) {
      state.estimateList = []
      state.analyzedData = null
      state.estimateDataSum = null
      state.longTermDataAnalysis = null
    },
    clearEstimateDateSum (state) {
      state.estimateDataSum = null
    }
  }
})

const {
  getEstimateListStart,
  getEstimateListSuccess,
  getEstimateListFailed,
  getSecondaryEstimateListStart,
  getSecondaryEstimateListSuccess,
  getSecondaryEstimateListFailed,
  getDataAnalysisStart,
  getDataAnalysisSuccess,
  getDataAnalysisFailed,
  getEstimateDataSumStart,
  getEstimateDataSumSuccess,
  getEstimateDataSumFailed,
  getLongTermDataAnalysisStart,
  getLongTermDataAnalysisSuccess,
  getLongTermDataAnalysisFailed,
  getLongTermDataAnalysisExcelResultStart,
  getLongTermDataAnalysisExcelResultSuccess,
  getLongTermDataAnalysisExcelResultFailed,
  showSecondaryEstimate,
  clearEstimateDateSum,
  clear
} = analyzesSlice.actions

const getEstimateList = (powerPlantId, deliveryDate) => async dispatch => {
  try {
    dispatch(getEstimateListStart())
    const estimateList = await analysisApi.getEstimates({ powerPlantId, deliveryDate })
    dispatch(getEstimateListSuccess(estimateList))
  } catch (error) {
    dispatch(getEstimateListFailed({ error }))
  }
}

const getSecondaryEstimateList = (powerPlantId, deliveryDate) => async dispatch => {
  try {
    dispatch(getSecondaryEstimateListStart())
    const secondaryEstimateList = await analysisApi.getSecondaryEstimates({ powerPlantId, deliveryDate })
    dispatch(getSecondaryEstimateListSuccess(secondaryEstimateList))
  } catch (error) {
    dispatch(getSecondaryEstimateListFailed({ error }))
  }
}

const getDataAnalysis = (values) => async (dispatch, getState) => {
  const { isSecondaryEstimateVisible } = getState().analyzes
  const data = {
    isMKP: values.isMKP,
    primaryEstimateParameterEstimateId: values.estimateId,
    primaryEstimateParameterEstimateWeatherFieldsHasTemperature: values.estimateWeatherFieldsHasTemperature || false,
    primaryEstimateParameterEstimateWeatherFieldsHasRadiation: values.estimateWeatherFieldsHasRadiation || false,
    primaryEstimateParameterEstimateWeatherFieldsHasWind: values.estimateWeatherFieldsHasWind || false,
    factWeatherFieldsHasTemperature: values.factWeatherFieldsHasTemperature || false,
    factWeatherFieldsHasRadiation: values.factWeatherFieldsHasRadiation || false,
    factWeatherFieldsHasWind: values.factWeatherFieldsHasWind || false,
    ...(isSecondaryEstimateVisible ? {
      secondaryEstimateParameterEstimateId: values.secondaryEstimateId,
      secondaryEstimateParameterEstimateWeatherFieldsHasTemperature: values.secondaryEstimateWeatherFieldsHasTemperature || false,
      secondaryEstimateParameterEstimateWeatherFieldsHasRadiation: values.secondaryEstimateWeatherFieldsHasRadiation || false,
      secondaryEstimateParameterEstimateWeatherFieldsHasWind: values.secondaryEstimateWeatherFieldsHasWind || false
    } : {
      primaryEstimateParameterHasProductionDifference: values.hasProductionDifference || false
    })
  }
  try {
    dispatch(getDataAnalysisStart())
    const analyzedData = await analysisApi.getDataAnalysis(data)
    dispatch(getDataAnalysisSuccess(analyzedData))
  } catch (error) {
    dispatch(getDataAnalysisFailed({ error }))
  }
}

const getEstimateDataSum = (values) => async dispatch => {
  try {
    dispatch(getEstimateDataSumStart())
    const estimateDataSum = await analysisApi.getEstimateDataSum(values)
    dispatch(getEstimateDataSumSuccess(estimateDataSum))
  } catch (error) {
    dispatch(getEstimateDataSumFailed({ error }))
  }
}

const getLongTermDataAnalysis = (values) => async dispatch => {
  try {
    dispatch(getLongTermDataAnalysisStart())
    const longTermDataAnalysis = await analysisApi.getLongTermDataAnalysis(values)
    dispatch(getLongTermDataAnalysisSuccess(longTermDataAnalysis))
  } catch (error) {
    dispatch(getLongTermDataAnalysisFailed({ error }))
  }
}

const getLongTermDataAnalysisExcelResult = (values) => async dispatch => {
  try {
    dispatch(getLongTermDataAnalysisExcelResultStart())
    const file = await analysisApi.getLongTermDataAnalysisExcelResult(values, { responseType: 'blob' })
    dispatch(getLongTermDataAnalysisExcelResultSuccess(file))
    return file
  } catch (error) {
    dispatch(getLongTermDataAnalysisExcelResultFailed({ error }))
  }
}

const filteredSecondaryEstimateList = (selectedEstimate) => createSelector(
  state => state.analyzes.secondaryEstimateList,
  items => items.filter(item => item.id !== selectedEstimate?.id)
)

export {
  getEstimateList,
  getSecondaryEstimateList,
  getDataAnalysis,
  showSecondaryEstimate,
  getEstimateDataSum,
  getLongTermDataAnalysis,
  getLongTermDataAnalysisExcelResult,
  filteredSecondaryEstimateList,
  clear,
  clearEstimateDateSum
}

export default analyzesSlice.reducer
