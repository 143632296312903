import { createSlice } from '@reduxjs/toolkit'
import { solarPanelApi } from 'services/api'
import { omit } from 'lodash'
import { ModalStatus } from 'models/enum'

const initialState = {
  solarPanelList: [],
  solarPanel: {},
  datasheet: {},
  isDatasheetLoading: false,
  solarPanelModalStatus: ModalStatus.Hidden,
  isListLoading: false,
  isEntityLoading: false,
  error: null
}

const solarPanelSlice = createSlice({
  name: 'solarpanel',
  initialState,
  reducers: {
    getSolarPanelListStart (state) {
      state.error = null
      state.isListLoading = true
    },
    getSolarPanelListSuccess (state, { payload }) {
      state.solarPanelList = payload
      state.error = null
      state.isListLoading = false
    },
    getSolarPanelListFailed (state, { payload }) {
      state.solarPanelList = []
      state.error = payload.error
      state.isListLoading = false
    },
    getSolarPanelSuccess (state, { payload }) {
      state.solarPanel = payload
      state.error = null
      state.isEntityLoading = false
    },
    getSolarPanelFailed (state, { payload }) {
      state.solarPanel = {}
      state.error = payload.error
      state.isEntityLoading = false
    },
    duplicateSolarPanelSuccess (state, { payload }) {
      state.solarPanel = omit(payload, ['id', 'name'])
      state.error = null
      state.isEntityLoading = false
    },
    duplicateSolarPanelFailed (state, { payload }) {
      state.solarPanel = {}
      state.error = payload.error
      state.isEntityLoading = false
    },
    createSolarPanelSuccess (state) {
      state.solarPanel = {}
      state.error = null
      state.solarPanelModalStatus = ModalStatus.Hidden
      state.isEntityLoading = false
    },
    createSolarPanelFailed (state, { payload }) {
      state.error = payload.error
      state.isEntityLoading = false
    },
    editSolarPanelSuccess (state) {
      state.solarPanel = {}
      state.error = null
      state.solarPanelModalStatus = ModalStatus.Hidden
      state.isEntityLoading = false
    },
    editSolarPanelFailed (state, { payload }) {
      state.error = payload.error
      state.isEntityLoading = false
    },
    inactivateSolarPanelSuccess (state) {
      state.error = null
    },
    inactivateSolarPanelFailed (state, { payload }) {
      state.error = payload.error
    },
    activateSolarPanelSuccess (state) {
      state.error = null
    },
    activateSolarPanelFailed (state, { payload }) {
      state.error = payload.error
    },
    getDatasheetStarted (state) {
      state.isDatasheetLoading = true
    },
    getDatasheetSuccess (state, { payload }) {
      state.datasheet = payload
      state.isDatasheetLoading = false
      state.error = null
    },
    getDatasheetFailed (state, { payload }) {
      state.datasheet = {}
      state.isDatasheetLoading = false
      state.error = payload.error
    },
    uploadDatasheetStarted (state) {
      state.isDatasheetLoading = true
    },
    uploadDatasheetSuccess (state) {
      state.isDatasheetLoading = false
    },
    uploadDatasheetFailed (state, { payload }) {
      state.isDatasheetLoading = false
      state.error = payload.error
    },
    deleteDatasheetStarted (state) {
      state.isDatasheetLoading = true
    },
    deleteDatasheetSuccess (state) {
      state.isDatasheetLoading = false
    },
    deleteDatasheetFailed (state, { payload }) {
      state.isDatasheetLoading = false
      state.error = payload.error
    },
    setSolarPanelModalStatus (state, { payload }) {
      state.solarPanelModalStatus = payload
      if (payload === ModalStatus.Hidden) {
        state.solarPanel = {}
      }
    },
    setSolarPanelLoading (state) {
      state.isEntityLoading = true
    },
    clear (state) {
      state.solarPanelList = []
      state.solarPanel = {}
      state.solarPanelModalStatus = ModalStatus.Hidden
      state.error = null
    }
  }
})

const {
  getSolarPanelListStart,
  getSolarPanelListSuccess,
  getSolarPanelListFailed,
  getSolarPanelSuccess,
  getSolarPanelFailed,
  duplicateSolarPanelSuccess,
  duplicateSolarPanelFailed,
  createSolarPanelSuccess,
  createSolarPanelFailed,
  editSolarPanelSuccess,
  editSolarPanelFailed,
  inactivateSolarPanelSuccess,
  inactivateSolarPanelFailed,
  activateSolarPanelSuccess,
  activateSolarPanelFailed,
  setSolarPanelModalStatus,
  setSolarPanelLoading,
  getDatasheetStarted,
  getDatasheetSuccess,
  getDatasheetFailed,
  uploadDatasheetStarted,
  uploadDatasheetSuccess,
  uploadDatasheetFailed,
  deleteDatasheetStarted,
  deleteDatasheetSuccess,
  deleteDatasheetFailed,
  clear
} = solarPanelSlice.actions

const getSolarPanelList = () => async dispatch => {
  try {
    dispatch(getSolarPanelListStart())
    const solarPanelList = await solarPanelApi.getAllSolarPanel()
    dispatch(getSolarPanelListSuccess(solarPanelList))
  } catch (error) {
    dispatch(getSolarPanelListFailed({ error }))
  }
}

const getSolarPanel = (id) => async dispatch => {
  try {
    dispatch(setSolarPanelLoading())
    const solarPanel = await solarPanelApi.getSolarPanel({ id })
    dispatch(getSolarPanelSuccess(solarPanel))
  } catch (error) {
    dispatch(getSolarPanelFailed({ error }))
  }
}

const duplicateSolarPanel = (id) => async dispatch => {
  try {
    dispatch(setSolarPanelLoading())
    const solarPanel = await solarPanelApi.getSolarPanel({ id })
    dispatch(duplicateSolarPanelSuccess(solarPanel))
  } catch (error) {
    dispatch(duplicateSolarPanelFailed({ error }))
  }
}

const createSolarPanel = (createSolarPanelDto) => async dispatch => {
  try {
    dispatch(setSolarPanelLoading())
    await solarPanelApi.createSolarPanel({ createSolarPanelDto })
    dispatch(createSolarPanelSuccess())
    dispatch(getSolarPanelList())
  } catch (error) {
    dispatch(createSolarPanelFailed({ error }))
  }
}

const editSolarPanel = (id, updateSolarPanelDto) => async dispatch => {
  try {
    dispatch(setSolarPanelLoading())
    await solarPanelApi.editSolarPanel({ id, updateSolarPanelDto })
    dispatch(editSolarPanelSuccess())
    dispatch(getSolarPanelList())
  } catch (error) {
    dispatch(editSolarPanelFailed({ error }))
  }
}

const inactivateSolarPanel = (id) => async dispatch => {
  try {
    await solarPanelApi.inactivateSolarPanel({ id })
    dispatch(inactivateSolarPanelSuccess())
    dispatch(getSolarPanelList())
  } catch (error) {
    dispatch(inactivateSolarPanelFailed({ error }))
  }
}

const activateSolarPanel = (id) => async dispatch => {
  try {
    await solarPanelApi.activateSolarPanel({ id })
    dispatch(activateSolarPanelSuccess())
    dispatch(getSolarPanelList())
  } catch (error) {
    dispatch(activateSolarPanelFailed({ error }))
  }
}

const checkSolarPanelNameUniqueness = (name) => async dispatch => {
  try {
    const solarPanelList = await solarPanelApi.getAllSolarPanel({ name })
    return solarPanelList.length
  } catch (error) {
    return 0
  }
}

const getSolarPanelDatasheet = (id) => async dispatch => {
  try {
    dispatch(getDatasheetStarted())
    const datasheet = await solarPanelApi.getSolarPanelDataSheet({ id })
    dispatch(getDatasheetSuccess(datasheet))
  } catch (error) {
    dispatch(getDatasheetFailed({ error }))
  }
}

const uploadSolarPanelDatasheet = (id, document) => async dispatch => {
  try {
    dispatch(uploadDatasheetStarted())
    await solarPanelApi.uploadSolarPanelDataSheet({ id, document })
    dispatch(uploadDatasheetSuccess())
    dispatch(getSolarPanelDatasheet(id))
  } catch (error) {
    dispatch(uploadDatasheetFailed({ error }))
  }
}

const downloadSolarPanelDatasheet = (id) => async dispatch => {
  const response = await solarPanelApi.downloadSolarPanelDataSheet({ id }, { responseType: 'blob' })
  return response
}

const deleteSolarPanelDatasheet = (id) => async dispatch => {
  try {
    dispatch(deleteDatasheetStarted())
    await solarPanelApi.deleteSolarPanelDataSheet({ id })
    dispatch(deleteDatasheetSuccess())
    dispatch(getSolarPanelDatasheet(id))
    return true
  } catch (error) {
    dispatch(deleteDatasheetFailed({ error }))
    return false
  }
}

export {
  getSolarPanelList,
  getSolarPanel,
  duplicateSolarPanel,
  createSolarPanel,
  editSolarPanel,
  inactivateSolarPanel,
  activateSolarPanel,
  setSolarPanelModalStatus,
  checkSolarPanelNameUniqueness,
  getSolarPanelDatasheet,
  uploadDatasheetStarted,
  uploadSolarPanelDatasheet,
  downloadSolarPanelDatasheet,
  deleteSolarPanelDatasheet,
  clear
}

export default solarPanelSlice.reducer
