import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import styles from './ModalWidget.module.scss'
import classnames from 'classnames'

const ModalWidget = (props) => {
  const { className, ...restProps } = props

  const classes = useMemo(() => {
    return classnames(className, styles.modalWidget)
  }, [className])

  return (
    <Modal
      className={classes}
      {...restProps}
    />
  )
}

export default ModalWidget

ModalWidget.propTypes = {
  className: PropTypes.string
}
