"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InverterApi = exports.InverterApiFactory = exports.InverterApiFp = exports.InverterApiAxiosParamCreator = void 0;
var globalImportUrl = require("url");
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var base_1 = require("../base");
/**
 * InverterApi - axios parameter creator
 * @export
 */
exports.InverterApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateInverter: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    if (id === null || id === undefined) {
                        throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling activateInverter.');
                    }
                    localVarPath = "/api/Inverter/{id}/Activate"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} inverterId
         * @param {CreateEfficiencyCurveDto} [createEfficiencyCurveDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEfficiencyCurve: function (inverterId, createEfficiencyCurveDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions, needsSerialization;
                return __generator(this, function (_a) {
                    // verify required parameter 'inverterId' is not null or undefined
                    if (inverterId === null || inverterId === undefined) {
                        throw new base_1.RequiredError('inverterId', 'Required parameter inverterId was null or undefined when calling createEfficiencyCurve.');
                    }
                    localVarPath = "/api/Inverter/{inverterId}/efcurve"
                        .replace("{" + "inverterId" + "}", encodeURIComponent(String(inverterId)));
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    needsSerialization = (typeof createEfficiencyCurveDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
                    localVarRequestOptions.data = needsSerialization ? JSON.stringify(createEfficiencyCurveDto !== undefined ? createEfficiencyCurveDto : {}) : (createEfficiencyCurveDto || "");
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {CreateInverterDto} [createInverterDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInverter: function (createInverterDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions, needsSerialization;
                return __generator(this, function (_a) {
                    localVarPath = "/api/Inverter";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    needsSerialization = (typeof createInverterDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
                    localVarRequestOptions.data = needsSerialization ? JSON.stringify(createInverterDto !== undefined ? createInverterDto : {}) : (createInverterDto || "");
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} inverterId
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeleteEfficiencyCurve: function (inverterId, id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'inverterId' is not null or undefined
                    if (inverterId === null || inverterId === undefined) {
                        throw new base_1.RequiredError('inverterId', 'Required parameter inverterId was null or undefined when calling deleteDeleteEfficiencyCurve.');
                    }
                    // verify required parameter 'id' is not null or undefined
                    if (id === null || id === undefined) {
                        throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling deleteDeleteEfficiencyCurve.');
                    }
                    localVarPath = "/api/Inverter/{inverterId}/efcurve/{id}"
                        .replace("{" + "inverterId" + "}", encodeURIComponent(String(inverterId)))
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInverterDataSheet: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    if (id === null || id === undefined) {
                        throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling deleteInverterDataSheet.');
                    }
                    localVarPath = "/api/Inverter/PowerPlant/DataSheet/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadInverterDataSheet: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    if (id === null || id === undefined) {
                        throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling downloadInverterDataSheet.');
                    }
                    localVarPath = "/api/Inverter/DataSheet/{id}/download"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} inverterId
         * @param {number} id
         * @param {UpdateEfficiencyCurveDto} [updateEfficiencyCurveDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editEfficiencyCurve: function (inverterId, id, updateEfficiencyCurveDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions, needsSerialization;
                return __generator(this, function (_a) {
                    // verify required parameter 'inverterId' is not null or undefined
                    if (inverterId === null || inverterId === undefined) {
                        throw new base_1.RequiredError('inverterId', 'Required parameter inverterId was null or undefined when calling editEfficiencyCurve.');
                    }
                    // verify required parameter 'id' is not null or undefined
                    if (id === null || id === undefined) {
                        throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling editEfficiencyCurve.');
                    }
                    localVarPath = "/api/Inverter/{inverterId}/efcurve/{id}"
                        .replace("{" + "inverterId" + "}", encodeURIComponent(String(inverterId)))
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    needsSerialization = (typeof updateEfficiencyCurveDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
                    localVarRequestOptions.data = needsSerialization ? JSON.stringify(updateEfficiencyCurveDto !== undefined ? updateEfficiencyCurveDto : {}) : (updateEfficiencyCurveDto || "");
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {UpdateInverterDto} [updateInverterDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editInverter: function (id, updateInverterDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions, needsSerialization;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    if (id === null || id === undefined) {
                        throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling editInverter.');
                    }
                    localVarPath = "/api/Inverter/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    needsSerialization = (typeof updateInverterDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
                    localVarRequestOptions.data = needsSerialization ? JSON.stringify(updateInverterDto !== undefined ? updateInverterDto : {}) : (updateInverterDto || "");
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} [name]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllInverter: function (name, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/api/Inverter";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    if (name !== undefined) {
                        localVarQueryParameter['name'] = name;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllInverterByPowerPlantId: function (powerPlantId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'powerPlantId' is not null or undefined
                    if (powerPlantId === null || powerPlantId === undefined) {
                        throw new base_1.RequiredError('powerPlantId', 'Required parameter powerPlantId was null or undefined when calling getAllInverterByPowerPlantId.');
                    }
                    localVarPath = "/api/Inverter/PowerPlant/{powerPlantId}"
                        .replace("{" + "powerPlantId" + "}", encodeURIComponent(String(powerPlantId)));
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} [elementsGroupId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSelectableInverter: function (elementsGroupId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/api/Inverter/Selectable";
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    if (elementsGroupId !== undefined) {
                        localVarQueryParameter['elementsGroupId'] = elementsGroupId;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} inverterId
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEfficiencyCurve: function (inverterId, id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'inverterId' is not null or undefined
                    if (inverterId === null || inverterId === undefined) {
                        throw new base_1.RequiredError('inverterId', 'Required parameter inverterId was null or undefined when calling getEfficiencyCurve.');
                    }
                    // verify required parameter 'id' is not null or undefined
                    if (id === null || id === undefined) {
                        throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling getEfficiencyCurve.');
                    }
                    localVarPath = "/api/Inverter/{inverterId}/efcurve/{id}"
                        .replace("{" + "inverterId" + "}", encodeURIComponent(String(inverterId)))
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} inverterId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEfficiencyCurveByInverterId: function (inverterId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'inverterId' is not null or undefined
                    if (inverterId === null || inverterId === undefined) {
                        throw new base_1.RequiredError('inverterId', 'Required parameter inverterId was null or undefined when calling getEfficiencyCurveByInverterId.');
                    }
                    localVarPath = "/api/Inverter/{inverterId}/efcurve"
                        .replace("{" + "inverterId" + "}", encodeURIComponent(String(inverterId)));
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInverter: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    if (id === null || id === undefined) {
                        throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling getInverter.');
                    }
                    localVarPath = "/api/Inverter/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInverterDataSheet: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    if (id === null || id === undefined) {
                        throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling getInverterDataSheet.');
                    }
                    localVarPath = "/api/Inverter/DataSheet/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inactivateInverter: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    if (id === null || id === undefined) {
                        throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling inactivateInverter.');
                    }
                    localVarPath = "/api/Inverter/{id}/Inactivate"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {any} [document]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInverterDataSheet: function (id, document, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, accessToken, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    if (id === null || id === undefined) {
                        throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling uploadInverterDataSheet.');
                    }
                    localVarPath = "/api/Inverter/DataSheet/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = globalImportUrl.parse(localVarPath, true);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarFormParams = new FormData();
                    // authentication bearer required
                    // http bearer authentication required
                    if (configuration && configuration.accessToken) {
                        accessToken = typeof configuration.accessToken === 'function'
                            ? configuration.accessToken()
                            : configuration.accessToken;
                        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
                    }
                    if (document !== undefined) {
                        localVarFormParams.append('document', document);
                    }
                    localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                    localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
                    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
                    delete localVarUrlObj.search;
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = localVarFormParams;
                    return [2 /*return*/, {
                            url: globalImportUrl.format(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * InverterApi - functional programming interface
 * @export
 */
exports.InverterApiFp = function (configuration) {
    return {
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateInverter: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.InverterApiAxiosParamCreator(configuration).activateInverter(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} inverterId
         * @param {CreateEfficiencyCurveDto} [createEfficiencyCurveDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEfficiencyCurve: function (inverterId, createEfficiencyCurveDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.InverterApiAxiosParamCreator(configuration).createEfficiencyCurve(inverterId, createEfficiencyCurveDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CreateInverterDto} [createInverterDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInverter: function (createInverterDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.InverterApiAxiosParamCreator(configuration).createInverter(createInverterDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} inverterId
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeleteEfficiencyCurve: function (inverterId, id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.InverterApiAxiosParamCreator(configuration).deleteDeleteEfficiencyCurve(inverterId, id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInverterDataSheet: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.InverterApiAxiosParamCreator(configuration).deleteInverterDataSheet(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadInverterDataSheet: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.InverterApiAxiosParamCreator(configuration).downloadInverterDataSheet(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} inverterId
         * @param {number} id
         * @param {UpdateEfficiencyCurveDto} [updateEfficiencyCurveDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editEfficiencyCurve: function (inverterId, id, updateEfficiencyCurveDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.InverterApiAxiosParamCreator(configuration).editEfficiencyCurve(inverterId, id, updateEfficiencyCurveDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {UpdateInverterDto} [updateInverterDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editInverter: function (id, updateInverterDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.InverterApiAxiosParamCreator(configuration).editInverter(id, updateInverterDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [name]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllInverter: function (name, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.InverterApiAxiosParamCreator(configuration).getAllInverter(name, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllInverterByPowerPlantId: function (powerPlantId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.InverterApiAxiosParamCreator(configuration).getAllInverterByPowerPlantId(powerPlantId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [elementsGroupId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSelectableInverter: function (elementsGroupId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.InverterApiAxiosParamCreator(configuration).getAllSelectableInverter(elementsGroupId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} inverterId
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEfficiencyCurve: function (inverterId, id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.InverterApiAxiosParamCreator(configuration).getEfficiencyCurve(inverterId, id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} inverterId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEfficiencyCurveByInverterId: function (inverterId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.InverterApiAxiosParamCreator(configuration).getEfficiencyCurveByInverterId(inverterId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInverter: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.InverterApiAxiosParamCreator(configuration).getInverter(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInverterDataSheet: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.InverterApiAxiosParamCreator(configuration).getInverterDataSheet(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inactivateInverter: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.InverterApiAxiosParamCreator(configuration).inactivateInverter(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {any} [document]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInverterDataSheet: function (id, document, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.InverterApiAxiosParamCreator(configuration).uploadInverterDataSheet(id, document, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
/**
 * InverterApi - factory interface
 * @export
 */
exports.InverterApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateInverter: function (id, options) {
            return exports.InverterApiFp(configuration).activateInverter(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} inverterId
         * @param {CreateEfficiencyCurveDto} [createEfficiencyCurveDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEfficiencyCurve: function (inverterId, createEfficiencyCurveDto, options) {
            return exports.InverterApiFp(configuration).createEfficiencyCurve(inverterId, createEfficiencyCurveDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CreateInverterDto} [createInverterDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInverter: function (createInverterDto, options) {
            return exports.InverterApiFp(configuration).createInverter(createInverterDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} inverterId
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeleteEfficiencyCurve: function (inverterId, id, options) {
            return exports.InverterApiFp(configuration).deleteDeleteEfficiencyCurve(inverterId, id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInverterDataSheet: function (id, options) {
            return exports.InverterApiFp(configuration).deleteInverterDataSheet(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadInverterDataSheet: function (id, options) {
            return exports.InverterApiFp(configuration).downloadInverterDataSheet(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} inverterId
         * @param {number} id
         * @param {UpdateEfficiencyCurveDto} [updateEfficiencyCurveDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editEfficiencyCurve: function (inverterId, id, updateEfficiencyCurveDto, options) {
            return exports.InverterApiFp(configuration).editEfficiencyCurve(inverterId, id, updateEfficiencyCurveDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} id
         * @param {UpdateInverterDto} [updateInverterDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editInverter: function (id, updateInverterDto, options) {
            return exports.InverterApiFp(configuration).editInverter(id, updateInverterDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [name]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllInverter: function (name, options) {
            return exports.InverterApiFp(configuration).getAllInverter(name, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} powerPlantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllInverterByPowerPlantId: function (powerPlantId, options) {
            return exports.InverterApiFp(configuration).getAllInverterByPowerPlantId(powerPlantId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} [elementsGroupId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSelectableInverter: function (elementsGroupId, options) {
            return exports.InverterApiFp(configuration).getAllSelectableInverter(elementsGroupId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} inverterId
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEfficiencyCurve: function (inverterId, id, options) {
            return exports.InverterApiFp(configuration).getEfficiencyCurve(inverterId, id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} inverterId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEfficiencyCurveByInverterId: function (inverterId, options) {
            return exports.InverterApiFp(configuration).getEfficiencyCurveByInverterId(inverterId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInverter: function (id, options) {
            return exports.InverterApiFp(configuration).getInverter(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInverterDataSheet: function (id, options) {
            return exports.InverterApiFp(configuration).getInverterDataSheet(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inactivateInverter: function (id, options) {
            return exports.InverterApiFp(configuration).inactivateInverter(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} id
         * @param {any} [document]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInverterDataSheet: function (id, document, options) {
            return exports.InverterApiFp(configuration).uploadInverterDataSheet(id, document, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * InverterApi - object-oriented interface
 * @export
 * @class InverterApi
 * @extends {BaseAPI}
 */
var InverterApi = /** @class */ (function (_super) {
    __extends(InverterApi, _super);
    function InverterApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {InverterApiActivateInverterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InverterApi
     */
    InverterApi.prototype.activateInverter = function (requestParameters, options) {
        var _this = this;
        return exports.InverterApiFp(this.configuration).activateInverter(requestParameters.id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {InverterApiCreateEfficiencyCurveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InverterApi
     */
    InverterApi.prototype.createEfficiencyCurve = function (requestParameters, options) {
        var _this = this;
        return exports.InverterApiFp(this.configuration).createEfficiencyCurve(requestParameters.inverterId, requestParameters.createEfficiencyCurveDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {InverterApiCreateInverterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InverterApi
     */
    InverterApi.prototype.createInverter = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return exports.InverterApiFp(this.configuration).createInverter(requestParameters.createInverterDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {InverterApiDeleteDeleteEfficiencyCurveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InverterApi
     */
    InverterApi.prototype.deleteDeleteEfficiencyCurve = function (requestParameters, options) {
        var _this = this;
        return exports.InverterApiFp(this.configuration).deleteDeleteEfficiencyCurve(requestParameters.inverterId, requestParameters.id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {InverterApiDeleteInverterDataSheetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InverterApi
     */
    InverterApi.prototype.deleteInverterDataSheet = function (requestParameters, options) {
        var _this = this;
        return exports.InverterApiFp(this.configuration).deleteInverterDataSheet(requestParameters.id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {InverterApiDownloadInverterDataSheetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InverterApi
     */
    InverterApi.prototype.downloadInverterDataSheet = function (requestParameters, options) {
        var _this = this;
        return exports.InverterApiFp(this.configuration).downloadInverterDataSheet(requestParameters.id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {InverterApiEditEfficiencyCurveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InverterApi
     */
    InverterApi.prototype.editEfficiencyCurve = function (requestParameters, options) {
        var _this = this;
        return exports.InverterApiFp(this.configuration).editEfficiencyCurve(requestParameters.inverterId, requestParameters.id, requestParameters.updateEfficiencyCurveDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {InverterApiEditInverterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InverterApi
     */
    InverterApi.prototype.editInverter = function (requestParameters, options) {
        var _this = this;
        return exports.InverterApiFp(this.configuration).editInverter(requestParameters.id, requestParameters.updateInverterDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {InverterApiGetAllInverterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InverterApi
     */
    InverterApi.prototype.getAllInverter = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return exports.InverterApiFp(this.configuration).getAllInverter(requestParameters.name, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {InverterApiGetAllInverterByPowerPlantIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InverterApi
     */
    InverterApi.prototype.getAllInverterByPowerPlantId = function (requestParameters, options) {
        var _this = this;
        return exports.InverterApiFp(this.configuration).getAllInverterByPowerPlantId(requestParameters.powerPlantId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {InverterApiGetAllSelectableInverterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InverterApi
     */
    InverterApi.prototype.getAllSelectableInverter = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return exports.InverterApiFp(this.configuration).getAllSelectableInverter(requestParameters.elementsGroupId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {InverterApiGetEfficiencyCurveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InverterApi
     */
    InverterApi.prototype.getEfficiencyCurve = function (requestParameters, options) {
        var _this = this;
        return exports.InverterApiFp(this.configuration).getEfficiencyCurve(requestParameters.inverterId, requestParameters.id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {InverterApiGetEfficiencyCurveByInverterIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InverterApi
     */
    InverterApi.prototype.getEfficiencyCurveByInverterId = function (requestParameters, options) {
        var _this = this;
        return exports.InverterApiFp(this.configuration).getEfficiencyCurveByInverterId(requestParameters.inverterId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {InverterApiGetInverterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InverterApi
     */
    InverterApi.prototype.getInverter = function (requestParameters, options) {
        var _this = this;
        return exports.InverterApiFp(this.configuration).getInverter(requestParameters.id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {InverterApiGetInverterDataSheetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InverterApi
     */
    InverterApi.prototype.getInverterDataSheet = function (requestParameters, options) {
        var _this = this;
        return exports.InverterApiFp(this.configuration).getInverterDataSheet(requestParameters.id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {InverterApiInactivateInverterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InverterApi
     */
    InverterApi.prototype.inactivateInverter = function (requestParameters, options) {
        var _this = this;
        return exports.InverterApiFp(this.configuration).inactivateInverter(requestParameters.id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {InverterApiUploadInverterDataSheetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InverterApi
     */
    InverterApi.prototype.uploadInverterDataSheet = function (requestParameters, options) {
        var _this = this;
        return exports.InverterApiFp(this.configuration).uploadInverterDataSheet(requestParameters.id, requestParameters.document, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return InverterApi;
}(base_1.BaseAPI));
exports.InverterApi = InverterApi;
